import React, {useEffect, useRef, useState} from 'react';
import {AsteriskIcon, CloseIcon, SearchIcon} from "../../../../assets/icons";
import {ButtonFilled, ButtonOutlined} from "../../../shared/buttons";
import Calendar from "../../../shared/calendar/Calendar";
import {useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {addManyStudents} from "../../../../store/actions/groups";
import {getStudentsNameSuggestions} from "../../../../api/students";

const AddStudentModal = ({close, type}) => {
    const [joinDate, setJoinDate] = useState('')
    const [students, setStudents] = useState(null)
    const [selectedStudents, setSelectedStudents] = useState([])
    const [value, setValue] = useState('')
    const [errors, setErrors] = useState({})
    const [showSelectedStudents, setShowSelectedStudents] = useState(false)

    const {id} = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const wrapperRef = useRef(null);

    const handleSelect = (student) => {
        if (!selectedStudents.some(s => s._id === student._id)) {
            setSelectedStudents(prev => [...prev, student])
        }
        setValue('')
        setShowSelectedStudents(false)
    }

    const handleRemoveStudent = (studentId) => {
        setSelectedStudents(prev => prev.filter(s => s._id !== studentId));
    }

    const validateFields = () => {
        const newErrors = {}
        if (!joinDate) newErrors.joinDate = "Այս դաշտը պարտադիր է"
        if (selectedStudents.length === 0) newErrors.student = "Այս դաշտը պարտադիր է"
        return newErrors
    };

    const handleSubmit = () => {
        const validationErrors = validateFields();

        if (Object.keys(validationErrors).length === 0) {
            const selectedStudentIds = selectedStudents.map(student => student._id);

            dispatch(addManyStudents(id, selectedStudentIds.join(','), joinDate))
            close()

        } else {
            setErrors(validationErrors);
        }
    };

    const fetchStudentSuggestions = async () => {
        const {data} = await getStudentsNameSuggestions(value)
        setStudents(data.students)
    }

    useEffect(() => {
        if (value) {
            fetchStudentSuggestions(value);
        } else {
            setStudents(null);
        }
    }, [value]);

    useEffect(() => {
        setErrors(prevErrors => ({
            ...prevErrors,
            joinDate: joinDate ? "" : prevErrors.joinDate,
            student: selectedStudents.length > 0 ? "" : prevErrors.student
        }));
    }, [joinDate, selectedStudents]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowSelectedStudents(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    return (
        <div className={'fixed z-[9990] w-screen h-screen top-0 left-0 flex items-center justify-center'}>
            <div className={'fixed bg-black bg-opacity-5 backdrop-blur w-full h-full top-0 left-0 z-[9990]'}></div>

            <div
                className={'absolute grid grid-rows-[auto,1fr,auto] w-full max-w-[690px] max-h-[400px] z-[9999] border border-black rounded-[10px] bg-white'}>
                <div className={'relative flex items-center justify-center p-[28px]'}>
                    <h2 className={'text-center'}>Ավելացնել ուսանող</h2>
                    <button className={'absolute right-6 bg-black bg-opacity-5 rounded-full p-1.5'} onClick={close}>
                        <CloseIcon width={20} height={20}/>
                    </button>
                </div>

                <div className={'flex flex-col px-[30px] gap-[70px]'} ref={wrapperRef}>
                    <div className={'relative'}>
                        <h2 className={'flex gap-[5px] mb-2.5'}>Ավելացման ամսաթիվ <AsteriskIcon/></h2>
                        <div className={'relative'}>
                            <div
                                className={'absolute w-full bg-white border border-black border-opacity-70 py-[3px] rounded-[5px]'}>
                                <Calendar placeholder={'օր/ամիս/տարի'} setDate={setJoinDate} value={joinDate}
                                          optionClassName={'px-[30px]'} displayFormat={'DD/MM/YYYY'}
                                          returnFormat={'YYYY-MM-DD'}/>
                            </div>
                            {errors.joinDate &&
                                <p className={'absolute top-[35px] text-red-500 mt-1'}>{errors.joinDate}</p>}
                        </div>
                    </div>

                    <div className={`relative flex flex-col gap-[10px]`}>
                        <h2 className={'flex gap-[5px]'}>Որոնել ուսանող <AsteriskIcon/></h2>
                        <div
                            className={`relative flex justify-between bg-white w-full border border-black border-opacity-70 rounded-[3px] py-[3px] px-[30px] ${selectedStudents.length > 0 && 'rounded-b-none'}`}>
                            <input type={'text'} placeholder={'որոնել'} value={value}
                                   onChange={e => setValue(e.target.value)}
                                   onFocus={() => setShowSelectedStudents(true)}
                                   className={'w-full pr-[30px] outline-none'}/>
                            <div className={'flex items-center gap-5'}>
                                <button>
                                    <SearchIcon/>
                                </button>
                            </div>
                        </div>
                        {errors.student && <p className={'absolute top-full mt-2 text-red-500'}>{errors.student}</p>}
                        {(value || showSelectedStudents) && (
                            <>
                                <div
                                    className={'absolute w-full py-2.5 border border-black border-t-0 top-full bg-white rounded-b-[3px]'}>
                                    <div className={`relative flex flex-col gap-[10px]`}>
                                        {selectedStudents.length > 0 && (
                                            <div className={'flex flex-wrap gap-2.5 mb-4 px-2.5 border-b'}>
                                                {selectedStudents.map(student => (
                                                    <div key={student._id}
                                                         className={'flex items-center gap-2.5 bg-gray-100 rounded mb-2'}>
                                                        <p>{student.fullName}</p>
                                                        <button onClick={() => handleRemoveStudent(student._id)}>
                                                            <CloseIcon width={15} height={15}/>
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    {students?.length > 0 ?
                                        <div className={'flex flex-col'}>
                                            {students?.map(student => (
                                                <div key={student._id} onClick={() => handleSelect(student)}
                                                     className={'flex items-center gap-5 px-[30px] py-[5px] hover:bg-gray-100 transition cursor-pointer'}>
                                                    <p>{student.fullName}</p>
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        <p className={'mx-[30px]'}>Ուսանողներ չկան</p>
                                    }
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className={'flex justify-center py-[50px] gap-[30px]'}>
                    <ButtonOutlined onClick={close} text={'Չեղարկել'} className={'w-[176px]'}/>
                    <ButtonFilled onClick={handleSubmit} text={'Ավելացնել'} className={'w-[176px]'}/>
                </div>
            </div>
        </div>
    );
};

export default AddStudentModal;