import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {AsteriskIcon, CloseIcon, SearchIcon} from "../../../assets/icons";
import Calendar from "../../shared/calendar/Calendar";
import {useParams} from "react-router-dom";
import {addManyStudents, addStudentToGroup} from "../../../store/actions/groups";
import {getStudentsNameSuggestions} from "../../../api/students";
import {ButtonFilled, ButtonOutlined} from "../../shared/buttons";
import {getGroupNameSuggestions} from "../../../api/groups";

const initialState = {
    title: '',
    description: '',
    group: null,
}

const AddToGroupModal = ({close, joinedIds}) => {
    const [joinDate, setJoinDate] = useState('')

    const [data, setData] = useState(initialState)
    const [searchGroupValue, setSearchGroupValue] = useState('')
    const [searchedGroups, setSearchedGroups] = useState(null)

    const {id} = useParams()
    const dispatch = useDispatch()

    const handleRemoveSelectedGroup = () => {
        setData({...data, group: null})
        setSearchGroupValue('')
    }

    const handleSelectGroup = (group) => {
        setData({...data, group})
        setSearchGroupValue(group.name)
    }

    const handleChangeSearchGroup = (e) => {
        setSearchGroupValue(e.target.value)
    }

    const handleSubmit = () => {
        dispatch(addManyStudents(data.group._id, joinedIds, joinDate))

        close()
    }

    useEffect(() => {
        const fetchGroupSuggestions = async () => {
            const {data} = await getGroupNameSuggestions(searchGroupValue)
            setSearchedGroups(data)
        }

        if (searchGroupValue) fetchGroupSuggestions()
    }, [searchGroupValue])

    return (
        <div className={'fixed z-[9990] w-screen h-screen top-0 left-0 flex items-center justify-center'}>
            <div onClick={close}
                 className={'fixed bg-black bg-opacity-5 backdrop-blur w-full h-full top-0 left-0 z-[9990]'}></div>

            <div
                className={'absolute grid grid-rows-[auto,1fr,auto] w-full max-w-[690px] max-h-[400px] z-[9999] border border-black rounded-[10px] bg-white'}>
                <div className={'relative flex items-center justify-center p-[28px]'}>
                    <h2 className={'text-center'}>Ավելացնել խմբում</h2>

                    <button className={'absolute right-6 bg-black bg-opacity-5 rounded-full p-1.5'}
                            onClick={close}>
                        <CloseIcon width={20} height={20}/>
                    </button>
                </div>
                <div className={'flex flex-col gap-10 px-[30px]'}>
                    <div className={'relative'}>
                        <h2 className={'flex gap-[5px] mb-2.5'}>Ավելացման ամսաթիվ <AsteriskIcon/></h2>
                        <div
                            className={'absolute w-full border border-black border-opacity-70 z-[999] rounded-[3px] overflow-hidden'}>
                            <Calendar
                                placeholder={'օր/ամիս/տարի'}
                                className={'py-[3px]'}
                                optionClassName={'px-[30px]'}
                                setDate={setJoinDate}
                                value={joinDate}
                                displayFormat={'DD/MM/YYYY'}
                                returnFormat={'YYYY-MM-DD'}/>
                        </div>
                    </div>

                    <div className={'relative flex flex-col gap-[10px] mt-[30px]'}>
                        <p className={'flex gap-1'}>Խումբ <AsteriskIcon/></p>
                        <div
                            className={`border border-black border-opacity-70 py-[2px] px-[30px] rounded-[3px] outline-none`}>
                            <div className={'flex items-center justify-between'}>
                                {data.group ?
                                    <div className={'flex items-center justify-between'}>
                                        <span>{data.group.name}</span>
                                    </div>
                                    :
                                    <input
                                        type={'text'}
                                        name="group"
                                        value={searchGroupValue}
                                        onChange={handleChangeSearchGroup}
                                        placeholder={'որոնել'}
                                        className={'w-full pr-[30px] outline-none'}
                                        autoComplete={"off"}
                                    />
                                }
                                <div className={'flex items-center gap-5'}>
                                    {data.group &&
                                        <button onClick={handleRemoveSelectedGroup}>
                                            <CloseIcon width={15} height={15}/>
                                        </button>
                                    }
                                    <button>
                                        <SearchIcon/>
                                    </button>
                                </div>
                            </div>
                            {!data.group && searchedGroups && searchGroupValue && (
                                <div
                                    className={'max-h-[260px] overflow-scroll absolute left-0 right-0 w-full bg-white border border-black rounded-b-[3px] pt-5'}>
                                    {searchedGroups?.length > 0 ?
                                        searchedGroups.map(group => (
                                            <div key={group._id} className={'hover:bg-gray-200 transition'}
                                                 onClick={() => handleSelectGroup(group)}>
                                                <p className={'px-[30px] py-1'}>{group.name}</p>
                                            </div>
                                        ))
                                        :
                                        <p className={'px-[30px]'}>Խմբեր չկան</p>
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={'flex justify-center py-[50px] gap-[30px]'}>
                    <ButtonOutlined onClick={close} text={'Չեղարկել'} className={'w-[176px]'}/>
                    <ButtonFilled onClick={handleSubmit} text={'Ավելացնել'} className={'w-[176px]'}/>
                </div>
            </div>
        </div>
    );
};

export default AddToGroupModal;