import {
    CHANGE_DRIVER_STATUS,
    CHANGE_ROUTE_STATUS,
    CHANGE_TRANSPORT_STOP_STATUS, CHANGE_VEHICLE_STATUS, CREATE_DRIVER, CREATE_ROUTE,
    CREATE_TRANSPORT_STOP, CREATE_VEHICLE, DELETE_ROUTE,
    DELETE_TRANSPORT_STOP, DELETE_VEHICLE, EDIT_DRIVER, EDIT_ROUTE,
    EDIT_TRANSPORT_STOP, GET_DRIVER, GET_DRIVERS, GET_ROUTES,
    GET_TRANSPORT_STOPS, GET_VEHICLES, SELECT_ROUTE, SELECT_TRANSPORT_STOP,
    SET_LOADING
} from '../constants/actionTypes'

const initialState = {
    stops: null,
    stop: null,
    routes: null,
    route: null,
    vehicles: null,
    drivers: null,
    driver: null,
    loading: false,
}

const transportReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_TRANSPORT_STOP:
            return {
                ...state,
                stops: {
                    ...state.stops,
                    data: [action.payload.transportStop, ...state.stops.data],
                },
            }
        case CREATE_ROUTE:
            return {
                ...state,
                routes: {
                    ...state.routes,
                    data: [action.payload.transportRoute, ...state.routes.data],
                },
            }
        case CREATE_DRIVER:
            return {
                ...state,
                drivers: {
                    ...state.drivers,
                    data: [action.payload.driver, ...state.drivers.data],
                },
            }
        case CREATE_VEHICLE:
            return {
                ...state,
                vehicles: {
                    ...state.vehicles,
                    data: [action.payload.vehicle, ...state.vehicles.data],
                },
            }
        case GET_TRANSPORT_STOPS:
            return {...state, stops: action.payload.transportStops}
        case GET_ROUTES:
            return {...state, routes: action.payload.transportRoutes}
        case GET_DRIVERS:
            return {...state, drivers: action.payload.drivers}
        case GET_DRIVER:
            return {...state, driver: action.payload.driver}
        case GET_VEHICLES:
            return {...state, vehicles: action.payload.vehicles}
        case SELECT_TRANSPORT_STOP:
            return {...state, stop: action.payload.transportStop}
        case SELECT_ROUTE:
            return {...state, route: action.payload.route}
        case EDIT_TRANSPORT_STOP:
            const updatedTransportStop = action.payload.transportStop
            const updatedTransportStops = state.stops.data.map(stop => {
                if (stop._id === updatedTransportStop._id) {
                    return {...stop, status: updatedTransportStop.status}
                }
                return stop
            })
            return {
                ...state,
                stops: {
                    ...state.stops,
                    data: updatedTransportStops
                }
            }
        case EDIT_ROUTE:
            const updatedRoute = action.payload.transportRoute
            const updatedRoutes = state.routes.data.map(route => {
                if (route._id === updatedRoute._id) {
                    return {...route, status: updatedRoute.status}
                }
                return updatedRoute
            })
            return {
                ...state,
                routes: {
                    ...state.routes,
                    data: updatedRoutes
                }
            }
        case EDIT_DRIVER:
            const updatedDriver = action.payload.driver
            const updatedDrivers = state.drivers.data.map(driver => {
                if (driver._id === updatedDriver._id) {
                    return {...driver, status: updatedDriver.status}
                }
                return updatedDriver
            })
            return {
                ...state,
                drivers: {
                    ...state.drivers,
                    data: updatedDrivers
                }
            }
        case CHANGE_TRANSPORT_STOP_STATUS:
            const updatedStop = action.payload.transportStop
            const updatedStops = state.stops.data.map(stop => {
                if (stop._id === updatedStop._id) {
                    return { ...stop, active: updatedStop.active }
                }
                return stop
            });
            return {
                ...state,
                stops: {
                    ...state.stops,
                    data: updatedStops,
                },
            }
        case CHANGE_ROUTE_STATUS:
            const updatedTransportRoute = action.payload.transportRoute
            const updatedTransportRoutes = state.routes.data.map(route => {
                if (route._id === updatedTransportRoute._id) {
                    return { ...route, active: updatedTransportRoute.active }
                }
                return route
            });
            return {
                ...state,
                routes: {
                    ...state.routes,
                    data: updatedTransportRoutes,
                },
            }
        case CHANGE_DRIVER_STATUS:
            const updatedTransportDriver = action.payload.driver
            const updatedTransportDrivers = state.drivers.data.map(driver => {
                if (driver._id === updatedTransportDriver._id) {
                    return { ...driver, active: updatedTransportDriver.active }
                }
                return driver
            })
            return {
                ...state,
                drivers: {
                    ...state.drivers,
                    data: updatedTransportDrivers,
                },
            }
        case CHANGE_VEHICLE_STATUS:
            const updatedVehicle = action.payload.vehicle
            const updatedVehicles = state.vehicles.data.map(vehicle => {
                if (vehicle._id === updatedVehicle._id) {
                    return { ...vehicle, active: updatedVehicle.active }
                }
                return vehicle
            });
            return {
                ...state,
                vehicles: {
                    ...state.vehicles,
                    data: updatedVehicles,
                },
            }
        case DELETE_TRANSPORT_STOP:
            return {
                ...state,
                stops: {
                    ...state.stops,
                    data: state.stops.data.filter(item => item._id !== action.payload.deletedId)
                }
            }
        case DELETE_ROUTE:
            return {
                ...state,
                routes: {
                    ...state.routes,
                    data: state.routes.data.filter(item => item._id !== action.payload.deletedId)
                }
            }
        case DELETE_VEHICLE:
            return {
                ...state,
                vehicles: {
                    ...state.vehicles,
                    data: state.vehicles.data.filter(item => item._id !== action.payload.deletedVehicleId)
                }
            }
        case SET_LOADING:
            return {...state, loading: !state.loading}
        default:
            return state;
    }
}
export default transportReducer