import React, {useEffect} from 'react';
import Container from "../components/shared/Container";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getRequestForm} from "../store/actions/forms";
import FormView from "../components/forms/form/FormView";
import {AsteriskIcon} from "../assets/icons";
import FormSubmitted from "../components/forms/form/FormSubmitted";
import ClosedForm from "./main/forms/ClosedForm";

const FillFormRequest = () => {
    const {form, submitted, loading} = useSelector(state => state.forms)
    const {id} = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        if (id) {
            dispatch(getRequestForm(id))
        }
    }, [id]);

    return (
        <div>
            <Container className={`pt-[47px] pb-24`}>

                {loading
                    ?
                    <p className={'text-center'}>Բեռնվում է․․․</p>
                    :
                    submitted
                        ?
                        <FormSubmitted successMessage={form.successMessage}/>
                        :
                        form
                            ?
                            <div
                                className={'max-w-[1254px] flex justify-center border rounded-[10px] shadow-md p-4'}>
                                <div className={'max-w-[1054px] w-full'}>
                                    <h2 className={'text-[28px] font-bold text-center break-all text-[#FCC10F] mt-[14px]'}>{form?.name}</h2>
                                    <p className={'text-[28px] mt-[50px] mb-[30px] break-all'}>{form?.description}</p>
                                    <div className={'flex items-center text-[#FF0707] mb-5'}>
                                        <AsteriskIcon width={12} height={12}/><span
                                        className={'ml-4'}>դաշտերը պարտադիր լրացման են</span>
                                    </div>
                                    <FormView form={form} disableSubmit={loading}/>
                                </div>
                            </div>
                            :
                            !loading && <ClosedForm />
                }
            </Container>
        </div>
    )
}

export default FillFormRequest;