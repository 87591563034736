import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {ReturnLeftIcon} from "../../../../../assets/icons";
import {Container} from "../../../../../components/shared";
import RoutesFilter from "../../../../../components/transport/subpages/routes/RoutesFilter";
import {useFilters} from "../../../../../hooks/useFilters";
import {deleteRoute, deleteTransportStop, getRoutes} from "../../../../../store/actions/transport";
import {useDispatch, useSelector} from "react-redux";
import AddButton from "../../../../../components/shared/buttons/AddButton";
import StopCard from "../../../../../components/transport/subpages/stops/StopCard";
import Pagination from "../../../../../components/shared/Pagination";
import RouteCard from "../../../../../components/transport/subpages/routes/RouteCard";

const initialFilters = {
    page: 1,
    size: 9
}

const Routes = () => {
    const {routes, loading} = useSelector(state => state.transport)

    const {
        filters,
        handleChange,
        changeSelectedFilters,
        discardFilters,
        changePage,
        submitFilters,
        setFilters
    } = useFilters(initialFilters, getRoutes, 'transport/routes')

    const [routeToDelete, setRouteToDelete] = useState()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleDeleteRoute = () => {
        dispatch(deleteRoute(routeToDelete))
        setRouteToDelete(null)
    }

    return (
        <div>
            <Container>
                <Link to={'/transport'} className={'flex items-center gap-[30px] group w-fit cursor-pointer fill-icon'}>
                    <ReturnLeftIcon/>
                    <p className={'transition group-hover:text-[#FCC10F]'}>Տրանսպորտ</p>
                </Link>
                <h2 className={'text-[50px] text-[#FCC10F] font-bold text-center'}>Երթուղիների բազա</h2>
            </Container>
            <RoutesFilter
                filters={filters}
                setFilters={setFilters}
                discardFilters={discardFilters}
                submitFilters={submitFilters}
                handleChange={handleChange}
                changeSelectedFilters={changeSelectedFilters}
            />

            <Container>
                <AddButton
                    text={'Նոր երթուղի'}
                    onClick={() => navigate('/transport/routes/create')}
                    className={'hover:bg-white transition border border-[#FCC10F] hover:text-[#FCC10F]'}
                />
                {loading
                    ?
                    <p className={'text-center'}>Բեռնվում է․․․</p>
                    :
                    routes?.data?.length > 0
                        ?
                        <>
                            <div className={'grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-[30px] mt-[30px]'}>
                                {routes?.data?.map((route, index) =>
                                    <RouteCard
                                        {...route}
                                        key={index}
                                        handleDeleteRoute={handleDeleteRoute}
                                        routeToDelete={routeToDelete}
                                        setRouteToDelete={setRouteToDelete}
                                    />)}
                            </div>
                            <Pagination count={routes?.pagesCount} page={filters?.page || 1} onChange={changePage}/>
                        </>
                        :
                        <p className={'text-center'}>Երթուղիներ չկան</p>
                }
            </Container>
        </div>
    );
};

export default Routes;