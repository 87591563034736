import axios from 'axios'

// Local network
// const API = axios.create({baseURL: 'http://10.100.7.26:5000'})

// Hetzner
const API = axios.create({baseURL: 'https://chat-api.smartcitizen.am', withCredentials: true})

// const API = axios.create({baseURL: 'http://localhost:5001', withCredentials: true})

export default API