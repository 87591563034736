import {Dashboard, Form, Forms, Requests} from "../pages/main";
import {Student, Students} from "../pages/main/students";
import {CreateGroup, Group, Groups} from "../pages/main/groups";
import Programs from "../pages/main/programs/Programs";
import Program from "../pages/main/programs/Program";
import GroupSettings from "../pages/main/groups/group/settings/GroupSettings";
import Settings from "../pages/main/settings/Settings";
import Profile from "../pages/main/profile/Profile";
import Projects from "../pages/main/projects/Projects";
import Communities from "../pages/main/communities/Communities";
import Intros from "../pages/main/intros/Intros";
import CreateIntro from "../pages/main/intros/CreateIntro";
import Intro from "../pages/main/intros/intro/Intro";
import IntroSettings from "../pages/main/intros/intro/IntroSettings";
import Workshops from "../pages/main/smartEvents/workshops/Workshops";
import CreateWorkshop from "../pages/main/smartEvents/workshops/CreateWorkshop";
import Workshop from "../pages/main/smartEvents/workshops/workshop/Workshop";
import Connects from "../pages/main/smartEvents/connects/Connects";
import WorkshopSettings from "../pages/main/smartEvents/workshops/workshop/WorkshopSettings";
import CreateConnect from "../pages/main/smartEvents/connects/CreateConnect";
import Connect from "../pages/main/smartEvents/connects/connect/Connect";
import ConnectSettings from "../pages/main/smartEvents/connects/connect/ConnectSettings";
import Trips from "../pages/main/smartEvents/trips/Trips";
import Trip from "../pages/main/smartEvents/trips/trip/Trip";
import CreateTrip from "../pages/main/smartEvents/trips/CreateTrip";
import TripSettings from "../pages/main/smartEvents/trips/trip/TripSettings";
import Transport from "../pages/main/transport/Transport";
import Stops from "../pages/main/transport/subpages/stops/Stops";
import CreateStop from "../pages/main/transport/subpages/stops/CreateStop";
import EditStop from "../pages/main/transport/subpages/stops/EditStop";
import Routes from "../pages/main/transport/subpages/routes/Routes";
import CreateRoute from "../pages/main/transport/subpages/routes/CreateRoute";
import EditRoute from "../pages/main/transport/subpages/routes/EditRoute";
import Vehicles from "../pages/main/transport/vehicles/Vehicles";
import CreateVehicle from "../pages/main/transport/vehicles/CreateVehicle";
import Drivers from "../pages/main/transport/subpages/drivers/Drivers";
import Driver from "../pages/main/transport/subpages/drivers/driver/Driver";

export const routes = {
    'manager': [
        { path: '/', element: <Dashboard /> },
        { path: '/forms', element: <Forms /> },
        { path: '/forms/:id', element: <Form /> },
        { path: '/requests', element: <Requests /> },
        { path: '/students', element: <Students /> },
        { path: '/students/:id/', element: <Student /> },
        { path: '/groups/create', element: <CreateGroup /> },
        { path: '/groups/:id/settings', element: <GroupSettings /> },
        { path: '/intros/:id/settings', element: <IntroSettings /> },
        { path: '/workshops/:id/settings', element: <WorkshopSettings />},
        { path: '/connects/:id/settings', element: <ConnectSettings />},
        { path: '/trips.js/:id/settings', element: <TripSettings />},
        { path: '/groups/:id/*', element: <Group /> },
        { path: '/groups', element: <Groups /> },
        { path: '/programs', element: <Programs /> },
        { path: '/programs/:id/*', element: <Program /> },
        { path: '/settings/*', element: <Settings /> },
        { path: '/profile', element: <Profile /> },
        { path: '/projects', element: <Projects /> },
        { path: '/communities', element: <Communities /> },
        { path: '/intros/:id/*', element: <Intro /> },
        { path: '/intros', element: <Intros /> },
        { path: '/intros/create', element: <CreateIntro /> },
        { path: '/workshops', element: <Workshops />},
        { path: '/workshops/create', element: <CreateWorkshop />},
        { path: '/workshops/:id/*', element: <Workshop />},
        { path: '/connects', element: <Connects />},
        { path: '/connects/:id/*', element: <Connect />},
        { path: '/connects/create', element: <CreateConnect />},
        { path: '/trips.js', element: <Trips />},
        { path: '/trips.js/:id/*', element: <Trip />},
        { path: '/trips.js/create', element: <CreateTrip />},
        { path: '/transport', element: <Transport />},
        { path: '/transport/stops', element: <Stops />},
        { path: '/transport/stops/create', element: <CreateStop />},
        { path: '/transport/stops/edit', element: <EditStop />},
        { path: '/transport/routes', element: <Routes />},
        { path: '/transport/routes/create', element: <CreateRoute />},
        { path: '/transport/routes/edit', element: <EditRoute />},
        { path: '/vehicles', element: <Vehicles />},
        { path: '/vehicles/create', element: <CreateVehicle />},
        { path: '/transport/drivers', element: <Drivers />},
        { path: '/transport/drivers/:id/*', element: <Driver />},
    ],
    'admin': [
        { path: '/', element: <Dashboard /> },
        { path: '/forms', element: <Forms /> },
        { path: '/forms/:id', element: <Form /> },
        { path: '/requests', element: <Requests /> },
        { path: '/students', element: <Students /> },
        { path: '/students/:id/', element: <Student /> },
        { path: '/groups', element: <Groups /> },
        { path: '/groups/create', element: <CreateGroup /> },
        { path: '/groups/:id/*', element: <Group /> },
        { path: '/groups/:id/settings', element: <GroupSettings /> },
        { path: '/intros/:id/settings', element: <IntroSettings /> },
        { path: '/settings/*', element: <Settings /> },
        { path: '/profile', element: <Profile /> },
        { path: '/projects', element: <Projects /> },
        { path: '/communities', element: <Communities /> },
        { path: '/intros/:id/*', element: <Intro /> },
        { path: '/intros', element: <Intros /> },
        { path: '/intros/create', element: <CreateIntro /> }
    ],
    'volunteer': [
        { path: '/', element: <Dashboard /> },
        { path: '/forms', element: <Forms /> },
        { path: '/forms/:id', element: <Form /> },
        { path: '/requests', element: <Requests /> },
        { path: '/students', element: <Students /> },
        { path: '/students/:id/', element: <Student /> },
    ],
    'instructor': [
        { path: '/', element: <Dashboard /> },
        { path: '/groups', element: <Groups /> },
        { path: '/groups/:id/*', element: <Group /> },
        { path: '/students/:id/', element: <Student /> },
    ]
}

export const routeNames = {
    'manager': [
        { path: "/students?page=1&size=10&", name: 'Գլխավոր'},
        { path: "/forms?page=1&size=10&", name: 'Ստեղծել հայտ'},
        { path: "/requests?page=1&size=10&trash=false", name: 'Հայտադարան'},
    ],
    'volunteer': [
        { path: "/students?page=1&size=10&", name: 'Գլխավոր'},
        { path: "/forms?page=1&size=10&", name: 'Ստեղծել հայտ'},
        { path: "/requests?page=1&size=10&trash=false", name: 'Հայտադարան'},
    ],
}