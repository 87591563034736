import React from 'react';
import logo from "../../assets/images/logo.png";
import {Link} from "react-router-dom";
import {Container} from "../shared";

const DefaultHeader = () => {
    return (
        <div className={'fixed top-0 right-0 z-[10000] left-0 bg-white py-1 border-b border-b-[#FCC10F]'}>
            <Container>
                <Link to={'/'} className={'block w-fit'}>
                    <img src={logo} className={'w-10'} alt={'logo'}/>
                </Link>
            </Container>
        </div>
    );
};

export default DefaultHeader;