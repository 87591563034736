import React, {useEffect} from 'react'
import {Route, Routes, useNavigate} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux";
import {getFormNames} from "../../store/actions/forms";
import {getCommunityNames, getMemberRoles, getMembersNamesList, getProgramNames} from "../../store/actions/common";
import {routes} from "../../common/routes";

const Main = () => {
    const {authData} = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getFormNames())
        dispatch(getCommunityNames())
        dispatch(getProgramNames())
        dispatch(getMemberRoles())

        if (!authData) {
            navigate("/")
        }
    }, [])

    return (
        <Routes>
            {routes[authData?.role]?.map(route => <Route key={route.path} path={route.path} element={route.element} />)}
        </Routes>
    )
};

export default Main;