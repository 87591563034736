import React, {useState} from 'react';
import {ReturnLeftIcon} from "../../../../../assets/icons";
import {Container} from "../../../../../components/shared";
import StopsFilter from "../../../../../components/transport/subpages/stops/StopsFilter";
import {useDispatch, useSelector} from "react-redux";
import {deleteTransportStop, getTransportStops} from "../../../../../store/actions/transport";
import StopCard from "../../../../../components/transport/subpages/stops/StopCard";
import AddButton from "../../../../../components/shared/buttons/AddButton";
import {Link, useNavigate} from "react-router-dom";
import {useFilters} from "../../../../../hooks/useFilters";
import Pagination from "../../../../../components/shared/Pagination";

const initialFilters = {
    page: 1,
    size: 9
}

const Stops = () => {
    const {stops, loading} = useSelector(state => state.transport)
    const {
        filters,
        handleChange,
        changeSelectedFilters,
        discardFilters,
        changePage,
        submitFilters,
        setFilters
    } = useFilters(initialFilters, getTransportStops, 'transport/stops')

    const [stopToDelete, setStopToDelete] = useState(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleDeleteStop = () => {
        dispatch(deleteTransportStop(stopToDelete))
        setStopToDelete(null)
    }

    return (
        <div>
            <Container>
                <Link to={'/transport'} className={'flex items-center gap-[30px] group w-fit cursor-pointer fill-icon'}>
                    <ReturnLeftIcon/>
                    <p className={'transition group-hover:text-[#FCC10F]'}>Տրանսպորտ</p>
                </Link>
                <h2 className={'text-[50px] text-[#FCC10F] font-bold text-center'}>Կանգառ</h2>
            </Container>
            <StopsFilter
                filters={filters}
                setFilters={setFilters}
                discardFilters={discardFilters}
                submitFilters={submitFilters}
                handleChange={handleChange}
                changeSelectedFilters={changeSelectedFilters}
            />

            <Container>
                <AddButton
                    text={'Նոր կանգառ'}
                    onClick={() => navigate('/transport/stops/create')}
                    className={'hover:bg-white transition border border-[#FCC10F] hover:text-[#FCC10F]'}
                />
                {loading
                    ?
                    <p className={'text-center'}>Բեռնվում է․․․</p>
                    :
                    stops?.data?.length > 0
                        ?
                        <>
                            <div className={'grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-[30px] mt-[30px]'}>
                                {stops?.data?.map((stop, index) =>
                                    <StopCard
                                        {...stop}
                                        key={index}
                                        handleDeleteStop={handleDeleteStop}
                                        stopToDelete={stopToDelete}
                                        setStopToDelete={setStopToDelete}
                                    />)}
                            </div>
                            <Pagination count={stops?.pagesCount} page={filters?.page || 1} onChange={changePage}/>
                        </>
                        :
                        <p className={'text-center'}>Կանգառներ չկան</p>
                }
            </Container>
        </div>
    )
}

export default Stops