import {
    GET_COMMUNITIES,
    SET_LOADING
} from '../constants/actionTypes'

const initialState = {
    communities: [],
    loading: false,
}

const communitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMMUNITIES:
            return {
                ...state,
                communities: action.payload.communities,
            }
        case SET_LOADING:
            return {...state, loading: !state.loading}
        default:
            return state;
    }
}
export default communitiesReducer