// General
export const SET_LOADING = 'SET_LOADING'

// Auth
export const SIGN_IN = 'SIGN_IN'
export const CHECK_AUTH = 'CHECK_AUTH'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGOUT = 'LOGOUT'
export const CHANGE_PROFILE_SETTINGS = 'CHANGE_PROFILE_SETTINGS'

// Forms
export const CREATE_FORM = 'CREATE_FORM'
export const CREATE_FIELD = 'CREATE_FIELD'
export const DUPLICATE_FIELD = 'DUPLICATE_FIELD'
export const DELETE_FIELD = 'DELETE_FIELD'
export const CHANGE_FOCUS = 'CHANGE_FOCUS'

export const SAVE_REQUEST_FORM = 'SAVE_REQUEST_FORM'
export const GET_REQUEST_FORM = 'GET_REQUEST_FORM'
export const GET_FORM_NAMES = 'GET_FORM_NAMES'
export const SUBMIT_FORM = 'SUBMIT_FORM'
export const GET_REQUEST_FORMS = 'GET_REQUEST_FORMS'
export const CHANGE_REQUEST_FORM_ACCESS = 'CHANGE_REQUEST_FORM_ACCESS'
export const DELETE_REQUEST_FORM = 'DELETE_REQUEST_FORM'
export const ADD_FORM_FIELD = 'ADD_FORM_FIELD'
export const CHANGE_EDIT_ELEMENT = 'CHANGE_EDIT_ELEMENT'
export const CHANGE_FIELD = 'CHANGE_FIELD'
export const CHANGE_POSITION = 'CHANGE_POSITION'
export const GET_FORM_REQUIREMENTS = 'GET_FORM_REQUIREMENTS'

// Requests
export const GET_REQUESTS = 'GET_REQUESTS'
export const SUBMIT_REQUEST = 'SUBMIT_REQUEST'
export const DELETE_REQUEST = 'DELETE_REQUEST'
export const DELETE_FOREVER = 'DELETE_FOREVER'
export const EDIT_REQUEST = 'EDIT_REQUEST'
export const SELECT_REQUESTS = 'SELECT_REQUESTS'
export const CREATE_STUDENT_FROM_REQUEST = 'CREATE_STUDENT_FROM_REQUEST'
export const RECOVER_REQUEST = 'RECOVER_REQUEST'


// Common
export const GET_COMMUNITY_NAMES = 'GET_COMMUNITY_NAMES'
export const GET_PROGRAM_NAMES = 'GET_PROGRAM_NAMES'
export const GET_MEMBERS_NAMES_LIST = 'GET_MEMBERS_NAMES_LIST'
export const GET_TRANSPORT_STOP_NAMES = 'GET_TRANSPORT_STOP_NAMES'
export const GET_REGIONS = 'GET_REGIONS'
export const GET_TRANSPORT_ROUTE_FILTERS = 'GET_TRANSPORT_ROUTE_FILTERS'
export const GET_SCHEDULE_CODES = 'GET_SCHEDULE_CODES'
export const GET_VEHICLE_FILTERS = 'GET_VEHICLE_FILTERS'
export const GET_VEHICLE_MARKS = 'GET_VEHICLE_MARKS'

// Socket
export const SET_CHAT_ROOM = 'SET_CHAT_ROOM'
export const SET_SOCKET = 'SET_SOCKET'

// Students

export const GET_STUDENTS = 'GET_STUDENTS'
export const EDIT_STUDENT = 'EDIT_STUDENT'
export const SELECT_STUDENTS = 'SELECT_STUDENTS'
export const CHANGE_STUDENT_STATUS = 'CHANGE_STUDENT_STATUS'
export const GET_STUDENT = 'GET_STUDENT'
export const GET_NOTES = 'GET_NOTES'
export const CREATE_NOTE = 'CREATE_NOTE'
export const EDIT_NOTE = 'EDIT_NOTE'
export const DELETE_NOTE = 'DELETE_NOTE'
export const CHANGE_STUDENT_PROFILE_IMAGE = 'CHANGE_STUDENT_PROFILE_IMAGE'
export const GET_STUDENT_FILES = 'GET_STUDENT_FILES'
export const DELETE_STUDENT_FILE = 'DELETE_STUDENT_FILE'
export const CREATE_STUDENT_FILE = 'CREATE_STUDENT_FILE'
export const GET_STUDENTS_FILTERS = 'GET_STUDENTS_FILTERS'

// Groups
export const CREATE_GROUP = 'CREATE_GROUP'
export const CREATE_INTRO = 'CREATE_INTRO'
export const CREATE_WORKSHOP = 'CREATE_WORKSHOP'
export const CREATE_CONNECT = 'CREATE_CONNECT'
export const CREATE_TRIP = 'CREATE_TRIPS'
export const GET_GROUPS = 'GET_GROUPS'
export const GET_GROUP = 'GET_GROUP'
export const GET_INTROS = 'GET_INTROS'
export const GET_INTRO = 'GET_INTRO'
export const GET_WORKSHOPS = 'GET_WORKSHOPS'
export const GET_WORKSHOP = 'GET_WORKSHOP'
export const GET_CONNECTS = 'GET_CONNECTS'
export const GET_CONNECT = 'GET_CONNECT'
export const GET_TRIPS = 'GET_TRIPS'
export const GET_TRIP = 'GET_TRIP'
export const CHANGE_GROUP_STATUS = 'CHANGE_GROUP_STATUS'
export const DELETE_GROUP = 'DELETE_GROUP'
export const DELETE_GROUP_NOTE = 'DELETE_GROUP_NOTE'
export const ADD_MANY_STUDENTS = 'ADD_MANY_STUDENTS'

// Group
export const GET_GROUP_STUDENTS = 'GET_GROUP_STUDENTS'
export const GET_GROUP_NOTES = 'GET_GROUP_NOTES'
export const ADD_STUDENT_TO_GROUP = 'ADD_STUDENT_TO_GROUP'
export const REMOVE_STUDENT_FROM_GROUP = 'REMOVE_STUDENT_FROM_GROUP'
export const CHANGE_GROUP_STUDENT_STATUS = 'CHANGE_GROUP_STUDENT_STATUS'
export const CHANGE_STUDENT_JOIN_DATE = 'CHANGE_STUDENT_JOIN_DATE'
export const ADD_STUDENT_NOTE = 'ADD_STUDENT_NOTE'
export const CHANGE_LESSON_DATE = 'CHANGE_LESSON_DATE'
export const CANCEL_LESSON = 'CANCEL_LESSON'
export const RESTORE_CANCELED_LESSON = 'RESTORE_CANCELED_LESSON'
export const MARK_LESSON_ATTENDANCE = 'MARK_LESSON_ATTENDANCE'

// Programs
export const CREATE_PROGRAM = 'CREATE_PROGRAM'
export const GET_PROGRAMS = 'GET_PROGRAMS'
export const GET_PROGRAM = 'GET_PROGRAM'
export const GET_PROGRAM_GROUPS = 'GET_PROGRAM_GROUPS'
export const CREATE_PROGRAM_PROJECT = 'CREATE_PROGRAM_PROJECT'
export const GET_PROGRAM_PROJECTS = 'GET_PROGRAM_PROJECTS'
export const CHANGE_PROGRAM_STATUS = 'CHANGE_PROGRAM_STATUS'
export const DELETE_PROGRAM = 'DELETE_PROGRAM'
export const DELETE_PROGRAM_PROJECT = 'DELETE_PROGRAM_PROJECT'

//profile
export const CREATE_DIVISION = 'CREATE_DIVISION'
export const GET_DIVISIONS = 'GET_DIVISIONS'
export const CHANGE_DIVISION_STATUS = 'CHANGE_DIVISION_STATUS'
export const EDIT_DIVISION = 'EDIT_DIVISION'
export const GET_MEMBER_ROLES = 'GET_MEMBER_ROLES'
export const CREATE_MEMBER = 'CREATE_MEMBER'
export const GET_MEMBERS = 'GET_MEMBER'
export const CHANGE_MEMBER_STATUS = 'CHANGE_MEMBER_STATUS'
export const EDIT_PROFILE = 'EDIT_PROFILE'
export const DELETE_MEMBER = 'DELETE_MEMBER'
export const GET_ALL_PROJECTS = 'GET_ALL_PROJECTS'

// communities
export const GET_COMMUNITIES = 'GET_COMMUNITIES'

// transport
export const CREATE_TRANSPORT_STOP = 'CREATE_TRANSPORT_STOP'
export const CREATE_ROUTE = 'CREATE_ROUTE'
export const CREATE_VEHICLE = 'CREATE_VEHICLE'
export const CREATE_DRIVER = 'CREATE_DRIVER'
export const GET_VEHICLES = 'GET_VEHICLES'
export const GET_TRANSPORT_STOPS = 'GET_TRANSPORT_STOPS'
export const GET_ROUTES = 'GET_ROUTES'
export const GET_DRIVERS = 'GET_DRIVERS'
export const GET_DRIVER = 'GET_DRIVER'
export const EDIT_TRANSPORT_STOP = 'EDIT_TRANSPORT_STOP'
export const EDIT_ROUTE = 'EDIT_ROUTE'
export const EDIT_DRIVER = 'EDIT_DRIVER'
export const SELECT_TRANSPORT_STOP = 'SELECT_TRANSPORT_STOP'
export const SELECT_ROUTE = 'SELECT_ROUTE'
export const CHANGE_TRANSPORT_STOP_STATUS = 'CHANGE_TRANSPORT_STOP_STATUS'
export const CHANGE_ROUTE_STATUS = 'CHANGE_ROUTE_STATUS'
export const CHANGE_VEHICLE_STATUS = 'CHANGE_VEHICLE_STATUS'
export const CHANGE_DRIVER_STATUS = 'CHANGE_DRIVER_STATUS'
export const DELETE_TRANSPORT_STOP = 'DELETE_TRANSPORT_STOP'
export const DELETE_ROUTE = 'DELETE_ROUTE'
export const DELETE_VEHICLE = 'DELETE_VEHICLE'

