import React from "react"
import {CheckedIcon} from "../../../assets/icons";

export const ButtonOutlined = ({onClick, text, className}) => (
    <button
        onClick={onClick}
        className={`${className || 'w-[165px]'} h-[40px] flex items-center justify-center text-[#FCC10F] ring-1 ring-[#FCC10F] rounded-[5px]`}>{text}</button>
)

export const ButtonFilled = ({onClick, text, className}) => (
    <button
        onClick={onClick}
        className={`${className || 'w-[165px]'} h-[40px] flex items-center justify-center text-white bg-[#FCC10F] ring-1 ring-[#FCC10F] rounded-[5px]`}>{text}</button>
)

export const PlusButton = ({onClick, disabled, className, width, height, styleClassName, hoverPlusColor}) => (
    <button className={`${className || 'p-[18px]'} bg-[#FCC10F] ${styleClassName} rounded-full ${disabled ? 'pointer-events-none' : 'pointer-events-auto'} ${styleClassName && 'fill-icon'}`} onClick={onClick}>
        <svg width={width || "15"} height={height || "15"} viewBox="0 0 16 16" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 0.5C8.24864 0.5 8.4871 0.598772 8.66291 0.774588C8.83873 0.950403 8.9375 1.18886 8.9375 1.4375V7.0625H14.5625C14.8111 7.0625 15.0496 7.16127 15.2254 7.33709C15.4012 7.5129 15.5 7.75136 15.5 8C15.5 8.24864 15.4012 8.4871 15.2254 8.66291C15.0496 8.83873 14.8111 8.9375 14.5625 8.9375H8.9375V14.5625C8.9375 14.8111 8.83873 15.0496 8.66291 15.2254C8.4871 15.4012 8.24864 15.5 8 15.5C7.75136 15.5 7.5129 15.4012 7.33709 15.2254C7.16127 15.0496 7.0625 14.8111 7.0625 14.5625V8.9375H1.4375C1.18886 8.9375 0.950403 8.83873 0.774588 8.66291C0.598772 8.4871 0.5 8.24864 0.5 8C0.5 7.75136 0.598772 7.5129 0.774588 7.33709C0.950403 7.16127 1.18886 7.0625 1.4375 7.0625H7.0625V1.4375C7.0625 1.18886 7.16127 0.950403 7.33709 0.774588C7.5129 0.598772 7.75136 0.5 8 0.5Z"
                fill={'black'}/>
        </svg>
    </button>
)

export const Checkbox = ({checked}) => (
    <div
        className={'flex items-center justify-center w-5 h-5 border border-black rounded-[5px] mr-5'}>
        {checked ? <CheckedIcon/> : ''}
    </div>
)