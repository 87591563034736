import React, {useEffect, useRef, useState} from 'react';
import {useOutsideClick} from "../../../hooks/useOutsideClick";
import {ActionMenuIcon} from "../../../assets/icons";
import {ConfirmationModal} from "../../shared";
import {useDispatch} from "react-redux";
import {changeDivisionStatus} from "../../../store/actions/profile";
import CreateDivisionModal from "./CreateDivisionModal";

const DivisionItem = ({_id, address, email, image, name, phoneNumber, status}) => {
    const [showMenu, setShowMenu] = useState(false)
    const [statusToChange, setStatusToChange] = useState(null)
    const [editMode, setEditMode] = useState(false)

    const dispatch = useDispatch()

    const ref = useRef(null)

    useOutsideClick(ref, () => setShowMenu(false))

    const changeStatus = () => {
        setStatusToChange(true)
    }

    const handleChangeStatus = () => {
        dispatch(changeDivisionStatus(_id))

        setShowMenu(false)
        setStatusToChange(false)
    }

    const openEditModal = () => {
        setShowMenu(false)
        setEditMode(true)
    }

    return (
        <>
            {statusToChange &&
                <ConfirmationModal
                    close={() => setStatusToChange(null)}
                    warningText={status === 'active' ? 'Վստա՞հ եք, որ ցանկանում եք ապաակտիվացնել այս դիվիժնը։' : 'Վստա՞հ եք, որ ցանկանում եք ակտիվացնել այս դիվիժնը։'}
                    confirm={handleChangeStatus}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            {editMode && (
                <CreateDivisionModal
                    close={() => setEditMode(false)}
                    divisionData={{
                        _id,
                        name,
                        phoneNumber,
                        email,
                        address,
                        image,
                    }}
                />
            )}

            <div
                className={'relative border border-black border-opacity-70 py-[9px] px-5 rounded-[5px] mt-[30px]'}>
                <div className={'relative grid grid-cols-4 '}>
                    <div className={'flex items-center gap-[30px] line-clamp-1'}>
                        <img src={image} alt={"memberProfileImg"} className={'w-[30px] h-[30px] object-cover'}/>
                        <h2>{name}</h2>
                    </div>

                    <div className={'line-clamp-1'}>{phoneNumber}</div>

                    <div className={'line-clamp-1'}>{email}</div>

                    <div className={'relative'}>
                        <span title={address} className={'line-clamp-1 pr-2.5'}>{address}</span>
                        <button
                            className={`absolute -right-3 top-0 ${showMenu ? 'pointer-events-none' : 'pointer-events-auto'}`}
                            onClick={() => setShowMenu(!showMenu)}>
                            <ActionMenuIcon/>
                        </button>
                    </div>
                </div>

                {showMenu && (
                    <div ref={ref}
                         className={`absolute z-50 w-[218px] top-full flex flex-col gap-5 bg-white right-0 -mr-0.5 py-5 border border-black rounded-[5px]`}>
                        <>
                            <p
                                onClick={openEditModal}
                                className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                Խմբագրել
                            </p>
                            <p
                                onClick={changeStatus}
                                className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                {status === 'deactivated' ? 'Ակտիվացնել' : 'Ապաակտիվացնել'}
                            </p>
                        </>
                    </div>
                )}
            </div>
        </>
    );
};

export default DivisionItem;