import React from 'react';
import {CloseIcon} from "../../assets/icons";
import moment from "moment";

const SelectedFilters = ({selectedFilters, removeItem}) => {
    const filtersArr = selectedFilters && Object.entries(selectedFilters)

    const notMultipleSelectFilters = ['firstName', 'lastName', 'middleName', 'name', 'page', 'size', 'archive', 'group', 'groupName', 'place', 'email', 'duration', 'educationalEstablishment', 'plate']
    const allowedFilters = ['age', 'community', 'status', 'studentStatus', 'requestStatus', 'formN', 'gender', 'school', 'grade', 'schedule', 'ageGroup', 'program', 'fromDate', 'toDate', 'clothingSize', 'finishedPrograms', 'finishedProgramsCount', 'degrees', 'awards', 'stopsCount', 'routeStart', 'region', 'routeDuration', 'distance', 'seatingCapacity', 'mark', 'driverLicense', 'driver']

    const CategoryItems = ({name, data}) => {
        const handleRemove = (item) => {
            removeItem(name, item)
        }

        if (allowedFilters.includes(name) && typeof data === 'object') {
            return (
                data?.map((item, i) => (
                    <div key={item?._id || i}
                         onClick={() => handleRemove(item)}
                         className={'flex items-center gap-3 px-1 bg-[#D9D9D940] rounded-[3px] cursor-pointer'}>
                        <span>{item?.name}</span>
                        <button><CloseIcon/></button>
                    </div>
                ))
            )
        } else {
            const formattedDate = moment(data).format('DD.MM.YYYY')

            return (
                <div
                    onClick={() => handleRemove(data)}
                    className={'flex items-center gap-3 px-1 bg-[#D9D9D940] rounded-[3px] cursor-pointer'}>
                    <span>{formattedDate}</span>
                    <button><CloseIcon/></button>
                </div>
            )
        }
    }

    return (
        <div className={`flex flex-wrap gap-5 h-fit`}>
            {filtersArr?.map((category) => {
                const categoryName = category[0]
                const categoryItems = category[1]

                if (notMultipleSelectFilters.includes(categoryName)) return

                return <CategoryItems key={categoryName} data={categoryItems} name={categoryName}/>
            })}
        </div>
    );
};

export default SelectedFilters;