import React from 'react';
import {ActiveStudentsIcon, AllStudentsCountIcon, GroupsCountIcon, SettingsIcon} from "../../../assets/icons";
import {Link} from "react-router-dom";
import ButtonHoverText from "../../shared/buttons/ButtonHoverText";

const ProgramHeader = ({name, image, activeStudentsCount, allStudentsCount, groupsCount}) => {
    return (
        <div className={'flex items-center justify-between'}>
            <img src={image} alt={'program_image'} className={'h-full max-h-[50px]'}/>

            <div className={'relative flex flex-col items-center'}>
                <h2 className={'text-[50px] text-[#FCC10F] font-bold'}>{name}</h2>
                <div className={'absolute top-full flex justify-center gap-[30px]'}>
                    <p className={'relative group flex items-center gap-[10px]'}>
                        <ActiveStudentsIcon/>
                        <span>{activeStudentsCount}</span>
                        <span
                            className={`absolute w-[180px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Ակտիվ ուսանողներ</span>
                    </p>
                    <p className={'relative group flex items-center gap-[10px]'}>
                        <AllStudentsCountIcon/>
                        <span>{allStudentsCount}</span>
                        <span
                            className={`absolute w-[180px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Ընդհանուր ուսանողների թիվ</span>
                    </p>
                    <p className={'relative group flex items-center gap-[10px]'}>
                        <GroupsCountIcon/>
                        <span>{groupsCount}</span>
                        <span
                            className={`absolute w-[180px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Խմբերի քանակ</span>
                    </p>
                </div>
            </div>
            <Link to={'settings'}><SettingsIcon/></Link>
        </div>
    );
};

export default ProgramHeader;