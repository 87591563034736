import React, {useEffect, useState} from 'react';
import {Container} from "../../../../../components/shared";
import DropdownInputCheckbox from "../../../../../components/shared/dropdowns/DropdownInputCheckbox";
import {AsteriskIcon} from "../../../../../assets/icons";
import Calendar from "../../../../../components/shared/calendar/Calendar";
import {ButtonFilled, ButtonOutlined} from "../../../../../components/shared/buttons";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";
import {checkGroupDetails} from "../../../../../utils/groups/checkGroupDetails";
import {changeGroupSettings, getConnect} from "../../../../../store/actions/groups";

const ConnectSettings = () => {
    const {programs} = useSelector(state => state.common)
    const {group} = useSelector(state => state.groups)

    const [data, setData] = useState(null)
    const [validationErrors, setValidationErrors] = useState({})
    const [nameBeforeEdit, setNameBeforeEdit] = useState('')

    const {id} = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleStartChangeName = () => {
        setNameBeforeEdit(data.name)
    }

    const handleChangeName = (e) => {
        setData({...data, name: e.target.value})
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            if (data.name) {
                setNameBeforeEdit('')
            } else {
                setData({...data, name: nameBeforeEdit})
            }
        }
    }

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleChangeTime = (e) => {
        const { name, value } = e.target
        const timeValue = value.replace(/\D/g, '').slice(0, 4)
        let formattedTime = ''

        if (timeValue.length >= 2) {
            formattedTime = `${timeValue.slice(0, 2)}:${timeValue.slice(2)}`
        }

        if (formattedTime.length === 5) {
            const [hours, minutes] = formattedTime.split(':').map(Number)
            if (hours > 23 || minutes > 59) {
                return;
            }
        }

        const newSchedule = data.schedule.map(scheduleTimes => {
            if (name === 'startTime') {
                const startTime = moment(formattedTime, 'HH:mm')
                const endTime = startTime.add(data.lessonDuration, 'minutes').format('HH:mm')
                return {
                    ...scheduleTimes,
                    startTime: formattedTime,
                    endTime: startTime.isValid() ? endTime : ''
                }
            } else if (name === 'endTime') {
                return {
                    ...scheduleTimes,
                    endTime: formattedTime
                }
            }
            return scheduleTimes;
        })

        setData({
            ...data,
            schedule: newSchedule
        })
    }

    const handleKeyDown = (e) => {
        const { name, value } = e.target;

        if (e.key === 'Enter') {
            // handleSubmit()
        } else if (e.key === 'Backspace' && value.length === 3 && value.charAt(2) === ":") {
            e.preventDefault();
            setData({
                ...data,
                [name]: value.slice(0, 1)
            })
        }
    }

    const handleSelectProgram = (_, program) => {
        setData({ ...data, program })
    }

    const changeStartDate = (e) => {
        setData({ ...data, date: e })
    }

    const handleDiscard = () => {
        navigate('/connects?page=1&size=10&')
    }

    const handleSubmit = () => {
        const startTime = document.querySelector('input[name="startTime"]')
        const endTime = document.querySelector('input[name="endTime"]')

        const payload = {
            name: data.name,
            lessonDuration: data.lessonDuration,
            date: data.startDate,
            startTime: data.schedule.map(scheduleTimes => scheduleTimes.startTime),
            endTime: data.schedule.map(scheduleTimes => scheduleTimes.endTime)
        }

        const errors = checkGroupDetails(payload, data)

        if (Object.keys(errors).length === 0 &&  startTime && endTime && startTime.value.length === 5 && endTime.value.length === 5) {
            dispatch(changeGroupSettings(id, payload, () => navigate(`/connects/${group._id}`)))
        } else {
            setValidationErrors(errors)
        }
    }

    const handleFocus = (field) => {
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [field]: ""
        }))
    }

    useEffect(() => {
        if (group) {
            setData({
                ...data,
                name: group.name,
                program: group.program,
                startDate: group.startDate,
                endDate: group.endDate,
                lessonDuration: group.lessonDuration,
                schedule: group.schedule,
            })
        }
    }, [group])

    useEffect(() => {
        if (!group) {
            dispatch(getConnect(id))
        }
    }, [id])

    return (
        <div>
            <Container>
                <div className={'flex justify-center mb-[50px]'}>
                    {nameBeforeEdit ?
                        <input
                            name="name"
                            className={'w-full text-center text-[50px] text-[#FCC10F] font-bold outline-none'}
                            autoComplete={'off'}
                            autoFocus
                            onFocus={e => e.target.select()}
                            value={data?.name}
                            onChange={handleChangeName}
                            onKeyDown={handleEnter}
                        />
                        :
                        <h2 onClick={handleStartChangeName}
                            className={'text-center text-[50px] text-[#FCC10F] font-bold break-all'}>{data?.name}</h2>
                    }
                </div>
                <div className={'grid lg:grid-cols-2 grid-cols-1 gap-[30px]'}>
                    <div className={'flex flex-col gap-[30px]'}>
                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Կցել ծրագիր </h2>
                            <div>
                                <DropdownInputCheckbox
                                    data={programs}
                                    previewClassName={'px-[30px] rounded-[5px] py-[7px]'}
                                    optionClassName={'px-6'}
                                    name={'program'}
                                    placeholder={'Ընտրել ծրագիր'}
                                    onChange={handleSelectProgram}
                                    selected={[data?.program]}
                                    hideCheckbox
                                    onFocus={() => handleFocus('program')}
                                />
                            </div>
                        </div>

                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Դասերի տևողությունը (րոպե) <AsteriskIcon/></h2>
                            <input
                                type={"number"}
                                value={data?.lessonDuration}
                                name={'lessonDuration'}
                                placeholder={'Օրինակ՝ 120'}
                                onChange={handleChange}
                                onFocus={() => handleFocus('lessonDuration')}
                                className={'w-full border border-black border-opacity-70 outline-none rounded-[5px] py-[7px] px-[30px]'}
                            />
                            {validationErrors.lessonDuration &&
                                <p className={"text-red-500 mt-2"}>{validationErrors.lessonDuration}</p>}
                        </div>
                    </div>
                    <div className={'flex flex-col gap-[30px]'}>
                        <div className={'relative'}>
                            <h2 className={'flex gap-1 mb-[10px]'}>ՍՄԱՐԹամեջի օր <AsteriskIcon/></h2>
                            <div className={'relative'}>
                                <div
                                    className={`absolute w-full bg-white border border-black border-opacity-70 py-[6px] rounded-[5px]`}>
                                    <Calendar displayFormat={'DD.MM.YYYY'}
                                              returnFormat={'YYYY-MM-DD'}
                                              value={group?.date}
                                              setDate={changeStartDate}
                                              placeholder={'օր/ամիս/տարի'}
                                              className={'py-[1px]'}
                                              optionClassName={'px-[30px]'}
                                              onFocus={() => handleFocus('startDate')}
                                              disableClick={true}
                                    />
                                    {validationErrors.startDate &&
                                        <p className={"absolute top-full text-red-500 mt-2"}>{validationErrors.startDate}</p>}
                                </div>
                            </div>
                        </div>

                        <div className={'flex items-center gap-[13px] mt-[40px]'}>
                            <div className={'flex flex-col gap-2.5'}>
                                <p>Դասի սկիզբ</p>
                                <input
                                    type={"text"}
                                    name={'startTime'}
                                    placeholder={'Օրինակ՝ 14։00'}
                                    className={'w-[300px] h-10 border border-black border-opacity-70 rounded-[5px] px-[30px] py-2.5 outline-none'}
                                    onChange={handleChangeTime}
                                    onKeyDown={handleKeyDown}
                                    value={data?.schedule?.[0]?.startTime || ''}
                                />
                            </div>

                            <div className={'flex flex-col gap-2.5'}>
                                <p>Դասի ավարտ</p>
                                <input
                                    type={"text"}
                                    name={'endTime'}
                                    placeholder={'Օրինակ՝ 16։00'}
                                    className={'w-[300px] h-10 border border-black border-opacity-70 rounded-[5px] px-[30px] py-2.5 outline-none'}
                                    onChange={handleChangeTime}
                                    onKeyDown={handleKeyDown}
                                    value={data?.schedule?.[0]?.endTime || ''}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={'flex items-center justify-end'}>
                        <div>
                            <div className={'w-fit flex gap-[30px] items-center mt-[100px]'}>
                                <ButtonOutlined
                                    onClick={handleDiscard}
                                    text={'Չեղարկել'}
                                    className={'w-[176px]'}
                                />
                                <ButtonFilled
                                    onClick={handleSubmit}
                                    text={'Պահպանել'}
                                    className={'w-[176px]'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default ConnectSettings;
