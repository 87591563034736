import React, {useEffect, useRef, useState} from 'react';
import SelectYear from "./SelectYear";
import SelectMonth from "./SelectMonth";
import {gsap} from 'gsap'
import {CalendarIcon} from "../../../assets/icons";
import moment from "moment";
import {useOutsideClick} from "../../../hooks/useOutsideClick";

const Calendar = ({
                      value,
                      setDate,
                      placeholder,
                      showDefault,
                      disableClick,
                      displayFormat,
                      returnFormat,
                      className,
                      paddingClassName,
                      gapClassName,
                      marginClassName,
                      selectedDateClassName,
                      showShortMonths,
                      optionClassName,
                      textSize,
                      onFocus
                  }) => {
    const [selectedDate, setSelectedDate] = useState(value ? moment(value, returnFormat) : moment())
    const [selectedMonth, setSelectedMonth] = useState(selectedDate.month())
    const [selectedYear, setSelectedYear] = useState(selectedDate.year())

    const [open, setOpen] = useState(false)

    const selectedRef = useRef(null)
    const optionsRef = useRef(null)

    useOutsideClick(optionsRef, () => setOpen(false))

    const toggleOpen = () => {
        setOpen(!open)
    }

    const daysOfWeek = ['Երկ', 'Երք', 'Չրք', 'Հնգ', 'Ուրբ', 'Շբթ', 'Կիր']
    const getDaysInMonth = (month, year) => {
        return moment([year, month]).daysInMonth()
    }

    const dayOfWeek = (moment([selectedYear, selectedMonth, 1]).isoWeekday() + 6) % 7
    const daysInMonth = getDaysInMonth(selectedMonth, selectedYear)

    const handleMonthChange = (newMonthIndex) => {
        setSelectedMonth(newMonthIndex)
        setSelectedDate(moment([selectedYear, newMonthIndex]))
    }

    const handleYearChange = (year) => {
        setSelectedYear(year);
        setSelectedDate(moment([year, selectedMonth]));
    }

    const handleDateSelect = (date) => {
        setSelectedDate(moment(date, displayFormat))
        setDate(moment(date, displayFormat).format(returnFormat))
        setOpen(!open)
    };

    useEffect(() => {
        const optionsElement = optionsRef.current

        if (open) {
            gsap.fromTo(
                optionsElement,
                {opacity: 0, height: 0, display: 'block'},
                {opacity: 1, height: 'auto', duration: 0.3}
            )
        } else {
            gsap.to(optionsElement, {opacity: 0, height: 0, duration: 0.3, display: 'none'})
        }
    }, [open])

    useEffect(() => {
        if (showDefault) {
            const today = moment()
            setSelectedDate(moment(today, displayFormat))
            setDate(moment(today, displayFormat).format(returnFormat))
        }
    }, [])

    return (
        <div onClick={onFocus} className={`${open && 'relative z-50'} overflow-hidden select-none bg-white`}>
            <div
                ref={selectedRef}
                onClick={disableClick ? null : toggleOpen}
                className={`flex justify-between items-center w-full px-[30px] cursor-pointer ${open ? 'border-b border-black pb-[7px] pointer-events-none' : 'pointer-events-auto'} ${className}`}
            >
                <span
                    className={`${!value ? 'text-gray-400' : 'text-black'}`}>{value ? selectedDate.format(displayFormat) : placeholder}</span>
                <button className={'flex justify-end'}><CalendarIcon/></button>
            </div>

            <div ref={optionsRef} className={`${open ? 'h-auto ' : 'h-0'} w-fit`}>
                <div
                    className={`flex justify-between py-5 ${paddingClassName || 'px-[30px]'} ${selectedDateClassName}`}>
                    <SelectYear selectedYear={selectedYear} handleYearChange={handleYearChange} className={'gap-1'} textSize={textSize}/>
                    <SelectMonth handleMonthChange={handleMonthChange} selectedMonth={selectedMonth} className={'gap-1'}
                                 arrowClassName={'p-0'} showShortMonths={showShortMonths} textSize={textSize}/>
                </div>
                <div
                    className={`${className || 'w-full'} ${optionClassName}`}>
                    <div className={`grid grid-cols-7 ${gapClassName || 'gap-[23px]'} w-fit place-items-center`}>
                        {daysOfWeek.map((day) => (
                            <div key={day} className={`calendar-cell-header ${textSize}`}>
                                {day}
                            </div>
                        ))}
                    </div>
                    <div className={`${marginClassName || 'mt-3.5'}`}>
                        {Array.from({length: Math.ceil((dayOfWeek + daysInMonth) / 7)}, (_, i) => (
                            <div key={i}
                                 className={`grid grid-cols-7 ${gapClassName || 'gap-[23px]'} ${showShortMonths && 'max-w-[297px]'}`}>
                                {Array.from({length: 7}, (_, j) => {
                                    const date = i * 7 + j - dayOfWeek + 1

                                    return date > 0 && date <= daysInMonth ?
                                        <div
                                            key={date}
                                            onClick={() =>
                                                handleDateSelect(moment([selectedYear, selectedMonth, date]))
                                            }
                                            className={`flex items-center justify-center transition cursor-pointer rounded-full w-[30px] h-[30px] ${
                                                selectedDate.date() === date ? 'bg-[#FCC10F] bg-opacity-50' : ''
                                            }`}
                                        >
                                            <span className={`${textSize}`}>{date}</span>
                                        </div>
                                        :
                                        <div key={date} className={`${textSize}`}></div>
                                })}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Calendar;
