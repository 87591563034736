import React, { useEffect, useRef, useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { ActionMenuIcon, CloseIcon, PdfColoredIcon, ReturnLeftIcon, TrashIcon } from "../../../../assets/icons";
import ButtonHoverText from "../../../shared/buttons/ButtonHoverText";
import { useOutsideClick } from "../../../../hooks/useOutsideClick";
import VideoPreviewModal from "./modals/VideoPreviewModal";
import { ConfirmationModal } from "../../../shared";
import { useDispatch } from "react-redux";
import { deleteProgramProject } from "../../../../store/actions/programs";
import { Link } from "react-router-dom";
import ImageModal from "./modals/ImageModal";

const ProjectItem = ({ _id, description, file, media, title, group, page }) => {
    const [showPdfViewer, setShowPdfViewer] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [videoPreview, setVideoPreview] = useState(null);
    const [projectToDelete, setProjectToDelete] = useState(null);
    const [showImageModal, setShowImageModal] = useState(false);
    const [modalImage, setModalImage] = useState(null);

    const isImage = media.endsWith('.jpg') || media.endsWith('.jpeg') || media.endsWith('.png') || media.endsWith('.gif') || media.endsWith(".webp");

    const dispatch = useDispatch();

    const ref = useRef(null);
    useOutsideClick(ref, () => setShowMenu(false));

    const openPdfViewer = () => {
        setShowPdfViewer(true);
    };

    const closePdfViewer = () => {
        setShowPdfViewer(false);
    };

    const handleDeleteProject = () => {
        dispatch(deleteProgramProject(_id));
    };

    const openImageModal = (imageSrc) => {
        setModalImage(imageSrc);
        setShowImageModal(true);
    };

    const closeImageModal = () => {
        setShowImageModal(false);
    };

    useEffect(() => {
        if (showPdfViewer || videoPreview || showImageModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [showPdfViewer, videoPreview, showImageModal]);

    return (
        <>
            {projectToDelete && (
                <ConfirmationModal
                    icon={<TrashIcon width={50} height={50} />}
                    warningText={'Վստա՞հ եք, որ ցանկանում եք ջնջել այս նախագիծը։'}
                    close={() => setProjectToDelete(null)}
                    confirm={handleDeleteProject}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            )}
            {videoPreview && (
                <VideoPreviewModal
                    video={media}
                    close={() => setVideoPreview(null)}
                />
            )}
            {showPdfViewer && (
                <>
                    <div className="pdf-overlay bg-black bg-opacity-20" onClick={closePdfViewer}></div>
                    <div className="pdf-viewer-modal overflow-hidden">
                        <div className={'flex items-center justify-between'}>
                            <div
                                onClick={() => setShowPdfViewer(null)}
                                className={'group flex items-center gap-[30px] py-[10px] cursor-pointer fill-icon'}>
                                <ReturnLeftIcon/>
                                <div className={'flex items-center gap-[10px]'}>
                                    <PdfColoredIcon/>
                                    <span className={'transition hover:text-[#FCC10F]'}>{title}</span>
                                </div>
                            </div>
                            <div
                                onClick={() => setShowPdfViewer(null)}
                                className={'w-[30px] h-[30px] border border-black bg-white rounded-full p-[5px] shadow-md cursor-pointer'}>
                                <CloseIcon width={20} height={20}/>
                            </div>
                        </div>
                        <div className="pdf-viewer-container">
                            <div className="pdf-viewer-content">
                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                    <Viewer fileUrl={file}/>
                                </Worker>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {showImageModal && (
                <ImageModal
                    close={closeImageModal}
                    imageSrc={modalImage}
                />
            )}
            <div ref={ref} className={'flex gap-[74px] shadow-xl rounded-[5px] p-5 mt-5'}>
                <div className={'flex items-center justify-center w-full max-w-[234px] h-[127px]'}>
                    {isImage ?
                        <img
                            src={media}
                            alt={'image'}
                            className={'w-auto h-full object-cover cursor-pointer'}
                            onClick={() => openImageModal(media)}
                        />
                        :
                        <div className="video-wrapper" onClick={() => setVideoPreview(true)}>
                            <video src={media} className={'w-full h-full'}></video>
                        </div>
                    }
                </div>
                <div className={'relative w-full flex flex-col gap-5'}>
                    <div className={'grid grid-cols-2'}>
                        <div className={'grid grid-cols-2'}>
                            <Link to={`/groups/${group?._id}/attendance`}>{group?.name}</Link>
                            <p>{title}</p>
                        </div>
                        <div className={'absolute z-50 right-0'}>
                            {file && (
                                <ButtonHoverText
                                    icon={<PdfColoredIcon/>}
                                    text={'Բացել ֆայլը'}
                                    onClick={openPdfViewer}
                                    className={'mr-5'}
                                />
                            )}
                            <button
                                className={` ${showMenu ? 'pointer-events-none' : 'pointer-events-auto'}`}
                                onClick={() => setShowMenu(!showMenu)}
                            >
                                <ActionMenuIcon width={20} height={20}/>
                            </button>
                        </div>
                    </div>
                    {showMenu && (
                        <div ref={ref} className={`absolute z-50 w-[163] top-[30px] flex flex-col gap-4 bg-white right-0 py-5 border border-black rounded-[5px]`}>
                            <>
                                <p
                                    // onClick={deleteProgram}
                                    className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                    Խմբագրել
                                </p>
                                <p
                                    onClick={() => setProjectToDelete(true)}
                                    className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                    Ջնջել
                                </p>
                            </>
                        </div>
                    )}
                    <div className={'text-black text-opacity-70 break-all'}>{description}</div>
                </div>
            </div>
        </>
    );
};

export default ProjectItem;
