import React from 'react';
import {CloseIcon} from "../../assets/icons";
import {ButtonFilled, ButtonOutlined} from "./buttons";

const ConfirmationModal = ({close, confirm, warningText, infoText, icon, cancelText, confirmText}) => {
    return (
        <div className={'fixed z-[9990] w-screen h-screen left-0 top-0 flex justify-center'}>
            <div onClick={close} className={'fixed bg-opacity-5 backdrop-blur w-full h-full top-0 left-0 z-[9990]'}></div>

            <div className={'flex flex-col items-center bg-white absolute top-[180px] z-[9999] border border-black border-opacity-70 px-[142px] py-[50px] rounded-[10px]'}>
                <button className={'absolute top-5 right-5 bg-black bg-opacity-5 rounded-full p-1.5'} onClick={close}>
                    <CloseIcon width={20} height={20}/>
                </button>
                <div>
                    {icon}
                </div>
                <div className={'flex flex-col items-center gap-[30px] mt-[50px]'}>
                    <p>{warningText}</p>
                    <p>{infoText}</p>
                </div>
                <div className={'flex gap-[30px] mt-[60px]'}>
                    <ButtonOutlined onClick={confirm} text={confirmText} className={'w-[176px]'}/>
                    <ButtonFilled onClick={close} text={cancelText} className={'w-[176px]'}/>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;