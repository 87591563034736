import React from 'react';
import login_bg from '../assets/images/login/login-bg.png'
import {Container} from "../components/shared";
import LoginForm from "../components/login/LoginForm.jsx";

const Login = () => {
    return (
        <div>
            <Container className={'pt-[70px] pb-[86px]'}>
                <div className={'grid grid-cols-2'}>
                    <div>
                        <img src={login_bg} alt={''} className={'h-[594px]'}/>
                    </div>

                    <LoginForm/>
                </div>
            </Container>
        </div>
    );
};

export default Login;