import React, {useEffect, useState} from 'react';
import { Container } from "../../../components/shared";
import AddButton from "../../../components/shared/buttons/AddButton";
import { DropdownInputCheckbox } from "../../../components/shared/dropdowns";
import { communitySortOptions } from "../../../common/dropdownOptions";
import CommunityItem from "../../../components/communities/CommunityItem";
import {useDispatch, useSelector} from "react-redux";
import {getCommunities} from "../../../store/actions/communities";
import {useLocation, useNavigate} from "react-router-dom";
import {parseQueryParams} from "../../../utils/convertQueryFilters";

const Communities = () => {
    const {communities} = useSelector(state => state.communities)
    const [sortOption, setSortOption] = useState(communitySortOptions[0])

    const dispatch = useDispatch()

    const navigate = useNavigate()
    const location = useLocation()

    const handleChangeSortOption = (name, option) => {
        navigate(`/communities?sortOption=${option._id}`)
    }

    useEffect(() => {
        const {sortOption: sortParam} = parseQueryParams(window.location.href)

        setSortOption(communitySortOptions.find(item => item._id === sortParam))

        dispatch(getCommunities(location.search))
    }, [location])

    return (
        <div>
            <Container>
                <h2 className={'text-[50px] text-[#FCC10F] font-bold text-center'}>Համայնքներ</h2>

                <div className={'flex items-center justify-between'}>
                    <AddButton text={'Նոր համայնք'}/>
                    <div className={'flex items-center gap-5 mt-[14px]'}>
                        <p className={'text-[12px]'}>Դասակարգել</p>
                        <div className={'w-[518px]'}>
                            <DropdownInputCheckbox
                                data={communitySortOptions}
                                name={'community'}
                                onChange={handleChangeSortOption}
                                selected={[sortOption]}
                                previewClassName={'py-[7.2px] rounded-[5px] px-[30px]'}
                                optionClassName={'px-[30px]'}
                                textSize={'text-[12px]'}
                                hideCheckbox
                                disableInput
                            />
                        </div>
                    </div>
                </div>

                <div className={'grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-[33px]'}>
                    {communities.map((community, index) => <CommunityItem key={index}  {...community} />)}
                </div>
            </Container>
        </div>
    );
};

export default Communities
