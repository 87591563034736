import React, {useEffect, useRef, useState} from 'react';
import {
    AsteriskIcon,
    CameraIcon,
    ClosedEyeIcon,
    CloseIcon,
    DoneIcon,
    OpenedEyeIcon,
    UnderdoneIcon
} from "../../../assets/icons";
import {ButtonFilled, ButtonOutlined} from "../../shared/buttons";
import {useDispatch, useSelector} from "react-redux";
import Schedule from "./schedule/Schedule";
import DropdownInputCheckbox from "../../shared/dropdowns/DropdownInputCheckbox";
import {createMember, editProfile} from "../../../store/actions/profile";

const initialState = {
    fullName: '',
    position: '',
    email: '',
    password: '',
    confirmPassword: '',
    role: null,
    division: null,
    workingSchedule: []
}

const CreateMemberModal = ({close, memberData}) => {
    const {divisions} = useSelector(state => state.profile);
    const {roles} = useSelector(state => state.common);

    const [data, setData] = useState(memberData === 'create' ? initialState : memberData);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [errors, setErrors] = useState({});

    const editMode = !!memberData && !!memberData._id;

    const dispatch = useDispatch();

    const imageUploadRef = useRef(null);

    const handleImageUpload = (e) => {
        const selectedImage = e.target.files[0];
        if (selectedImage) {
            setUploadedImage(selectedImage);

            setErrors(prevErrors => ({
                ...prevErrors,
                image: ''
            }));
        }
    }

    const handleChangeSchedule = (newSchedule) => {
        setData({
            ...data,
            workingSchedule: newSchedule
        });
    }

    const handleInputChange = (name, value) => {
        setData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSelectDivision = (name, selectedDivision) => {
        console.log(selectedDivision)
        setData(prevState => ({
            ...prevState,
            division: selectedDivision
        }));
    }

    const transformRolesData = (roles) => {
        return Object.keys(roles).map((key) => ({
            name: roles[key],
            _id: key,
        }));
    }

    const transformedRolesData = transformRolesData(roles);

    const handleRoleChange = (name, selectedOption) => {
        setData(prevData => ({
            ...prevData,
            role: selectedOption._id
        }));
    }

    const handlePasswordChange = (e) => {
        const {name, value} = e.target;
        setData(prevState => ({...prevState, [name]: value}));

        const requirements = [
            {regex: /.{8,}/, message: 'Առնվազն 8 տառ'},
            {regex: /[A-Z]/, message: 'Առնվազն 1 մեծատառ'},
            {regex: /\d/, message: 'Առնվազն 1 թիվ'},
            {regex: /[*$&!#]/, message: 'Առնվազն 1 նշան'}
        ];

        const passwordErrors = requirements.map(req => ({
            message: req.message,
            isValid: req.regex.test(value)
        }))

        const allRequirementsMet = passwordErrors.every(error => error.isValid);

        setErrors(prevErrors => ({
            ...prevErrors,
            password: allRequirementsMet ? [] : passwordErrors
        }))
    }

    const handleRepeatPasswordChange = (e) => {
        const {name, value} = e.target;
        setData(prevState => ({
            ...prevState,
            [name]: value
        }));

        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: undefined
        }));
    }

    const handleCreateMember = () => {
        const requiredFields = ['fullName', 'position', 'role', 'email', 'division', 'workingSchedule'];
        const newErrors = {};

        requiredFields.forEach(field => {
            if (!data[field] || (Array.isArray(data[field]) && !data[field].length)) {
                newErrors[field] = `Այս դաշտը պարտադիր է`;
            }
        });

        if (!data.profileImage && !uploadedImage) {
            newErrors.image = "Այս դաշտը պարտադիր է";
        }

        if (!editMode) {
            if (!data.password) {
                newErrors.password = "Այս դաշտը պարտադիր է";
            }

            if (!data.confirmPassword) {
                newErrors.confirmPassword = "Այս դաշտը պարտադիր է";
            }
        }

        if (data.password !== data.confirmPassword) {
            newErrors.confirmPassword = "Գաղտնաբառերը չեն համընկնում";
        }

        if (Object.keys(newErrors).length) {
            setErrors(prevErrors => ({...prevErrors, ...newErrors}));
            return;
        }

        const formData = new FormData();
        formData.append('fullName', data.fullName);
        formData.append('position', data.position);
        formData.append('role', data.role);
        formData.append('email', data.email);
        formData.append('division', data.division._id);
        formData.append('workingSchedule', JSON.stringify(data.workingSchedule));
        formData.append('password', data.password)
        formData.append('confirmPassword', data.confirmPassword)

        // if (!editMode) {
        //     formData.append('password', data.password);
        //     formData.append('confirmPassword', data.confirmPassword);
        // }

        formData.append('image', uploadedImage);

        if (editMode) {
            dispatch(editProfile(memberData._id, formData))
        } else {
            dispatch(createMember(formData));
        }

        close();
    }

    useEffect(() => {
        if (memberData !== 'create') {
            setData({
                ...memberData,
                division: memberData.division || initialState.division,
                role: memberData.role || initialState.role,
                password: '',
                confirmPassword: ''
            });
        }
    }, [memberData])

    return (
        <div className={'fixed z-[9990] w-screen h-screen top-0 left-0 flex items-center justify-center mt-10'}>
            <div onClick={close}
                 className={'fixed bg-black bg-opacity-5 backdrop-blur w-full h-full top-0 left-0 z-[9990]'}></div>
            <div
                className={'grid grid-rows-[70px,auto] w-full max-w-[879px] max-h-[850px] overflow-hidden z-[9999] border border-black rounded-[10px] bg-white'}>
                <div className={'relative flex items-center justify-center py-[23px]'}>
                    <h2 className={'text-center'}>{editMode ? 'Փոփոխել օգտաէջը' : 'Նոր օգտաէջ'}</h2>
                    <button className={'absolute right-6 bg-black bg-opacity-5 rounded-full p-1.5'} onClick={close}>
                        <CloseIcon width={20} height={20}/>
                    </button>
                </div>
                <div className={'flex gap-[30px] mx-[30px] h-[calc(100vh-200px-70px)] overflow-scroll'}>
                    <div className={'flex flex-col gap-3'}>
                        <div onClick={() => imageUploadRef.current.click()}
                             className={`w-[200px] h-[200px] flex items-center justify-center cursor-pointer`}>
                            <input type={"file"} accept={'image/*'} className={'hidden'} id={"upload_image"}
                                   ref={imageUploadRef} onChange={handleImageUpload}/>
                            {uploadedImage ?
                                <img src={URL.createObjectURL(uploadedImage)} alt="uploaded"
                                     className="max-w-[200px] h-full object-cover rounded-full"/>
                                : memberData && memberData.profileImage ?
                                    <img src={memberData.profileImage} alt="uploaded"
                                         className="max-w-[200px] h-full object-cover rounded-full"/>
                                    :
                                    <div
                                        className={'w-full h-full flex items-center justify-center border-[7px] border-[#FCC10F] rounded-full'}>
                                        <CameraIcon color={'#FCC10F'} height={100} width={100}/>
                                    </div>
                            }
                        </div>
                        {errors?.image && <p className={'text-red-500'}>{errors.image}</p>}
                    </div>
                    <div className={`flex flex-col w-[548px] gap-5`}>
                        <p className={'flex items-center gap-[15px] text-[#FF0707]'}>
                            <AsteriskIcon/>
                            <span>դաշտերը պարտադիր լրացման են</span>
                        </p>
                        <div className={'flex flex-col gap-2.5'}>
                            <p className={'flex gap-1'}>
                                <span>Անուն, ազգանուն, հայրանուն </span>
                                <AsteriskIcon/>
                            </p>
                            <input
                                type={'text'}
                                name={'fullName'}
                                value={data?.fullName}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                className={'w-full h-[30px] border border-black border-opacity-70 rounded-[3px] outline-none px-[30px]'}
                            />
                            {errors.fullName && <p className={'text-red-500'}>{errors.fullName}</p>}
                        </div>

                        <div className={'flex flex-col gap-2.5'}>
                            <p className={'flex gap-1'}>
                                <span>Պաշտոն </span>
                                <AsteriskIcon/>
                            </p>
                            <input
                                type={"text"}
                                name={"position"}
                                value={data?.position}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                className={'w-full h-[30px] border border-black border-opacity-70 rounded-[3px] outline-none px-[30px]'}
                            />
                            {errors.position && <p className={'text-red-500'}>{errors.position}</p>}
                        </div>

                        <div className={'flex flex-col gap-2.5'}>
                            <p className={'flex gap-1'}>
                                <span>Դեր </span>
                                <AsteriskIcon/>
                            </p>
                            <div className={'relative'}>
                                <DropdownInputCheckbox
                                    data={transformedRolesData}
                                    name={'role'}
                                    previewClassName={'px-[30px] rounded-[3px] h-[30px] border-opacity-70'}
                                    optionClassName={'px-6'}
                                    onChange={handleRoleChange}
                                    selected={data?.role ? [{
                                        _id: data?.role,
                                        name: roles[data?.role]
                                    }] : []}
                                    dropdownClassName={'relative z-[9999]'}
                                    hideCheckbox
                                    disableInput={true}
                                />
                            </div>
                            {errors.role && <p className={'text-red-500'}>{errors.role}</p>}
                        </div>

                        <div className={'flex flex-col gap-2.5'}>
                            <p className={'flex gap-1'}>
                                <span>Էլեկտրոնային փոստ </span>
                                <AsteriskIcon/>
                            </p>
                            <input
                                type={"email"}
                                name={'email'}
                                autoComplete={'off'}
                                value={data?.email}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                className={'w-full h-[30px] border border-black border-opacity-70 rounded-[3px] outline-none px-[30px]'}
                            />
                            {errors.email && <p className={'text-red-500'}>{errors.email}</p>}
                        </div>

                        <div className={'relative z-50 flex flex-col gap-2.5'}>
                            <p className={'flex gap-1'}>
                                <span className={''}>Աշխատանքային գրաֆիկ </span>
                                <AsteriskIcon/>
                            </p>
                            <div className={'relative'}>
                                <Schedule
                                    schedule={data.workingSchedule}
                                    onChange={handleChangeSchedule}
                                    onFocus={() => {
                                        setErrors(prevErrors => ({
                                            ...prevErrors,
                                            schedule: ''
                                        }))
                                    }}
                                />
                                {errors.workingSchedule &&
                                    <p className={'text-red-500 pt-2.5'}>{errors.workingSchedule}</p>}
                            </div>
                        </div>

                        <div className={'flex flex-col gap-2.5'}>
                            <p className={'flex gap-1'}>
                                <span>Դիվիժն </span>
                                <AsteriskIcon/>
                            </p>
                            <DropdownInputCheckbox
                                data={divisions}
                                name={'division'}
                                previewClassName={'px-[30px] rounded-[3px] h-[30px] border-opacity-70'}
                                optionClassName={'px-6'}
                                onChange={handleSelectDivision}
                                selected={data?.division ? [data.division] : []}
                                hideCheckbox
                            />
                            {errors.division && <p className={'text-red-500'}>{errors.division}</p>}
                        </div>

                        <div className={'relative flex flex-col gap-2.5'}>
                            <p className={'flex gap-1'}>
                                <span>Գաղտնաբառ </span>
                                {!editMode && <AsteriskIcon/>}
                            </p>
                            <div
                                className={'flex items-center w-full h-[30px] border border-black border-opacity-70 rounded-[3px] outline-none px-[30px]'}>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    value={data?.password}
                                    onChange={handlePasswordChange}
                                    name={'password'}
                                    className={'w-full outline-none border-none'}
                                />
                                <button onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <OpenedEyeIcon width={20} height={20}/> :
                                        <ClosedEyeIcon width={20} height={20}/>}
                                </button>
                            </div>
                            {Array.isArray(errors.password) && errors.password.map((error, index) => (
                                <p key={index}
                                   className={`flex items-center gap-2.5 ${error.isValid ? 'text-green-500' : 'text-[#FF0707]'}`}>
                                    {!error.isValid ? <UnderdoneIcon/> : <DoneIcon/>} <span>{error.message}</span>
                                </p>
                            ))
                            }
                        </div>

                        <div className={'flex flex-col gap-2.5'}>
                            <p className={'flex gap-1'}>
                                <span>Կրկնել գաղտնաբառը </span>
                                {!editMode && <AsteriskIcon/>}
                            </p>
                            <div
                                className={'flex items-center w-full h-[30px] border border-black border-opacity-70 rounded-[3px] outline-none px-[30px]'}>
                                <input
                                    type={showRepeatPassword ? 'text' : 'password'}
                                    name="confirmPassword"
                                    value={data?.confirmPassword}
                                    onChange={handleRepeatPasswordChange}
                                    className={'w-full outline-none border-none'}
                                />
                                <button onClick={() => setShowRepeatPassword(!showRepeatPassword)}>
                                    {showRepeatPassword ? <OpenedEyeIcon width={20} height={20}/> :
                                        <ClosedEyeIcon width={20} height={20}/>}
                                </button>
                            </div>
                            {errors.confirmPassword &&
                                <p className={'text-red-500'}>{errors.confirmPassword}</p>}
                        </div>

                        <div className={'flex items-center justify-end gap-[30px] pb-5'}>
                            <ButtonOutlined onClick={close} text={'Չեղարկել'} className={'w-[176px]'}/>
                            <ButtonFilled onClick={handleCreateMember} text={editMode ? 'Պահպանել' : 'Ստեղծել'}
                                          className={'w-[176px] shadow-md'}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateMemberModal;
