import React from 'react';
import {NavLink} from "react-router-dom";

const SettingsNavbar = () => {
    return (
        <div className={'flex items-center gap-[100px]'}>
            <NavLink to={`/settings/members`}
                     className={({isActive}) => `${isActive ? 'text-[#FCC10F]' : 'text-black'}`}>
                Դերեր և օգտաէջեր
            </NavLink>
            <NavLink to={`/settings/notifications`}
                     className={({isActive}) => `${isActive ? 'text-[#FCC10F]' : 'text-black'}`}>
                Ծանուցումներ
            </NavLink>
            <NavLink to={`/settings/divisions`}
                     className={({isActive}) => `${isActive ? 'text-[#FCC10F]' : 'text-black'}`}>
                Դիվիժն
            </NavLink>
        </div>
    );
};

export default SettingsNavbar;