import React from 'react';
import {Link} from "react-router-dom";
import {SettingsIcon} from "../../../assets/icons";

const EventHeader = ({image, name}) => {
    return (
        <div className={'flex items-center justify-between'}>
            <div><img src={image} alt={'groupImage'} className={'max-w-[50px] max-h-[50px]'}/></div>
            <h2 className={'max-w-[800px] break-all text-[50px] text-[#FCC10F] font-bold text-center'}>{name}</h2>
            <Link to={'settings'}><SettingsIcon/></Link>
        </div>
    );
};

export default EventHeader;