import React from 'react';
import LessonItem from "./LessonItem";
import moment from "moment";
import {countStudentAttendanceInLevel} from "../../../../utils/groups/countStudentAttendanceInLevel";
import TotalAttendance from "./TotalAttendance";

const Level = ({
                   level,
                   index,
                   levelsCount,
                   markAttendance,
                   openReplaceLessonModal,
                   openCancelLessonModal,
                   openRestoreCanceledLessonModal,
                   lineHovered,
                   hoverLine,
                   activeStudents,
                   showDropped,
                   isParentScrolling
               }) => {
    const studentsAttendanceInLevel = countStudentAttendanceInLevel(level)

    return (
        <div className={'w-full flex border-r'}>
            {level?.map((lesson, i) => {
                const prevLessonYear = i !== 0 && moment(level[i - 1][0], 'YYYY-MM-DD').year()
                const currentLessonYear = moment(lesson[0], 'YYYY-MM-DD').year()
                const showYear = i === 0 || currentLessonYear !== prevLessonYear
                const isLast = i === level.length - 1

                return <LessonItem
                    key={i}
                    date={lesson[0]}
                    lessonData={lesson[1]}
                    lessonIndex={i}
                    levelIndex={index}
                    showYear={showYear}
                    isLast={isLast}
                    markAttendance={markAttendance}
                    openReplaceLessonModal={openReplaceLessonModal}
                    openCancelLessonModal={openCancelLessonModal}
                    openRestoreCanceledLessonModal={openRestoreCanceledLessonModal}
                    lineHovered={lineHovered}
                    hoverLine={hoverLine}
                    activeStudents={activeStudents}
                    levelsCount={levelsCount}
                    showDropped={showDropped}
                    isParentScrolling={isParentScrolling}
                />
            })}

            {levelsCount > 1 &&
                <div className={'w-fit border-l bg-[#FFFCF2]'}>
                    <div className={'h-[82px] text-[13px] flex flex-col items-center gap-2.5 px-1 pt-5 border-y'}>
                        <p>Մակարդակ</p>
                        <p>{index + 1}</p>
                    </div>

                    <TotalAttendance
                        data={studentsAttendanceInLevel}
                        lineHovered={lineHovered}
                        hoverLine={hoverLine}
                        activeStudents={activeStudents}
                        showDropped={showDropped}
                    />
                </div>
            }
        </div>
    )
}

export default Level