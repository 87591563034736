import React, {useEffect, useState} from 'react';
import {FillFormRequest, Login} from "./pages";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import DefaultHeader from "./components/header/DefaultHeader";
import {Main} from "./pages/main";
import MainHeader from "./components/header/MainHeader";
import {checkAuth} from "./store/actions/auth";

const App = () => {
    const {authData, loading} = useSelector(state => state.auth)
    const [isPublicPage, setIsPublicPage] = useState(false)

    const dispatch = useDispatch()

    const location = useLocation()

    useEffect(() => {
        if (location.pathname.includes('/forms/view/')) {
            setIsPublicPage(true)
        } else {
            setIsPublicPage(false)
        }

        window.scrollTo(0, 0)
    }, [location])

    useEffect(() => {
        dispatch(checkAuth())
    }, [])

    // if(!authData && loading) return <p>Loading...</p>

    return (
        <>
            {(authData && authData !== 'unauthorized') ? isPublicPage ? <DefaultHeader/> : <MainHeader /> : <DefaultHeader/>}

            <div className={'pt-20'}>
                <Routes>
                    {authData && authData !== 'unauthorized'
                        ?
                        <>
                            <Route path='/*' element={<Main/>}/>
                        </>
                        :
                        <>
                            <Route path='/' element={<Login/>}/>
                            <Route path="*" element={<Navigate to="/" replace={true}/>}/>
                        </>
                    }

                    <Route path='/forms/view/:id' element={<FillFormRequest/>}/>
                </Routes>
            </div>
        </>
    );
};

export default App;
