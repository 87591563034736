import React, {useEffect, useRef, useState} from 'react';
import {ConfirmationModal} from "../shared";
import {Link} from "react-router-dom";
import {ActionMenuIcon, AgeGroupIcon, CalendarIcon, GeoIcon, PeopleIcon, StatusIcon} from "../../assets/icons";
import ButtonHoverText from "../shared/buttons/ButtonHoverText";
import {daysOfWeek, groupStatusNames} from "../../common/dropdownOptions";
import {useOutsideClick} from "../../hooks/useOutsideClick";
import {useDispatch} from "react-redux";
import {changeGroupStatus} from "../../store/actions/groups";
import moment from "moment";

const statusColors = {
    active: '#20BA07',
    finished: '#FF0707',
    closed: 'rgba(0, 0, 0, 0.6)',
}

const EventCard = ({
                       _id,
                       name,
                       startDate,
                       endDate,
                       studentsCount,
                       status,
                       image,
                       program,
                       place,
                       workshopGuest,
                       workshopToDelete,
                       setWorkshopToDelete,
                       handleDeleteWorkshop,
                       connectToDelete,
                       setConnectToDelete,
                       handleDeleteConnect,
                       tripToDelete,
                       setTripToDelete,
                       handleDeleteTrip,
                       schedule,
                       type,
                       date,
                   }) => {
    const [showMenu, setShowMenu] = useState(false)
    const [statusToChange, setStatusToChange] = useState(null)

    const ref = useRef(null)
    useOutsideClick(ref, () => setShowMenu(false))

    const dispatch = useDispatch()

    const textColorStyle = statusColors.hasOwnProperty(status) ? {color: statusColors[status]} : {}

    const modifiedStartDate = moment(startDate).format('DD.MM.YYYY')
    const modifiedEndDate = moment(endDate).format('DD.MM.YYYY')
    const modifiedDate = moment(date).format('DD.MM.YYYY')

    const deleteEventCard = () => {
        setWorkshopToDelete && setWorkshopToDelete(_id)
        setConnectToDelete && setConnectToDelete(_id)
        setTripToDelete && setTripToDelete(_id)
    }

    const closeEvent = () => {
        setWorkshopToDelete && setWorkshopToDelete(null)
        setConnectToDelete && setConnectToDelete(null)
        setTripToDelete && setTripToDelete(null)
    }

    const changeStatus = () => {
        setStatusToChange(true)
    }

    const handleChangeStatus = () => {
        let newStatus
        if (status === 'active' || status === 'finished') {
            newStatus = 'closed'
        } else if (status === 'closed') {
            newStatus = 'active'
        }

        dispatch(changeGroupStatus(_id, newStatus))
        setShowMenu(false)
        setStatusToChange(false)
    }

    useEffect(() => {
        document.body.style.overflow = workshopToDelete || connectToDelete || statusToChange ? 'hidden' : 'auto'
    }, [workshopToDelete, statusToChange])

    return (
        <>
            {(workshopToDelete || connectToDelete || tripToDelete) &&
                <ConfirmationModal
                    close={closeEvent}
                    warningText={`Վստա՞հ եք, որ ցանկանում եք ջնջել այս խումբը։`}
                    confirm={handleDeleteWorkshop || handleDeleteConnect || handleDeleteTrip}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            {statusToChange &&
                <ConfirmationModal
                    close={() => setStatusToChange(false)}
                    warningText={`${status === 'active' || status === 'finished' ? 'Վստա՞հ եք, որ ցանկանում եք փակել այս խումբը։' : 'Վստա՞հ եք, որ ցանկանում եք ակտիվացնել այս խումբը։'}`}
                    confirm={handleChangeStatus}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            <div className={'relative w-[405px] h-[392px] py-[25px] px-[29px] rounded-[10px] custom-shadow'}>
                <div
                    className={`${showMenu ? 'block' : 'hidden'} absolute w-full h-full z-40 backdrop-blur-[1px]`}></div>
                <div>
                    <div className={'flex justify-center'}>
                        <div className={'max-w-[250px] break-all line-clamp-2 text-[#FCC10F] text-center'}>
                            {type === 'workshop' &&
                                <>
                                    <Link to={`/workshops/${_id}`}>{name}</Link>
                                    <h2>{workshopGuest}</h2>
                                </>
                            }
                            {type === 'connect' &&
                                <Link to={`/connects/${_id}`}>{name}</Link>
                            }
                            {type === 'trips' &&
                                <Link to={`/trips/${_id}`}>{name}</Link>
                            }
                        </div>

                        <button
                            className={`absolute z-50 right-5 ${showMenu ? 'pointer-events-none' : 'pointer-events-auto'}`}
                            onClick={() => setShowMenu(!showMenu)}
                        >
                            <ActionMenuIcon/>
                        </button>
                    </div>

                    {showMenu && (
                        <div ref={ref}
                             className={`absolute z-50 w-[163px] top-[60px] flex flex-col gap-4 bg-white right-5 py-6 border border-black rounded-[5px]`}>
                            {status === 'active'
                                &&
                                <>
                                    <p
                                        onClick={deleteEventCard}
                                        className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                        Ջնջել
                                    </p>
                                    <p
                                        onClick={changeStatus}
                                        className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                        {status === 'active' ? 'Փակել' : 'Ակտիվացնել'}
                                    </p>
                                </>
                            }
                            {status === 'finished'
                                &&
                                <p
                                    onClick={changeStatus}
                                    className={'px-[30px] py-[3px] transition hover:bg-gray-200 cursor-pointer'}>
                                    Փակել
                                </p>
                            }
                            {status === 'closed'
                                &&
                                <p
                                    onClick={changeStatus}
                                    className={'px-[30px] py-[3px] transition hover:bg-gray-200 cursor-pointer'}>
                                    Ակտիվացնել
                                </p>
                            }
                        </div>
                    )}
                </div>
                <div
                    className={`w-full h-full max-h-[152px] flex justify-center ${type === 'trips' ? 'mt-10 mb-[15px]' : 'mt-5 mb-[50px]'} px-11`}>
                    {type === 'workshop' &&
                        <Link to={`/workshops/${_id}`}>
                            <img src={image} alt={'intro_image'} className={'max-h-[149px] w-full'}/>
                        </Link>
                    }
                    {type === 'connect' &&
                        <Link to={`/connects/${_id}`}>
                            <img src={image} alt={'intro_image'} className={'max-h-[149px] w-full'}/>
                        </Link>
                    }
                    {type === 'trips' &&
                        <Link to={`/trips/${_id}`}>
                            <img src={image} alt={'intro_image'} className={'max-h-[149px] w-full'}/>
                        </Link>
                    }
                </div>
                <div className={`flex flex-col ${type === 'trips' ? 'gap-5' : 'gap-[30px]'}`}>
                    <div className={'flex items-center justify-between'}>
                        <div className={'w-[90px] max-h-[70px] flex items-start flex-col'}>
                            {type === 'trips'
                                ?
                                <div className={'flex flex-col gap-5'}>
                                    <h2 title={program?.name} className={'line-clamp-1'}>{program?.name}</h2>
                                    <p className={'relative group flex items-center gap-2.5'}>
                                        <PeopleIcon/>
                                        <span
                                            className={`absolute w-[180px] left-[30%] top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Ուսանողների քանակ</span>
                                        <span>{studentsCount}</span>
                                    </p>
                                </div>
                                :
                                <h2 title={program?.name} className={'line-clamp-1'}>{program?.name}</h2>
                            }

                        </div>
                        {type === 'workshop' &&
                            <p className={'flex items-center gap-2.5'}>
                                <span>{modifiedStartDate}-{modifiedEndDate}</span>
                            </p>
                        }
                        {type === 'connect'
                            &&
                            <div className={'flex items-center gap-5'}>
                                <p>{modifiedStartDate}</p>
                                <p>{schedule?.map(scheduleTimes => <span
                                    key={scheduleTimes.dayIndex}>{scheduleTimes.startTime}</span>)}</p>
                            </div>
                        }
                        {type === 'trips'
                            &&
                            <div className={'flex items-end flex-col gap-5'}>
                                <p>{modifiedDate}</p>
                                <p>{schedule?.map(scheduleTimes => <span
                                    key={scheduleTimes.dayIndex}>{scheduleTimes.startTime}</span>)}</p>
                            </div>
                        }
                    </div>
                    <div className={'flex items-center justify-between'}>
                        {type === 'trips'
                            ?
                            <div className={'flex items-center gap-2.5'}>
                                <GeoIcon color={'#000'}/>
                                <p>{place}</p>
                            </div>
                            :
                            <p className={'relative group flex items-center gap-2.5'}>
                                <PeopleIcon/>
                                <span>{studentsCount}</span>
                                <span
                                    className={`absolute w-[180px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Ուսանողների քանակ</span>
                            </p>
                        }
                        <p className={`flex items-center capitalize gap-[10px] select-none`}>
                            <span style={textColorStyle}>{groupStatusNames[status]}</span>
                            <StatusIcon color={statusColors[status]}/>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventCard;