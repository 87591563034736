import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AsteriskIcon, CameraIcon, CloseIcon} from "../../../../../assets/icons";
import DropdownInputCheckbox from "../../../../shared/dropdowns/DropdownInputCheckbox";
import {ButtonFilled, ButtonOutlined} from "../../../../shared/buttons";
import {createDriver} from "../../../../../store/actions/transport";
import Calendar from "../../../../shared/calendar/Calendar";
import {PhoneInput} from "../../../../shared";
import {driverLicenseData} from "../../../../../common/dropdownOptions";
import {getVehicleMarks} from "../../../../../store/actions/common";

const initialState = {
    fullName: '',
    email: '',
    birthday: '',
    registrationAddress: '',
    address: '',
    passport: '',
    phoneModel: '',
    driverLicense: [],
    phoneNumbers: [],
    vehicles: [],
}

const CreateDriverModal = ({close}) => {
    const {vehicleMarks} = useSelector(state => state.common)

    const [data, setData] = useState(initialState)
    const [uploadedImage, setUploadedImage] = useState(null)
    const [validationErrors, setValidationErrors] = useState({})

    const dispatch = useDispatch();

    const imageUploadRef = useRef(null);

    const handleImageUpload = (e) => {
        const selectedImage = e.target.files[0];
        if (selectedImage) {
            setUploadedImage(selectedImage);

            setValidationErrors(prevErrors => ({
                ...prevErrors,
                image: ''
            }));
        }
    }

    const changeBirthday = (e) => {
        setData({...data, birthday: e})
    }

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleFocus = (field) => {
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [field]: ''
        }));
    }

    const handleSelectOption = (field, selectedOptions) => {
        if (field === 'vehicle1') {
            setData(prevData => ({
                ...prevData,
                vehicles: [
                    selectedOptions,
                    prevData.vehicles[1] || null
                ].filter(Boolean)
            }));
        } else if (field === 'vehicle2') {
            setData(prevData => ({
                ...prevData,
                vehicles: [
                    prevData.vehicles[0] || null,
                    selectedOptions
                ].filter(Boolean)
            }));
        }
    };

    const handleAddDriverLicense = (_, value) => {
        const isSelected = data.driverLicense?.some(item => item._id === value._id)

        if (isSelected) {
            const updatedLicenses = data.driverLicense?.filter(license => license._id !== value._id)
            setData({
                ...data,
                driverLicense: updatedLicenses,
            })
        } else {
            setData({
                ...data,
                driverLicense: [...data.driverLicense, value],
            })
        }
    }

    const changePhoneNumber = (value, index) => {
        const updatedPhoneNumbers = [...data.phoneNumbers]

        if (value.trim() !== '') {
            updatedPhoneNumbers[index] = value
        } else {
            updatedPhoneNumbers[index] = undefined
        }

        const filteredPhoneNumbers = updatedPhoneNumbers.filter(number => number)

        setData({
            ...data,
            phoneNumbers: filteredPhoneNumbers
        })
    }

    const handleCreateDriver = () => {
        const newErrors = {};

        if (!uploadedImage) {
            newErrors.image = "Այս դաշտը պարտադիր է";
        }
        if (!data.fullName) {
            newErrors.fullName = "Այս դաշտը պարտադիր է";
        }
        if (!data.birthday) {
            newErrors.birthday = "Այս դաշտը պարտադիր է";
        }
        if (!data.registrationAddress) {
            newErrors.registrationAddress = "Այս դաշտը պարտադիր է";
        }
        if (!data.address) {
            newErrors.address = "Այս դաշտը պարտադիր է";
        }
        if (!data.passport) {
            newErrors.passport = "Այս դաշտը պարտադիր է";
        }
        if (!data.email) {
            newErrors.email = "Այս դաշտը պարտադիր է";
        }
        if (!data.phoneModel) {
            newErrors.phoneModel = "Այս դաշտը պարտադիր է";
        }
        if (!data.driverLicense.length) {
            newErrors.driverLicense = "Այս դաշտը պարտադիր է";
        }
        if (!data.phoneNumbers[0]) {
            newErrors.phoneNumber1 = "Այս դաշտը պարտադիր է";
        }
        if (!data.vehicles[0]) {
            newErrors.vehicles = "Այս դաշտը պարտադիր է";
        }

        if (Object.keys(newErrors).length === 0) {
            const formattedVehicles = data.vehicles.map(vehicle => vehicle._id);
            const formattedDriverLicenses = data.driverLicense.map(license => license._id);

            const formData = new FormData();
            formData.append('image', uploadedImage);
            formData.append('fullName', data.fullName);
            formData.append('birthday', data.birthday);
            formData.append('registrationAddress', data.registrationAddress);
            formData.append('address', data.address);
            formData.append('email', data.email);
            formData.append('passport', data.passport);
            formData.append('phoneModel', data.phoneModel);
            formData.append('driverLicense', JSON.stringify(formattedDriverLicenses));
            formData.append('phoneNumbers', JSON.stringify(data.phoneNumbers));
            formData.append('vehicles', JSON.stringify(formattedVehicles));

            dispatch(createDriver(formData))
        } else {
            setValidationErrors(newErrors)
        }
    };

    useEffect(() => {
        dispatch(getVehicleMarks())
    }, []);

    return (
        <div className={'fixed z-[9990] w-screen h-screen top-0 left-0 flex items-center justify-center mt-10'}>
            <div onClick={close}
                 className={'fixed bg-black bg-opacity-5 backdrop-blur w-full h-full top-0 left-0 z-[9990]'}></div>
            <div
                className={'grid grid-rows-[70px,auto] w-full max-w-[879px] max-h-[850px] overflow-hidden z-[9999] border border-black rounded-[10px] bg-white'}>
                <div className={'relative flex items-center justify-center py-[23px]'}>
                    <h2 className={'text-center'}>Նոր վարորդ</h2>
                    <button className={'absolute right-6 bg-black bg-opacity-5 rounded-full p-1.5'} onClick={close}>
                        <CloseIcon width={20} height={20}/>
                    </button>
                </div>
                <div className={'flex gap-[30px] mx-[30px] h-[calc(100vh-200px-70px)] overflow-scroll'}>
                    <div className={'flex flex-col gap-3'}>
                        <div onClick={() => imageUploadRef.current.click()}
                             className={`w-[200px] h-[200px] flex items-center justify-center cursor-pointer`}>
                            <input type={"file"} accept={'image/*'} className={'hidden'} id={"upload_image"}
                                   ref={imageUploadRef} onChange={handleImageUpload}/>
                            <div
                                className={`w-full h-full flex items-center justify-center ${!uploadedImage && 'border-[7px] border-[#FCC10F]'} rounded-full`}>
                                {uploadedImage ?
                                    <img
                                        src={URL.createObjectURL(uploadedImage)} alt="uploaded"
                                        className="max-w-[200px] w-full h-full object-cover rounded-full"/>
                                    :
                                    <CameraIcon color={'#FCC10F'} height={100} width={100}/>
                                }
                            </div>
                        </div>
                        {validationErrors?.image && <p className={'text-red-500'}>{validationErrors.image}</p>}
                    </div>
                    <div className={`flex flex-col w-[548px] gap-5`}>
                        <p className={'flex items-center gap-[15px] text-[#FF0707]'}>
                            <AsteriskIcon/>
                            <span>դաշտերը պարտադիր լրացման են</span>
                        </p>
                        <div className={'flex flex-col gap-2.5'}>
                            <p className={'flex gap-1'}>
                                <span>Անուն, ազգանուն, հայրանուն </span>
                                <AsteriskIcon/>
                            </p>
                            <input
                                type={'text'}
                                name={'fullName'}
                                value={data?.fullName}
                                onChange={handleChange}
                                onFocus={() => handleFocus('fullName')}
                                className={'w-full h-[30px] border border-black border-opacity-70 rounded-[3px] outline-none px-[30px]'}
                            />
                            {validationErrors.fullName && <p className={'text-red-500'}>{validationErrors.fullName}</p>}
                        </div>

                        <div className={'relative flex flex-col gap-2.5'}>
                            <p className={'flex gap-1'}>
                                <span>Ծննդյան օր, ամիս, տարի </span>
                                <AsteriskIcon/>
                            </p>
                            <div
                                onClick={() => handleFocus('birthday')}
                                className={'absolute w-full top-full border border-black py-[4px] rounded-[3px] mt-2.5 z-[9990]'}>
                                <Calendar value={data?.birthday} displayFormat={'DD.MM.YYYY'}
                                          returnFormat={'YYYY-MM-DD'} setDate={changeBirthday}
                                          optionClassName={'px-[30px]'}
                                          onChange={handleChange}/>
                                {validationErrors.birthday &&
                                    <p className={'absolute top-full text-red-500 pt-2.5'}>{validationErrors.birthday}</p>}
                            </div>
                        </div>

                        <div className={`flex flex-col gap-2.5 ${validationErrors.birthday ? 'mt-[65px]' : 'mt-[40px]'}`}>
                            <p className={'flex gap-1'}>
                                <span>Գրանցման հասցե </span>
                                <AsteriskIcon/>
                            </p>
                            <input
                                type={"text"}
                                name={"registrationAddress"}
                                value={data?.registrationAddress}
                                onChange={handleChange}
                                onFocus={() => handleFocus('registrationAddress')}
                                className={'w-full h-[30px] border border-black border-opacity-70 rounded-[3px] outline-none px-[30px]'}
                            />
                            {validationErrors.registrationAddress &&
                                <p className={'text-red-500'}>{validationErrors.registrationAddress}</p>}
                        </div>

                        <div className={'flex flex-col gap-2.5'}>
                            <p className={'flex gap-1'}>
                                <span>Բնակության հասցե </span>
                                <AsteriskIcon/>
                            </p>
                            <input
                                type={"text"}
                                name={"address"}
                                value={data?.address}
                                onChange={handleChange}
                                onFocus={() => handleFocus('address')}
                                className={'w-full h-[30px] border border-black border-opacity-70 rounded-[3px] outline-none px-[30px]'}
                            />
                            {validationErrors.address && <p className={'text-red-500'}>{validationErrors.address}</p>}
                        </div>

                        <div className={'flex flex-col gap-2.5 '}>
                            <p className={'flex gap-1'}>
                                <span>Անձնագրի տվյալներ </span>
                                <AsteriskIcon/>
                            </p>
                            <input
                                type={"text"}
                                name={"passport"}
                                value={data?.passport}
                                onChange={handleChange}
                                onFocus={() => handleFocus('passport')}
                                className={'w-full h-[30px] border border-black border-opacity-70 rounded-[3px] outline-none px-[30px]'}
                            />
                            {validationErrors.passport && <p className={'text-red-500'}>{validationErrors.passport}</p>}
                        </div>

                        <div className={'relative'}>
                            <label htmlFor={'phoneNumber'}
                                   className='flex items-center gap-1.5'><span>Հեռախոսահամար 1</span>
                                <AsteriskIcon/>
                            </label>
                            <div onClick={() => handleFocus('phoneNumber1')}
                                 className={'border border-black rounded-[3px] mt-2.5'}>
                                <PhoneInput
                                    value={data.phoneNumbers[0]}
                                    onChange={(value) => changePhoneNumber(value, 0)}
                                    name={'phoneNumber1'}
                                    className={'py-[3px]'}
                                />
                            </div>
                            {validationErrors.phoneNumber1 && <p className={'text-red-500 mt-2.5'}>{validationErrors.phoneNumber1}</p>}
                        </div>

                        <div className={'relative'}>
                            <label htmlFor={'phoneNumber'}
                                   className='flex items-center gap-1.5'><span>Հեռախոսահամար 2</span>
                            </label>
                            <div className={'border border-black rounded-[3px] mt-2.5'}>
                                <PhoneInput
                                    value={data.phoneNumbers[1]}
                                    onChange={(value) => changePhoneNumber(value, 1)}
                                    name={'phoneNumber2'}
                                    className={'py-[3px]'}
                                />
                            </div>
                        </div>

                        <div className={'flex flex-col gap-2.5'}>
                            <p className={'flex gap-1'}>
                                <span>Էլեկտրոնային փոստ </span>
                                <AsteriskIcon/>
                            </p>
                            <input
                                type={"email"}
                                name={'email'}
                                autoComplete={'off'}
                                value={data?.email}
                                onChange={handleChange}
                                onFocus={() => handleFocus('email')}
                                className={'w-full h-[30px] border border-black border-opacity-70 rounded-[3px] outline-none px-[30px]'}
                            />
                            {validationErrors.email && <p className={'text-red-500'}>{validationErrors.email}</p>}
                        </div>

                        <div className={'flex flex-col gap-2.5'}>
                            <p className={'flex gap-1'}>
                                <span>Վարորդական վկայական </span>
                                <AsteriskIcon/>
                            </p>
                            <DropdownInputCheckbox
                                data={driverLicenseData}
                                name={'driverLicense'}
                                onChange={handleAddDriverLicense}
                                previewClassName={'rounded-[3px] px-[30px]'}
                                optionClassName={'px-[30px]'}
                                onFocus={() => handleFocus('driverLicense')}
                                showSelected
                                type={'driverLicense'}
                                selected={data.driverLicense}
                            />
                            {validationErrors.driverLicense && <p className={'text-red-500'}>{validationErrors.driverLicense}</p>}
                        </div>

                        <div className={'flex flex-col gap-2.5'}>
                            <p className={'flex gap-1'}>
                                <span>SMART հեռախոսի մոդել </span>
                                <AsteriskIcon/>
                            </p>
                            <input
                                type={"text"}
                                name={"phoneModel"}
                                value={data?.phoneModel}
                                onChange={handleChange}
                                onFocus={() => handleFocus('phoneModel')}
                                className={'w-full h-[30px] border border-black border-opacity-70 rounded-[3px] outline-none px-[30px]'}
                            />
                            {validationErrors.phoneModel &&
                                <p className={'text-red-500'}>{validationErrors.phoneModel}</p>}
                        </div>

                        <div className={'flex flex-col gap-2.5'}>
                            <p className={'flex gap-1'}>
                                <span>Կցել մեքենա </span>
                                <AsteriskIcon/>
                            </p>
                            <DropdownInputCheckbox
                                data={vehicleMarks}
                                name={'vehicle1'}
                                onChange={handleSelectOption}
                                previewClassName={'rounded-[3px] py-[2px] px-[30px]'}
                                optionClassName={'px-[30px]'}
                                selected={data.vehicles[0] ? [data.vehicles[0]] : []}
                                onFocus={() => handleFocus('vehicles')}
                            />
                            {validationErrors.vehicles && <p className={'text-red-500'}>{validationErrors.vehicles}</p>}
                        </div>

                        <div className={'flex flex-col gap-2.5'}>
                            <p className={'flex gap-1'}>
                                <span>Կցել մեքենա </span>
                            </p>
                            <DropdownInputCheckbox
                                data={vehicleMarks}
                                name={'vehicle2'}
                                onChange={handleSelectOption}
                                previewClassName={'rounded-[3px] py-[2px] px-[30px]'}
                                optionClassName={'px-[30px]'}
                                selected={data.vehicles[1] ? [data.vehicles[1]] : []}
                            />
                        </div>

                        <div className={'flex items-center justify-end gap-[30px] pb-5'}>
                            <ButtonOutlined onClick={close} text={'Չեղարկել'} className={'w-[176px]'}/>
                            <ButtonFilled onClick={handleCreateDriver} text={'Ստեղծել'}
                                          className={'w-[176px] shadow-md'}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateDriverModal;
