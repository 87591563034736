import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {getForms} from "../../../store/actions/forms";
import {Container} from "../../../components/shared";
import FormItem from "../../../components/forms/FormItem";
import Pagination from "../../../components/shared/Pagination";
import PageSizeDropdown from "../../../components/shared/dropdowns/PageSizeDropdown";
import CreateNewFormButton from "../../../components/forms/CreateNewFormButton";
import {useFilters} from "../../../hooks/useFilters";

const initialFilters = {page: 1, size: 10}

const Forms = () => {
    const {forms, loading} = useSelector(state => state.forms)
    const [customPage, setCustomPage] = useState('')

    const {
        filters,
        changePage,
        handleChangeSize,
        setFilters,
        submitFilters
    } = useFilters(initialFilters, getForms, 'forms')

    const changeCustomPage = (e) => {
        const disallowedCharSet = '-+.'
        const value = e.target.value

        if (e.key === 'Enter') {
            setFilters({...filters, page: customPage <= forms?.pagesCount ? customPage : forms?.pagesCount})
        } else {
            const lastChat = value[value.length - 1]

            !disallowedCharSet.includes(lastChat) && setCustomPage(value)
        }
    }

    return (
        <>
            <Container>
                <h2 className={'text-center text-[50px] text-[#FCC10F] font-bold mt-[14px]'}>Ստեղծել հայտ</h2>

                <div className={'pt-[30px]'}>
                    <CreateNewFormButton/>

                    {loading && !forms ?
                        <p className={'text-center'}>Բեռնվում է․․․</p>
                        :
                        forms?.data.length > 0
                            ?
                            <div className={'py-[30px]'}>
                                <div className={'grid grid-cols-[1fr,120px] px-[30px] mb-[30px]'}>
                                    <div className={'grid grid-cols-2'}>
                                        <div className={'bold'}>Հայտի անուն</div>
                                        <div className={'bold'}>Հայտի կարգավիճակ</div>
                                    </div>
                                </div>
                                <div className={'grid grid-cols-1 gap-[30px]'}>
                                    {forms?.data.map(form => <FormItem key={form._id} {...form} />)}
                                </div>
                            </div>
                            :
                            <p className={'text-center'}>Հայտեր չկան</p>}
                </div>
                {forms && forms?.data.length > 0 && (
                    <div className={'flex items-center justify-center mt-[20px] mb-[100px]'}>
                        <Pagination count={forms?.pagesCount} onChange={changePage}
                                    page={parseInt(filters?.page || 1)}/>

                        <div className={'flex items-center justify-center gap-2.5'}>
                            <span>Էջ</span>
                            <input type="number" onKeyDown={changeCustomPage}
                                   onChange={changeCustomPage}
                                   value={customPage}
                                   max={forms?.pagesCount}
                                   className={'max-w-[53px] outline-none px-2 py-[3px] text-center border border-black rounded-[5px]'}/>
                        </div>
                        <div className={'ml-[30px]'}>
                            <PageSizeDropdown onChange={handleChangeSize} value={filters?.size}/>
                        </div>
                    </div>
                )}
            </Container>
        </>
    );
};

export default Forms;