import React, {useEffect, useRef, useState} from 'react';
import logo from "../../assets/images/logo.png";
import {Link, NavLink, useLocation} from "react-router-dom";
import {Container} from "../shared";
import {LogoutIcon, ProfileIcon, SettingsIcon} from "../../assets/icons";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../store/actions/auth";
import {useOutsideClick} from "../../hooks/useOutsideClick";

const MainHeader = () => {
    const {authData} = useSelector(state => state.auth)

    const [showMenu, setShowMenu] = useState(false)

    const userRole = authData?.role

    const dispatch = useDispatch()
    const ref = useRef(null)
    const location = useLocation()

    useOutsideClick(ref, () => setShowMenu(false))

    const handleLogout = () => {
        dispatch(logout())
    }

    useEffect(() => {
        setShowMenu(false)
    }, [location])

    return (
        <div className={'fixed top-0 right-0 left-0 z-[10000] py-1 w-full bg-white border-b border-b-[#FCC10F]'}>
            <Container>
                <div className={'flex justify-between items-center'}>
                    <div>
                        <Link to={'/'}>
                            <img src={logo} className={'w-10'} alt={'logo'}/>
                        </Link>
                    </div>

                    <div className={'flex items-center gap-[30px]'}>
                        <NavLink to={'/students?page=1&size=10&'}
                                                              className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:text-[#FCC10F] transition`}>Ուսանողներ</NavLink>
                        <NavLink to={'/forms?page=1&size=10&'}
                                 className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:text-[#FCC10F] transition`}>
                            Ստեղծել
                            հայտ</NavLink>
                        <NavLink to={'/requests?page=1&size=10&archive=false&'}
                                 className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:text-[#FCC10F] transition`}>Հայտադարան</NavLink>

                        {userRole !== 'volunteer' &&
                            <NavLink to={'/transport'}
                                     className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:text-[#FCC10F] transition`}>Տրանսպորտ</NavLink>}
                        {userRole !== 'volunteer' &&
                            <NavLink to={'/vehicles?page=1&size=9&'}
                                     className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:text-[#FCC10F] transition`}>Մեքենաներ</NavLink>}
                        <div className={'relative group'}>
                            <h2 className={'cursor-pointer'}>Այլ</h2>
                            <div className={'absolute top-full hidden group-hover:grid grid-rows-5 gap-2 p-3 bg-white border border-black border-opacity-70 rounded-[5px]'}>
                                {userRole !== 'volunteer' &&
                                    <NavLink to={'/groups?page=1&size=9&'}
                                             className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:text-[#FCC10F] transition`}>Խմբեր</NavLink>}
                                {userRole !== 'volunteer' &&
                                    <NavLink to={'/programs'}
                                             className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:text-[#FCC10F] transition`}>Ծրագրեր</NavLink>}
                                {userRole !== 'volunteer' &&
                                    <NavLink to={'/projects'}
                                             className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:text-[#FCC10F] transition`}>Նախագծեր</NavLink>}
                                {userRole !== 'volunteer' &&
                                    <NavLink to={'/communities?sortOption=alphabetical'}
                                             className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:text-[#FCC10F] transition`}>Համայնքներ</NavLink>}
                                {userRole !== 'volunteer' &&
                                    <NavLink to={'/intros?page=1&size=9&'}
                                             className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:text-[#FCC10F] transition`}>Ներ․ շաբաթ</NavLink>}
                                {userRole !== 'volunteer' &&
                                    <NavLink to={'/workshops?page=1&size=9&'}
                                             className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:text-[#FCC10F] transition`}>Աշխատարաններ</NavLink>}
                                {userRole !== 'volunteer' &&
                                    <NavLink to={'/connects?page=1&size=9&'}
                                             className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:text-[#FCC10F] transition`}>ՍՄԱՐԹամեջեր</NavLink>}
                                {userRole !== 'volunteer' &&
                                    <NavLink to={'/trips.js?page=1&size=9&'}
                                             className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:text-[#FCC10F] transition`}>ՍՄԱՐԹ այցեր</NavLink>}
                            </div>
                        </div>

                        <div className={`relative`}>
                            <img
                                src={authData.profileImage}
                                alt={'testingImage'}
                                className={`w-10 h-10 rounded-full ${showMenu ? 'pointer-events-none' : 'pointer-events-auto'} select-none`}
                                onClick={() => setShowMenu(!showMenu)}
                            />

                            {showMenu && (
                                <div ref={ref}
                                     className={`absolute z-50 w-[236px] max-h-[140px] top-full flex flex-col gap-2 bg-white right-0 mt-[10px] py-2 border border-black rounded-[5px]`}>
                                    <>
                                        <Link to={'/profile'}>
                                            <p
                                                className={'flex items-center gap-5 px-5 transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                                <ProfileIcon/>
                                                <span>Օգտաէջ</span>
                                            </p>
                                        </Link>
                                        <Link to={`/settings/members`}>
                                            <p
                                                className={'flex items-center gap-5 px-5 transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                                <SettingsIcon width={20} height={20} color={"#000"}/>
                                                <span>Կարգավորումներ</span>
                                            </p>
                                        </Link>
                                        <Link to={'/'}
                                              className={'flex items-center gap-[10px] px-5 transition hover:bg-gray-200 py-1 cursor-pointer'}
                                              onClick={handleLogout}>
                                            <LogoutIcon/>
                                            <span>Ելք</span>
                                        </Link>
                                    </>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default MainHeader;