import {CalendarArrow} from "../../../assets/icons";
import React from "react";

const months = [
    'Հունվար',
    'Փետրվար',
    'Մարտ',
    'Ապրիլ',
    'Մայիս',
    'Հունիս',
    'Հուլիս',
    'Օգոստոս',
    'Սեպտեմբեր',
    'Հոկտեմբեր',
    'Նոյեմբեր',
    'Դեկտեմբեր',
]
const shortMonths = [
    'Հնվ',
    'Փտվ',
    'Մրտ',
    'Ապր',
    'Մյս',
    'Հնս',
    'Հլս',
    'Օգս',
    'Սպտ',
    'Հկտ',
    'Նյմ',
    'Դկտ',
]

const SelectMonth = ({selectedMonth, handleMonthChange, className, showShortMonths, textSize}) => {
    const nextIndex = selectedMonth < 11 ? selectedMonth + 1 : null
    const prevIndex = selectedMonth > 0 ? selectedMonth - 1 : null

    const handleNextMonth = () => {
        handleMonthChange(nextIndex)
    }

    const handlePrevMonth = () => {
        handleMonthChange(prevIndex)
    }

    const handleSelectMonth = (e) => {
        handleMonthChange(parseInt(e.target.value))
    }

    return (
        <div className={`flex items-center ${className || 'gap-5'}`}>
            <button className={`flex items-center justify-center transition rounded-full ${prevIndex !== null && 'hover:bg-gray-200 cursor-pointer'}`} disabled={prevIndex === null} onClick={handlePrevMonth}>
                <CalendarArrow/>
            </button>
            <select value={selectedMonth} className={`outline-none ${textSize && 'text-[13px]'}`} onChange={handleSelectMonth}>
                {showShortMonths ?
                    shortMonths.map((month, index) => (
                        <option key={month} value={index}>
                            {month}
                        </option>
                    ))
                    :
                    months.map((month, index) => (
                    <option key={month} value={index}>
                        {month}
                    </option>
                ))}
            </select>
            <button className={`flex items-center justify-center transition rotate-180 rounded-full ${nextIndex !== null && 'hover:bg-gray-200 cursor-pointer'}`} disabled={nextIndex === null} onClick={handleNextMonth}>
                <CalendarArrow/>
            </button>
        </div>
    )
}

export default SelectMonth