import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {CheckedIcon, ClosedEyeIcon, GoogleIcon, KeyIcon, OpenedEyeIcon, UserIcon} from '../../assets/icons';
import {auth, provider} from '../../firebaseConfig'
import {googleSignIn, signIn} from "../../store/actions/auth";
import {signInWithPopup} from 'firebase/auth'

const initialFormData = {
    email: '',
    password: '',
    rememberMe: false,
    error: null
}

const LoginForm = () => {
    const {error} = useSelector(state => state.auth)
    const [formData, setFormData] = useState(initialFormData)
    const [showPassword, setShowPassword] = useState(false)
    const dispatch = useDispatch()

    const loginRef = useRef(null)
    const passwordRef = useRef(null)

    const toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleSubmit = () => {
        if (formData.email.includes('@gmail.com') || formData.email.includes('@coaf.org')) {
            dispatch(signIn(formData))
            setFormData(initialFormData)
        } else {
            setFormData({...formData, error: 'Մուտքանվան սխալ ձևաչափ, username@gmail.com'})
        }
    }

    const firebaseLogin = () => {
        signInWithPopup(auth, provider)
            .then((data) => {
                dispatch(googleSignIn(data._tokenResponse.idToken))
            })
            .catch((error) => {
                console.log("Caught error Popup closed");
            });
    }

    const toggleRememberMe = () => {
        setFormData({...formData, rememberMe: !formData.rememberMe})
    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    return (
        <div className={'max-w-[623px] h-fit pt-[30px] pb-[50px] px-[54px] border border-[#000] rounded-md'}>
            <div>
                <h2 className={'text-[50px] bold'}>Մուտք</h2>

                {!formData.error && <p className={'text-[#FB1313]'}>{error && 'Մուտքանունը կամ ծածկագիրը սխալ են'}</p>}
            </div>

            <div className={'my-[15px]'}>
                <div
                    onClick={() => loginRef.current.focus()}
                    className={'flex items-center gap-[30px] px-[30px] py-[9px] border border-[#000] rounded-[10px] cursor-pointer'}>
                    <UserIcon/>
                    <input
                        ref={loginRef}
                        type={"text"}
                        name={'email'}
                        className={'w-full outline-none border-none focus:ring-0'}
                        value={formData.email}
                        onChange={handleChange}
                        placeholder={'Մուտքանուն'}
                    />
                </div>

                {formData.error && <p className={'text-[#FB1313] mt-[15px]'}>{formData.error}</p>}

                <div
                    onClick={() => passwordRef.current.focus()}
                    className={'flex items-center gap-[30px] mt-[30px] px-[30px] py-[9px] border border-[#000] rounded-[10px] cursor-pointer'}>
                    <KeyIcon/>
                    <input
                        ref={passwordRef}
                        type={showPassword ? 'text' : 'password'}
                        name={'password'}
                        className={'w-full outline-none border-none focus:ring-0'}
                        value={formData.password}
                        onChange={handleChange}
                        placeholder={'Ծածկագիր'}
                        onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
                    />
                    <button onClick={toggleShowPassword}>
                        {showPassword ? <OpenedEyeIcon/> : <ClosedEyeIcon/>}
                    </button>
                </div>
            </div>

            <div className={'flex justify-between mt-[30px]'}>
                <div className={'select-none cursor-pointer'}>
                    <label htmlFor="remember-me" className={'flex items-center gap-[14px] cursor-pointer'}
                           onClick={toggleRememberMe}>
                        <div
                            className={'flex items-center justify-center w-5 h-5 border border-black rounded-[5px]'}>{formData.rememberMe &&
                            <CheckedIcon/>}</div>

                        <span>Հիշել ինձ</span>
                    </label>
                </div>
                <p className={'cursor-pointer'}>Մոռացե՞լ եք գաղտնաբառը</p>
            </div>
            <div className={'flex flex-col items-center gap-[25px]'}>
                <button
                    className={'flex items-center justify-center w-full h-[60px] text-[30px] text-white mt-[50px] bg-[#FCC10F] rounded-[10px]'}
                    onClick={handleSubmit}
                >
                    <span>Մուտք</span>
                </button>
                <div className={'w-[100px] border border-black border-opacity-30'}></div>
                <button onClick={firebaseLogin}
                        className={'flex items-center justify-center h-[60px] border border-black w-full  gap-[30px] rounded-[10px]'}>
                    <GoogleIcon/>
                    <span className={'text-[20px]'}>Մուտք Google համակարգով</span>
                </button>
            </div>
        </div>
    );
};

export default LoginForm;