import React from 'react';
import {CloudDownloadIcon, PdfFilledIcon, TrashIcon} from "../../../../assets/icons";
import moment from "moment";
import {Link} from "react-router-dom"

const FileItem = ({name, filePath, createdAt, _id, deleteFile}) => {
    const dateMoment = moment(createdAt).format('DD.MM.YYYY')

    const handleDelete = () => {
        deleteFile(_id)
    }

    return (
        <div>
            <div className={'grid grid-cols-6 px-[30px] pb-2.5'}>
                <p className={'bold'}>Ֆայլ</p>
                <p className={'bold'}>Անուն</p>
                <p className={'bold'}></p>
                <p className={'bold'}></p>
                <p className={'bold'}>Ավելացման ամսաթիվ</p>
            </div>
            <div className={'grid grid-cols-6 border border-black rounded-[3px] px-[30px] py-2.5'}>
                <div className={'flex items-center'}>
                    <PdfFilledIcon />
                </div>
                <div>
                    {name}
                </div>
                <div></div>
                <div></div>
                <div>
                    {dateMoment}
                </div>
                <div className={'flex justify-end items-center gap-[50px]'}>
                    <Link to={filePath} download={filePath} className={'flex items-center justify-center'}>
                        <CloudDownloadIcon />
                    </Link>

                    <button className={'flex items-center justify-center'} onClick={handleDelete}>
                        <TrashIcon />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FileItem;