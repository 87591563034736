import API from "./index";

export const createGroup = (payload) => API.post(`/groups`, payload)
export const changeGroupSettings = (groupId, payload) => API.patch(`/groups/${groupId}`, payload)
export const getGroups = (filters) => API.get(`/groups${filters}`)
export const getGroup = (id) => API.get(`/groups/${id}`)
export const changeGroupStatus = (id, status) => API.patch(`/groups/${id}/status`, {status})
export const deleteGroup = (id) => API.delete(`/groups/${id}`)

export const getGroupNameSuggestions = (searchPattern, filters = '') => API.get(`/groups/search?p=${searchPattern}&${filters}`)

export const getGroupNotes = (groupId) => API.get(`/groups/${groupId}/notes`)
export const addStudentToGroup = (groupId, studentId, joinDate) => API.patch(`/groups/${groupId}/add-student?student=${studentId}&joinDate=${joinDate}`)
export const removeStudentFromGroup = (groupId, studentId, note) => API.patch(`/groups/${groupId}/remove-student?student=${studentId}`, {note})
export const changeStudentStatus = (groupId, studentId, status, date, note) => API.patch(`/groups/${groupId}/change-student-status?student=${studentId}&status=${status}&date=${date}`, {note})
export const changeStudentJoinDate = (groupId, studentId, joinDate) => API.patch(`groups/${groupId}/change-student-join-date?student=${studentId}&date=${joinDate}`)
export const addStudentNote = (groupId, studentId, data) => API.post(`groups/${groupId}/add-student-note?student=${studentId}`, data)
export const changeLessonDate = (groupId, lessonDate, replaceDate, lessonIndex, levelIndex, note) => API.patch(`/groups/${groupId}/replace-lesson?lesson=${lessonDate}&replaceTo=${replaceDate}&lessonIndex=${lessonIndex}&levelIndex=${levelIndex}`, { note })
export const addManyStudents = (groupId, studentIds, joinDate) => API.patch(`/groups/${groupId}/add-many-students?students=${studentIds}&joinDate=${joinDate}`)

export const cancelLesson = (groupId, lessonDate, note, levelIndex, lessonIndex) => API.patch(`/groups/${groupId}/cancel-lesson?lesson=${lessonDate}&levelIndex=${levelIndex}&lessonIndex=${lessonIndex}`, {note})
export const restoreCanceledLesson = (groupId, lessonDate, levelIndex, lessonIndex) => API.patch(`/groups/${groupId}/restore-canceled-lesson?lesson=${lessonDate}&levelIndex=${levelIndex}&lessonIndex=${lessonIndex}`)
export const markLessonAttendance = (groupId, lesson, data, levelIndex, lessonIndex) => API.patch(`/groups/${groupId}/mark-attendance?lesson=${lesson}&levelIndex=${levelIndex}&lessonIndex=${lessonIndex}`, data)

export const createIntro = (data) => API.post(`/intros`, data)
export const getIntros = (queryParams) => API.get(`/intros${queryParams}`)
export const getIntro = (id) => API.get(`/intros/${id}`)

export const createWorkshop = (data) => API.post(`/workshops`, data)
export const getWorkshops = (queryParams) => API.get(`/workshops/${queryParams}`)
export const getWorkshop = (id) => API.get(`/workshops/${id}`)

export const createConnect = (data) => API.post('/connects', data)
export const getConnects = (queryParams) => API.get(`/connects/${queryParams}`)
export const getConnect = (id) => API.get(`/connects/${id}`)

export const createTrip = (data) => API.post('/trips', data)
export const getTrips = (queryParams) => API.get(`/trips/${queryParams}`)
export const getTrip = (id) => API.get(`/trips/${id}`)