import React, {useEffect, useRef, useState} from 'react';
import {AsteriskIcon, CameraIcon, CloseIcon, HandIcon, HeadIcon, HeartIcon} from "../../assets/icons";
import {ButtonFilled, ButtonOutlined} from "../shared/buttons";
import {useDispatch} from "react-redux";
import {createProgram} from "../../store/actions/programs";
import {changeStudentProfileImage} from "../../store/actions/students";

const icons = {
    head: <HeadIcon/>,
    heart: <HeartIcon/>,
    hand: <HandIcon/>
}

const CreateProgramModal = ({close}) => {
    const [essential, setEssential] = useState(null)
    const [programName, setProgramName] = useState('')
    const [programDescription, setProgramDescription] = useState('')
    const [uploadedImage, setUploadedImage] = useState(null)
    const [errors, setErrors] = useState({})

    const dispatch = useDispatch()

    const imageUploadRef = useRef(null)

    const handleSectionSelect = (index) => {
        setEssential(index === essential ? null : index)
    }

    const handleImageUpload = (e) => {
        const selectedImage = e.target.files[0]

        if (selectedImage) {
            setUploadedImage(selectedImage)
        }
    }

    const handleCreateProgram = async () => {
        const newErrors = {}
        if (!programName) newErrors.programName = "Այս դաշտը պարտադիր է"
        if (!programDescription) newErrors.programDescription = "Այս դաշտը պարտադիր է"
        if (!uploadedImage) newErrors.uploadedImage = "Այս դաշտը պարտադիր է"

        if (Object.keys(newErrors).length === 0) {

            try {
                const formData = new FormData()
                formData.append('image', uploadedImage)
                formData.append('name', programName)
                formData.append('description', programDescription)
                formData.append('essential', essential)

                dispatch(createProgram(formData))

                setProgramName('')
                setProgramDescription('')
                setUploadedImage(null)
                setEssential(null)

                close()
            } catch (error) {
                console.error('Error creating program:', error)
            }
        } else {
            setErrors(newErrors)
        }
    }

    useEffect(() => {
        setErrors(prevErrors => ({
            ...prevErrors,
            programName: programName ? "" : prevErrors?.programName,
            programDescription: programDescription ? "" : prevErrors?.programDescription,
            uploadedImage: uploadedImage ? "" : prevErrors?.uploadedImage
        }))
    }, [programName, programDescription, essential, uploadedImage])

    return (
        <div className={'fixed z-[9990] w-screen h-screen top-0 left-0 flex items-center justify-center'}>
            <div onClick={close}
                 className={'fixed bg-black bg-opacity-5 backdrop-blur w-full h-full top-0 left-0 z-[9990]'}></div>
            <div
                className={'absolute grid grid-rows-[auto,1fr,auto] w-full max-w-[879px] z-[9999] pt-[30px] pb-[50px] border border-black rounded-[10px] bg-white'}>
                <div className={'relative flex items-center justify-center'}>
                    <h2 className={'text-center'}>Նոր ծրագիր</h2>

                    <button className={'absolute right-6 bg-black bg-opacity-5 rounded-full p-1.5'}
                            onClick={close}>
                        <CloseIcon width={20} height={20}/>
                    </button>
                </div>

                <div className={'flex gap-[30px] px-[30px] mt-5'}>
                    <div className={'flex flex-col gap-3'}>
                        <div
                            onClick={() => imageUploadRef.current.click()}
                            className={`w-[200px] h-[200px] flex items-center justify-center ${uploadedImage ? 'border-0' : 'border-[7px] border-[#FCC10F]'} rounded-full cursor-pointer`}>
                            <input type={"file"} accept={'image/*'} className={'hidden'} id={"upload_image"}
                                   ref={imageUploadRef} onChange={handleImageUpload}/>
                            {uploadedImage ?
                                <img
                                    src={URL.createObjectURL(uploadedImage)} alt="uploaded"
                                    className="max-w-[200px] h-full"/>
                                :
                                <CameraIcon color={'#FCC10F'} height={100} width={100}/>
                            }
                        </div>
                        {errors?.uploadedImage && <p className={'text-red-500'}>{errors.uploadedImage}</p>}
                    </div>
                    <div className={`flex flex-col ${errors ? 'gap-3' : 'gap-5'} w-[548px]`}>
                        <p className={'flex items-center gap-[15px] text-[#FF0707]'}>
                            <AsteriskIcon/>
                            <span>դաշտերը պարտադիր լրացման են</span>
                        </p>

                        <div className={'flex flex-col gap-[10px]'}>
                            <p className={'flex gap-1'}>Ծրագրի անունը <AsteriskIcon/></p>
                            <input type={"text"}
                                   value={programName}
                                   onChange={(e) => setProgramName(e.target.value)}
                                   className={'w-full h-[30px] border border-black border-opacity-70 py-1 px-[30px] rounded-[3px] outline-none'}/>
                            {errors?.programName && <p className={'text-red-500'}>{errors.programName}</p>}
                        </div>

                        <div className={'flex flex-col justify-center gap-[10px]'}>
                            <p className={'flex gap-1'}>Կրթակարգի բաժին</p>
                            <div
                                className={'flex justify-center gap-[100px] border border-black border-opacity-70 py-[10px] rounded-[3px]'}>
                                {["head", "hand", "heart"].map((item, index) => (
                                    <div key={index}
                                         className={'w-10 flex flex-col items-center gap-5 cursor-pointer'}
                                         onClick={() => handleSectionSelect(item)}>
                                        <div>
                                            {icons[item]}
                                        </div>
                                        <div
                                            className={'w-5 h-5 flex items-center justify-center mx-[10px] border border-black rounded-full'}>
                                            {essential === item &&
                                                <div
                                                    className={'w-[10px] h-[10px] bg-[#FCC10F] rounded-full'}></div>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className={'flex flex-col gap-[10px]'}>
                            <p className={'flex gap-1'}>Ծրագրի նկարագրություն <AsteriskIcon/></p>
                            <textarea
                                value={programDescription}
                                onChange={(e) => setProgramDescription(e.target.value)}
                                className={'w-full h-[30px] border border-black border-opacity-70 px-[30px] rounded-[3px] outline-none resize-none'}></textarea>
                            {errors?.programDescription &&
                                <p className={'text-red-500'}>{errors.programDescription}</p>}
                        </div>

                        <div className={'flex items-center justify-end gap-[30px] mt-[10px]'}>
                            <ButtonOutlined onClick={close} text={'Չեղարկել'} className={'w-[176px]'}/>
                            <ButtonFilled onClick={handleCreateProgram} text={'Ստեղծել'}
                                          className={'w-[176px] shadow-md'}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateProgramModal;
