import React, { useEffect, useRef, useState } from 'react';
import { daysOfWeek } from "../../../../common/dropdownOptions";

const SelectWorkSchedule = ({ schedule, addToSchedule, removeScheduleDay, onFocus }) => {
    const [selectedDays, setSelectedDays] = useState([]);
    const [time, setTime] = useState({ startTime: '', endTime: '' });
    const containerRef = useRef(null);

    const handleSelectScheduleDay = (dayIndex) => {
        setSelectedDays(prevSelectedDays => {
            if (prevSelectedDays.includes(dayIndex)) {
                return prevSelectedDays.filter(day => day !== dayIndex);
            } else {
                return [...prevSelectedDays, dayIndex];
            }
        });

        const selectedDay = schedule.find(item => item.dayIndex === dayIndex);
        if (selectedDay) {
            setTime({
                startTime: selectedDay.startTime,
                endTime: selectedDay.endTime
            });
        } else {
            setTime({
                startTime: '',
                endTime: ''
            });
        }
    }

    const handleChangeTime = e => {
        const { name, value } = e.target;
        let timeValue = value.replace(/\D/g, '').slice(0, 4);
        let hours = value.slice(0, 2).replace(/\D/g, '');
        let minutes = value.slice(2).replace(/\D/g, '');

        if (timeValue.length >= 2 && hours <= 24 && minutes <= 59) {
            timeValue = `${timeValue.slice(0, 2)}:${timeValue.slice(2)}`;
        }

        if ((hours > 24 || (hours >= 3 && hours <= 9)) || (minutes > 59 || (minutes >= 6 && minutes <= 9))) {
            return;
        }

        setTime(prevTime => ({
            ...prevTime,
            [name]: timeValue
        }));
    }

    const handleSubmit = () => {
        if (time.startTime.length === 5 && time.endTime.length === 5) {
            const newScheduleDays = selectedDays.map(dayIndex => ({
                dayIndex,
                startTime: time.startTime,
                endTime: time.endTime
            }));

            newScheduleDays.forEach(day => removeScheduleDay(day.dayIndex));
            addToSchedule(newScheduleDays);

            setSelectedDays([]);
            setTime({ startTime: '', endTime: '' });
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        } else if (e.key === 'Backspace' && e.target.value.length === 3) {
            setTime(prevTime => ({
                ...prevTime,
                [e.target.name]: prevTime[e.target.name].slice(0, -1)
            }));
        }
    }

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (containerRef.current && !containerRef.current.contains(e.target)) {
                handleSubmit();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [containerRef, time, selectedDays]);

    return (
        <div ref={containerRef} className={'flex flex-col gap-[30px]'}>
            <div onClick={onFocus} className={'flex flex-row gap-10'}>
                <div className={'flex gap-[30px]'}>
                    {daysOfWeek.map((day, index) => {
                        const active = selectedDays.includes(index);
                        return (
                            <div
                                key={index}
                                onClick={() => handleSelectScheduleDay(index)}
                                className={`w-[45px] h-[45px] flex items-center justify-center border ${active ? 'border-[#FCC10F] bg-[#FCC10F] text-white' : 'border-black border-opacity-70'} rounded-full cursor-pointer select-none transition`}>
                                {day}
                            </div>
                        );
                    })}
                </div>
            </div>

            {selectedDays.length > 0 &&
                <div className={'grid grid-cols-2 max-w-[495px] max-h-[90px] gap-[45px]'}>
                    <div className={'flex flex-col gap-[30px]'}>
                        <h2>Սկիզբ</h2>
                        <input
                            type="text"
                            name="startTime"
                            className={`w-[225px] h-10 border border-black border-opacity-70 outline-none rounded-[5px] px-[50px] ${time.startTime && 'text-center'}`}
                            onChange={handleChangeTime}
                            onKeyDown={handleKeyDown}
                            value={time.startTime}
                        />
                    </div>
                    <div className={'flex flex-col gap-[30px]'}>
                        <h2>Ավարտ</h2>
                        <input
                            type="text"
                            name="endTime"
                            className={`w-[225px] h-10 border border-black border-opacity-70 outline-none rounded-[5px] px-[50px] ${time.endTime && 'text-center'}`}
                            onChange={handleChangeTime}
                            onKeyDown={handleKeyDown}
                            value={time.endTime}
                        />
                    </div>
                </div>
            }
        </div>
    );
};

export default SelectWorkSchedule;
