import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.js'
import './index.css'
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";

import reducers from "./store/store.js";
import {applyMiddleware, compose, createStore} from "redux";
import {thunk} from "redux-thunk";

const store = createStore(reducers, compose(applyMiddleware(thunk)))

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <App/>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>
)

