import React, {useEffect, useRef, useState} from 'react';
import {ArmenianFlag} from "../../assets/icons"

const charset = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
const acceptedCodes = ['77', '44', '99', '96', '33', '43', '41', '55', '95', '93', '98', '97', '91', '66', '11', '94']


const PhoneInput = ({answer, onChange, onTouch, name, className, onFocus}) => {
    const [code, setCode] = useState(answer?.slice(4, 6))
    const [number, setNumber] = useState(answer?.slice(6, 12))
    const [isInvalidCode, setIsInvalidCode] = useState(false)
    const [value, setValue] = useState(`+374${code}${number}`)

    const codeRef = useRef(null)
    const numbersRef = useRef(null)

    const handleFocus = () => {
        number?.length > 0 ? numbersRef.current.focus() : codeRef.current.focus()

        onTouch && onTouch()
    }

    const changeCode = (e) => {
        const lastChar = e.target.value[e.target.value.length - 1]

        const lastCharValid = lastChar ? charset.includes(lastChar) : true

        const remainingNumber = e.target.value.slice(2)

        e.target.value.length <= 2 && lastCharValid && setCode(e.target.value)

        if (remainingNumber.length > 0 && acceptedCodes.includes(code)) {
            setNumber(number + remainingNumber)
            numbersRef.current.focus()
        }
    }

    const changeNumber = (e) => {
        const lastChar = e.target.value[e.target.value.length - 1]
        const lastCharValid = lastChar ? charset.includes(lastChar) : true

        e.target.value.length <= 6 && lastCharValid && setNumber(e.target.value)
    }

    const handleKeyDown = e => {
        if (e.key === 'Backspace' && number === '') {
            e.preventDefault();
            codeRef.current.focus()
        }
    }

    useEffect(() => {
        if (code?.length === 2 && acceptedCodes.includes(code)) {
            numbersRef.current.focus()
            setIsInvalidCode(false)
        } else {
            setIsInvalidCode(true)
        }

        setValue(`+374${code}${number}`)
    }, [code]);

    useEffect(() => {
        setValue(`+374${code}${number}`)
    }, [number]);

    useEffect(() => {
        onChange(value, name)
    }, [value])

    return (
        <div className={`flex items-center px-7 ${className || 'py-[5px]'}`} onClick={handleFocus}>
            <div className={'w-5 h-5'}>
                <ArmenianFlag/>
            </div>
            <span className={'mx-[15px]'}>+374</span>
            (<input type={"text"} ref={codeRef} onChange={changeCode} value={code}
                    className={`w-6 outline-none text-center ${isInvalidCode && 'text-[#FF0707] caret-black'}`}
                    placeholder={'__'}/>)

            <input type={"text"} ref={numbersRef} onChange={changeNumber} value={number} onKeyDown={handleKeyDown}
                   className={'w-20 ml-1 outline-none'}
                   maxLength={6} placeholder={'______'}/>
        </div>
    );
};

export default PhoneInput
