import {
    CHANGE_GROUP_STATUS, CHANGE_PROGRAM_STATUS,
    CREATE_PROGRAM,
    CREATE_PROGRAM_PROJECT, DELETE_PROGRAM, DELETE_PROGRAM_PROJECT, GET_ALL_PROJECTS,
    GET_PROGRAM,
    GET_PROGRAM_GROUPS, GET_PROGRAM_PROJECTS,
    GET_PROGRAMS,
    SET_LOADING
} from '../constants/actionTypes'

const initialState = {
    programs: [],
    projects: [],
    program: null,
    groups: null,
    loading: false,
}

const programsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_PROGRAM:
            window.location = `/programs/${action.payload.programId}`
            return {
                ...state,
                programs: [action.payload.program, ...state.programs],
            }
        case CREATE_PROGRAM_PROJECT:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    data: [action.payload.project, ...state.projects.data]
                },
            }
        case GET_PROGRAMS:
            return {...state, programs: action.payload.programs, program: null}
        case GET_PROGRAM:
            return {...state, program: action.payload.program}
        case GET_PROGRAM_PROJECTS:
            return { ...state, projects: action.payload.projects }
        case GET_PROGRAM_GROUPS:
            return {...state, groups: action.payload.groups}
        case GET_ALL_PROJECTS:
            return {...state, projects: action.payload.projects }
        case CHANGE_PROGRAM_STATUS:
            const updatedProgram = action.payload;
            const updatedPrograms = state.programs.map(program => {
                if (program._id === updatedProgram._id) {
                    return { ...program, active: updatedProgram.active };
                }
                return program;
            });
            return {
                ...state,
                programs: updatedPrograms,
            }
        case DELETE_PROGRAM:
            return {
                ...state,
                programs: state.programs.filter(item => item._id !== action.payload.deletedProgramId)
            }
        case DELETE_PROGRAM_PROJECT:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    data: state.projects.data.filter(project => project._id !== action.payload.deletedProjectId)
                }
            }
        case SET_LOADING:
            return {...state, loading: !state.loading}
        default:
            return state;
    }
}
export default programsReducer