import React from 'react';
import {Container} from "../../../components/shared";
import TransportHeader from "../../../components/transport/TransportHeader";
import {
    DriversIcon,
    MapAndGPSIcon,
    RouteAmbushIcon,
    RoutesIcon,
    TimetableIcon,
    TransportStopIcon
} from "../../../assets/icons";
import TransportSectionCard from "../../../components/transport/TransportSectionCard";

const sections = [
    {
        icon: <RouteAmbushIcon />,
        name: 'Երթադարան',
        path: '/transport/trips.js'
    },
    {
        icon: <RoutesIcon />,
        name: 'Երթուղիներ',
        path: '/transport/routes?page=1&size=9&'

    },
    {
        icon: <TransportStopIcon />,
        name: 'Կանգառներ',
        path: '/transport/stops?page=1&size=9&'
    },
    {
        icon: <DriversIcon />,
        name: 'Վարորդներ',
        path: '/transport/drivers?page=1&size=9&'
    },
    {
        icon: <MapAndGPSIcon />,
        name: 'Քարտեզ և GPS',
        path: '/transport/maps-gps'
    },
    {
        icon: <TimetableIcon />,
        name: 'Ժամանակացույց',
        path: '/transport/timetable'
    },
]
const Transport = () => {
    return (
        <div>
            <Container>
                <TransportHeader/>

                <div className={'flex gap-8 mt-[50px]'}>
                    {sections.map(section => <TransportSectionCard {...section} key={section.name}/>)}
                </div>
            </Container>
        </div>
    );
};

export default Transport;