import React, {useMemo, useRef, useState} from 'react';
import {
    CakeIcon,
    CompletedIcon,
    DroppedIcon,
    GeoIcon,
    MenuIcon,
    ParticipatedIcon,
    PhoneIcon
} from "../../../../../assets/icons";
import {useOutsideClick} from "../../../../../hooks/useOutsideClick";
import {Link} from "react-router-dom";
import moment from "moment";
import {useSelector} from "react-redux";
import ButtonHoverText from "../../../../shared/buttons/ButtonHoverText";

const StudentItem = ({student, status, index, openEditStudentModal, lineHovered, hoverLine, handleRemoveStudent}) => {
    const {authData} = useSelector((state) => state.auth)
    const userHasPermissions = authData.role === 'manager' || authData.role === 'admin'

    const [showMenu, setShowMenu] = useState(false)
    const [showDetails, setShowDetails] = useState(false)

    const age = moment().diff(moment(student.birthday), 'years')

    const ref = useRef(null)

    useOutsideClick(ref, () => setShowMenu(false))

    const toggleShowDetails = () => {
        setShowDetails(!showDetails)
    }

    const handleChangeStudentStatus = (type) => {
        openEditStudentModal({student, type})
    }

    const StudentInfo = () => (
        <div
            className={`absolute mb-2 bottom-full w-full shadow-md custom-shadow transition z-50 rounded-[3px] bg-white px-5 py-[7px] group-hover:block hidden`}
        >
            <div className={'flex gap-5'}>
                <div className={'flex items-center'}>
                    <img src={student.profileImage} loading={'lazy'}
                         className={'w-[60px] aspect-square rounded-full'}
                         alt="profile-image"/>
                </div>
                <div className={'flex flex-col gap-[7px] text-sm'}>
                    <div className={'flex items-center gap-[15px]'}>
                        <GeoIcon/>
                        <span>{student.community?.name}</span>
                    </div>
                    <div className={'flex items-center gap-[15px]'}>
                        <PhoneIcon/>
                        <span>{student.phoneNumbers[0]}</span>
                    </div>
                    <div className={'flex items-center gap-[15px]'}>
                        <CakeIcon/>
                        <span>{age}</span>
                    </div>
                </div>
            </div>

            <div className={'relative flex items-center justify-center'}>
                <div className={'w-2 h-2 bg-white absolute top-[calc(100%+1.1px)] transform rotate-[45deg]'}></div>
            </div>
        </div>
    )

    return (
        <>
            <div
                className={`relative place-items-center grid grid-cols-[1fr,20px] h-10 pl-5 pr-2.5 border-t ${lineHovered === index && 'bg-[#f2f2f2]'}`}
                onMouseOver={() => hoverLine(index)}
                onMouseLeave={() => hoverLine(null)}
            >

                <div className={'relative group w-full h-fit flex items-center justify-start gap-[10px]'}>
                    <StudentInfo {...student} />

                    <div
                        title={student.fullName}
                        className={'flex items-center gap-2.5 max-w-[260px] line-clamp-1'}>
                        <span>{index + 1}</span>
                        <Link
                            to={`/students/${student._id}/?mode=view&subpage=profile`}
                            className={'truncate'}
                            onMouseOver={toggleShowDetails}
                            onMouseLeave={toggleShowDetails}
                        >{student.fullName}</Link>
                    </div>
                </div>

                <div className={'flex items-center gap-1'}>
                    <div>
                        {status === 'finished' &&
                            <ButtonHoverText icon={<CompletedIcon width={10} height={10}/>} text={'Ավարտած'}/>}
                        {status === 'participated' &&
                            <ButtonHoverText icon={<ParticipatedIcon width={10} height={10}/>} text={'Մասնակից'}/>}
                        {status === 'dropped' && <ButtonHoverText icon={<DroppedIcon width={10} height={10}/>} text={'Հեռացված'}/>}
                    </div>
                    {userHasPermissions && (
                        <div className={'flex items-center'}>
                            <button onClick={() => setShowMenu(!showMenu)}
                                    className={`${showMenu ? 'pointer-events-none' : 'pointer-events-auto'} w-[20px] h-[20px] `}>
                                <MenuIcon/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {showMenu && (
                <div ref={ref}
                     className={`absolute z-[9990] w-[185px] flex flex-col gap-4 bg-white right-0 py-5 border border-black rounded-[5px]`}>
                    <p onClick={() => handleRemoveStudent(student._id)}
                       className={'px-[30px] transition py-1 cursor-pointer'}>
                        Հեռացնել
                    </p>
                    <p onClick={() => handleChangeStudentStatus('participated')}
                       className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                        Մասնակից
                    </p>
                    <p onClick={() => handleChangeStudentStatus('completed')}
                       className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                        Ավարտած
                    </p>
                    {status !== 'dropped'
                        ?
                        <p onClick={() => handleChangeStudentStatus('dropped')}
                           className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                            Չավարտած
                        </p>
                        :
                        <p onClick={() => handleChangeStudentStatus('restored')}
                           className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                            Վերականգնել
                        </p>
                    }
                </div>
            )}
        </>
    );
};

export default StudentItem;
