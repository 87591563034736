import React, {useEffect, useRef, useState} from 'react';
import {ActionMenuIcon, AlarmIcon, GeoIcon, StatusIcon, TransportIcon} from "../../../../assets/icons";
import {Link, useNavigate} from "react-router-dom";
import {useOutsideClick} from "../../../../hooks/useOutsideClick";
import {ConfirmationModal} from "../../../shared";
import {changeProgramStatus} from "../../../../store/actions/programs";
import {useDispatch} from "react-redux";
import ButtonHoverText from "../../../shared/buttons/ButtonHoverText";
import {changeTransportStopStatus, selectTransportStop} from "../../../../store/actions/transport";

const StopCard = ({
                      _id,
                      active: initialActive,
                      community,
                      coordinates,
                      distance,
                      duration,
                      name,
                      place,
                      handleDeleteStop,
                      setStopToDelete,
                      stopToDelete,
                  }) => {
    const [showMenu, setShowMenu] = useState(false)
    const [statusToChange, setStatusToChange] = useState(false)
    const [active, setActive] = useState(initialActive)

    const ref = useRef()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useOutsideClick(ref, () => setShowMenu(false))

    const deleteStop = () => {
        setStopToDelete(_id)
    }

    const changeStatus = () => {
        setStatusToChange(true)
    }

    const handleChangeStatus = () => {
        dispatch(changeTransportStopStatus(_id, !active))
        setActive(!active)
        setShowMenu(false)
        setStatusToChange(false)
    }

    const handleSelectStop = () => {
        const selectedStopData = { _id,
            active: initialActive,
            community,
            coordinates,
            distance,
            duration,
            name,
            place,
        }

        dispatch(selectTransportStop(selectedStopData))
        navigate(`/transport/stops/edit`)
    }

    useEffect(() => {
        setActive(initialActive)
    }, [initialActive])

    useEffect(() => {
        document.body.style.overflow = stopToDelete || statusToChange ? 'hidden' : 'auto'
    }, [stopToDelete, statusToChange])

    return (
        <>
            {(stopToDelete) &&
                <ConfirmationModal
                    close={() => setStopToDelete(null)}
                    warningText={`Վստա՞հ եք, որ ցանկանում եք ջնջել այս կանգառը։`}
                    confirm={handleDeleteStop}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            {statusToChange &&
                <ConfirmationModal
                    close={() => setStatusToChange(false)}
                    warningText={`Վստա՞հ եք, որ ցանկանում եք ${active ? 'ապաակտիվացնել' : 'ակտիվացնել'} այս կանգառը։`}
                    confirm={handleChangeStatus}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            <div className={'relative w-[405px] h-[198px] custom-shadow rounded-[5px]'}>
                <div
                    className={`${showMenu ? 'block' : 'hidden'} absolute w-full h-full z-40 backdrop-blur-[1px]`}></div>


                <div className={'flex justify-center'}>
                    <div className={'relative group flex items-center gap-2.5 my-[30px] cursor-pointer'}>
                        <TransportIcon/>
                        <span className={'text-[#FCC10F]'}>{name}</span>

                        <div
                            className={'absolute top-1/2 mt-4 left-full hidden group-hover:block bg-white border custom-shadow p-2.5 rounded-[3px]'}>
                            <p>{coordinates?.long},</p>
                            <p>{coordinates?.lat}</p>
                        </div>
                    </div>
                    <button
                        className={`absolute z-50 right-5 top-5 ${showMenu ? 'pointer-events-none' : 'pointer-events-auto'}`}
                        onClick={() => setShowMenu(!showMenu)}
                    >
                        <ActionMenuIcon width={20} height={20}/>
                    </button>
                </div>

                {showMenu && (
                    <div ref={ref}
                         className={`absolute z-50 w-[203px] top-[60px] flex flex-col gap-4 bg-white right-5 py-6 border border-black rounded-[5px]`}>
                        <p
                            onClick={deleteStop}
                            className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                            Ջնջել
                        </p>
                        <p
                            onClick={handleSelectStop}
                            className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                            Խմբագրել
                        </p>
                        <p
                            onClick={changeStatus}
                            className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                            {active ? 'Ապաակտիվացնել' : 'Ակտիվացնել'}
                        </p>
                    </div>
                )}

                <div className={'flex justify-between px-[30px]'}>
                    <div className={'flex items-center gap-2.5'}>
                        <GeoIcon color={'#000'}/>
                        <span>{distance} կմ</span>
                    </div>

                    <div className={'flex items-center gap-2.5'}>
                        <span>{duration} րոպե</span>
                        <AlarmIcon color={'#000'}/>
                    </div>
                </div>

                <div className="relative w-full flex items-center justify-center my-2.5 px-[30px]">
                    <div className="relative w-full h-2.5 flex items-center">
                        <div className="absolute z-[9990] -left-1 h-4 w-4 bg-[#FCC10F] rounded-full"></div>
                        <div className="absolute top-0 bottom-0 left-0 right-0 bg-black rounded-[10px]"></div>
                        <div
                            className="absolute top-[1px] bottom-[1px] left-0 right-0 border-t-[1px] border-b-[1px] border-yellow-500"></div>
                        <div
                            className="absolute top-1/2 left-0 right-0 transform -translate-y-1/2 border-t-[2px] border-dotted border-white"></div>
                        <div className="absolute -right-1 w-4 h-4 bg-[#FCC10F] rounded-full"></div>
                    </div>
                </div>

                <div className={'flex justify-between px-[30px] text-[12px]'}>
                    <p>{community?.name}</p>
                    <p>SMART</p>
                </div>
                <div className={'flex justify-between px-[30px] mt-2.5 text-[12px]'}>
                    <p>{place}</p>
                    {active === true
                        ?
                        <ButtonHoverText icon={<StatusIcon color={'#20BA07'}/>} text={'Ակտիվ'}/>
                        :
                        <ButtonHoverText icon={<StatusIcon color={'#00000099'}/>} text={'Ապաակտիվ'}/>
                    }
                </div>
            </div>
        </>
    );
};

export default StopCard