import React from 'react';


const SmartBlock = ({selected, title, icon}) => {
    return (
        <div className={'pt-[15px] px-[25px] pb-[100px]'}>
            <h2 className={'flex items-center justify-center gap-5 text-[#FCC10F] text-center select-none'}>{icon} {title}</h2>

            {selected?.length > 0 && (
                <div className="flex flex-col gap-[30px] mt-30">
                    {selected.map(item => (
                        <div key={item._id} className={'cursor-pointer'}>{item.name}</div>
                    ))}
                </div>
            )}
        </div>
    );
};


export default SmartBlock;