import React, {useState, useEffect} from 'react';
import {
    CheckedIcon,
    ClothsSizeIcon,
    GPSIcon,
    HoodieIcon,
    LocationIcon,
    MailIcon,
    PhoneIcon,
    PinIcon,
    TabletIcon,
    TransportIcon,
    TuitionIcon,
    UsersIcon
} from "../../../assets/icons";
import {thinker, genius, wizard, whizzKid, creator} from "../../../assets/images/SMART Degrees/index";
import {DisplayPhoneNumber} from "../../shared";
import moment from "moment/moment";
import {DropdownInputCheckbox} from "../../shared/dropdowns";
import {clothingSizeData} from "../../../common/dropdownOptions";
import Calendar from "../../shared/calendar/Calendar";
import {useDispatch, useSelector} from "react-redux";
import {getRegions, getTransportRouteFilters, getTransportStopsNames} from "../../../store/actions/common";

const rewardIcons = [
    {icon: <PinIcon text={'Փիներ'}/>, id: 'pins'},
    {icon: <HoodieIcon text={'Հուդի'}/>, id: 'hoodie'},
    {icon: <LocationIcon text={'Այցեր'}/>, id: 'smartVisits'},
    {icon: <TabletIcon text={'Պլանշետ'}/>, id: 'notepad'},
    {icon: <TuitionIcon text={'Կրթաթոշակ'}/>, id: 'scholarship'}
];

const PersonalInfo = ({
                          changeCommunityField,
                          changePhoneNumber,
                          changeTransportStopName,
                          changeClothingSize,
                          community,
                          email,
                          transportStop,
                          gender,
                          phoneNumbers,
                          editMode,
                          birthday,
                          changeField,
                          clothingSize,
                          changeBirthdayDate,
                          ageGroup,
                          addAward,
                          awards,
                      }) => {
    const {communities, stopNames} = useSelector(state => state.common)

    const birthdayFormatted = moment(birthday).format('DD.MM.YYYY')
    const [selectedSection, setSelectedSection] = useState([])

    const [grayscale, setGrayscale] = useState({
        thinker: true,
        creator: true,
        whizzKid: true,
        wizard: true,
        genius: true
    })

    const dispatch = useDispatch()

    const handleSectionSelect = (index) => {
        const isSelected = selectedSection.includes(index)
        let newSelectedSection

        if (isSelected) {
            newSelectedSection = selectedSection.filter(i => i !== index)
        } else {
            newSelectedSection = [...selectedSection, index]
        }

        setSelectedSection(newSelectedSection)

        const selectedAwards = newSelectedSection.map(i => rewardIcons[i].id)

        addAward(selectedAwards)
    }

    useEffect(() => {
        const initialSelected = rewardIcons
            .map((icon, index) => awards.includes(icon.id) ? index : null)
            .filter(index => index !== null)
        setSelectedSection(initialSelected)
    }, [awards])

    useEffect(() => {
        dispatch(getTransportStopsNames())
    }, [])

    return (
        <div
            className={`flex justify-between h-[265px] px-[30px] pt-4 ${editMode ? 'pb-[29px]' : 'pb-[31px]'} border-b`}>
            <div className={`flex flex-col max-w-[356px] ${editMode ? 'gap-[10px]' : 'gap-[14.6px]'}`}>
                <p className={'text-[#FCC10F]'}>Անձնական տվյալներ</p>
                <div className={'flex gap-[30px]'}>
                    <div className={'flex items-center gap-[10px]'}>
                        <GPSIcon/>
                        {editMode
                            ?
                            <div className={'w-[180px]'}>
                                <DropdownInputCheckbox
                                    data={communities}
                                    name={'community'}
                                    placeholder={'Համայնք'}
                                    onChange={changeCommunityField}
                                    previewClassName={'rounded-[3px] h-[30px]'}
                                    hideCheckbox
                                    selected={[community]}
                                    disableInput
                                />
                            </div>
                            :
                            <span>{community?.name}</span>
                        }
                    </div>
                    <div>
                        <div className={'flex items-center gap-[10px]'}>
                            <TransportIcon/>
                            {editMode
                                ?
                                <div className={'w-[180px]'}>
                                    <DropdownInputCheckbox
                                        data={stopNames}
                                        name={'stops'}
                                        placeholder={'Կանգառ'}
                                        onChange={changeTransportStopName}
                                        previewClassName={'rounded-[3px] h-[30px]'}
                                        hideCheckbox
                                        selected={[transportStop]}
                                        disableInput
                                    />
                                </div>
                                :
                                <span>{transportStop?.name}</span>
                            }
                        </div>
                    </div>
                </div>
                <div className={'flex items-center gap-[10px]'}>
                    {editMode
                        ?
                        <div className={'flex gap-[30px]'}>
                            {phoneNumbers?.map((phoneNumber, i) => (
                                <div key={i} className={'flex items-center gap-[10px]'}>
                                    <PhoneIcon/>
                                    <input
                                        type="text"
                                        className={'w-[180px] h-[30px] border border-black border-opacity-70 rounded-[3px] py-2 px-[10px] outline-none'}
                                        value={phoneNumber || ''}
                                        onChange={e => changePhoneNumber(i, e.target.value)}
                                    />
                                </div>
                            ))}
                        </div>
                        :
                        <>
                            <PhoneIcon/>
                            <div>
                                {phoneNumbers?.map((n, i) => <DisplayPhoneNumber key={i} className={'mr-2.5'}
                                                                                 phoneNumber={n}/>)}
                            </div>
                        </>
                    }

                </div>
                <div className={'w-[420px] flex items-center gap-[10px]'}>
                    <MailIcon/>
                    {editMode
                        ?
                        <input
                            type="text"
                            className={'w-full h-[30px] border border-black border-opacity-70 rounded-[3px] px-[10px] outline-none'}
                            name={'email'}
                            value={email || ''}
                            onChange={changeField}
                        />
                        :
                        <span>{email}</span>
                    }
                </div>
                <div className={` ${editMode && 'w-[450px]'} flex items-center gap-[10px]`}>
                    <div className={'flex gap-[10px]'}>
                        <span className={'text-[#FCC10F]'}>Սեռ՝</span>
                        <span>{gender === 'female' ? 'Իգական' : 'Արական'}</span>
                    </div>
                    <div className={'relative flex items-center'}>
                        <span className={'w-[120px] text-[#FCC10F]'}>Ծննդյան օր՝</span>
                        {editMode
                            ?
                            <div className={'absolute left-full top-0 w-[240px] border border-black rounded-[3px]'}>
                                <Calendar
                                    displayFormat={'DD.MM.YYYY'}
                                    returnFormat={'YYYY-MM-DD'}
                                    className={'py-[1px]'}
                                    paddingClassName={'px-0'}
                                    optionClassName={'px-2'}
                                    gapClassName={'gap-1'}
                                    textSize={'text-[12px]'}
                                    setDate={changeBirthdayDate}
                                    showShortMonths={true}
                                    value={birthday}
                                />
                            </div>
                            :
                            <span>{birthdayFormatted}</span>
                        }
                    </div>
                </div>

                <div className={`${editMode ? 'gap-[14px]' : 'gap-[30px]'} flex items-center`}>
                    {editMode ?
                        <div className={'flex items-center w-[101px] gap-[10px] rounded-[3px]'}>
                            <DropdownInputCheckbox
                                selected={[clothingSizeData.find(item => item._id === clothingSize)]}
                                data={clothingSizeData}
                                onChange={changeClothingSize}
                                name={'clothingSize'}
                                icon={<ClothsSizeIcon/>}
                                hideCheckbox
                                optionClassName={'uppercase px-4'}
                                previewClassName={'uppercase py-[2px] rounded-[3px]'}
                                disableInput
                            />
                        </div>
                        :
                        <div className={'flex items-center gap-2.5 '}>
                            <ClothsSizeIcon/>
                            <p className={'uppercase'}>{clothingSize || 'Առկա չէ'}</p>
                        </div>
                    }

                    <div className={'flex items-center gap-[10px]'}>
                        <UsersIcon color={'#FCC10F'}/>
                        <span>{ageGroup}</span>
                    </div>
                </div>
            </div>

            <div className={`flex flex-col ${!editMode && 'gap-10'}`}>
                <div
                    className={`flex items-center justify-between w-[310px] ${editMode ? 'h-[110px]' : 'h-[70px]'} mt-[24px] px-5 rounded-[5px] shadow-md`}>
                    {rewardIcons.map((iconData, index) =>
                        <div key={index}
                             className={'flex flex-col items-center gap-5 cursor-pointer select-none'}
                             onClick={() => handleSectionSelect(index)}
                        >
                            {React.cloneElement(iconData.icon, {
                                color: selectedSection.includes(index) ? '#FCC10F' : undefined
                            })}
                            {editMode &&
                                <div
                                    className={`w-5 h-5 flex items-center justify-center mx-[10px] border rounded-[3px] ${selectedSection.includes(index) ? 'border-[#FCC10F]' : 'border-black'}`}>
                                    {selectedSection.includes(index) &&
                                        <CheckedIcon color={'#FCC10F'}/>
                                    }
                                </div>
                            }
                        </div>
                    )}
                </div>

                <div
                    className={'flex items-center justify-between w-[310px] h-[70px] px-5 rounded-[5px] shadow-md select-none'}>
                    <img
                        src={thinker}
                        alt={'thinker'}
                        className={grayscale.thinker ? 'w-[30px] h-[30px] grayscale select-none' : 'w-[30px] h-[30px]'}
                    />
                    <img
                        src={creator}
                        alt={'creator'}
                        className={grayscale.creator ? 'w-[30px] h-[30px] grayscale select-none' : 'w-[30px] h-[30px]'}
                    />
                    <img
                        src={whizzKid}
                        alt={'whizzKid'}
                        className={grayscale.whizzKid ? 'w-[30px] h-[30px] grayscale select-none' : 'w-[30px] h-[30px]'}
                    />
                    <img
                        src={wizard}
                        alt={'wizard'}
                        className={grayscale.wizard ? 'w-[30px] h-[30px] grayscale select-none' : 'w-[30px] h-[30px]'}
                    />
                    <img
                        src={genius}
                        alt={'thinker'}
                        className={grayscale.genius ? 'w-[30px] h-[30px] grayscale select-none' : 'w-[30px] h-[30px]'}
                    />
                </div>
            </div>
        </div>
    );
};

export default PersonalInfo;
