import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import {ActionMenuIcon, AgeGroupIcon, CalendarIcon, PeopleIcon, StatusIcon} from "../../assets/icons";
import {useOutsideClick} from "../../hooks/useOutsideClick";
import Calendar from "../shared/calendar/Calendar";
import ButtonHoverText from "../shared/buttons/ButtonHoverText";
import {daysOfWeek, groupStatusNames} from "../../common/dropdownOptions";
import {ConfirmationModal} from "../shared";
import {changeGroupStatus} from "../../store/actions/groups";
import {useDispatch} from "react-redux";

const statusColors = {
    active: '#20BA07',
    finished: '#FF0707',
    closed: 'rgba(0, 0, 0, 0.6)',
}
const IntroCard = ({
                       _id,
                       name,
                       startDate,
                       endDate,
                       studentsCount,
                       status,
                       ageGroup,
                       image,
                       scheduleCodes,
                       introToDelete,
                       setIntroToDelete,
                       handleDeleteIntro,
                   }) => {
    const [showMenu, setShowMenu] = useState(false)
    const [statusToChange, setStatusToChange] = useState(null)

    const ref = useRef(null)
    useOutsideClick(ref, () => setShowMenu(false))

    const dispatch = useDispatch()

    const textColorStyle = statusColors.hasOwnProperty(status) ? {color: statusColors[status]} : {}

    const schedule = scheduleCodes?.map((scheduleCode) => {
        const splitted = scheduleCode.split('_')
        const weekday = daysOfWeek[splitted[0]]

        return `${weekday}. ${splitted[1].split('-')[0]}`
    })

    const deleteIntro = () => {
        setIntroToDelete(_id)
    }

    const changeStatus = () => {
        setStatusToChange(true)
    }

    const handleChangeStatus = () => {
        let newStatus
        if (status === 'active' || status === 'finished') {
            newStatus = 'closed'
        } else if (status === 'closed') {
            newStatus = 'active'
        }

        dispatch(changeGroupStatus(_id, newStatus))
        setShowMenu(false)
        setStatusToChange(false)
    }

    useEffect(() => {
        document.body.style.overflow = introToDelete || statusToChange ? 'hidden' : 'auto'
    }, [introToDelete, statusToChange])

    return (
        <>
            {introToDelete &&
                <ConfirmationModal
                    close={() => setIntroToDelete(null)}
                    warningText={`Վստա՞հ եք, որ ցանկանում եք ջնջել այս խումբը։`}
                    confirm={handleDeleteIntro}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            {statusToChange &&
                <ConfirmationModal
                    close={() => setStatusToChange(false)}
                    warningText={`${status === 'active' || status === 'finished' ? 'Վստա՞հ եք, որ ցանկանում եք փակել այս խումբը։' : 'Վստա՞հ եք, որ ցանկանում եք ակտիվացնել այս խումբը։'}`}
                    confirm={handleChangeStatus}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            <div className={'relative w-[405px] h-[392px] py-[25px] px-[29px] rounded-[10px] custom-shadow'}>
                <div
                    className={`${showMenu ? 'block' : 'hidden'} absolute w-full h-full z-40 backdrop-blur-[1px]`}></div>
                <div>
                    <div className={'flex justify-center'}>
                        <Link to={`/intros/${_id}`}
                              className={'max-w-[250px] text-[#FCC10F] text-center'}>{name}</Link>

                        <button
                            className={`absolute z-50 right-5 ${showMenu ? 'pointer-events-none' : 'pointer-events-auto'}`}
                            onClick={() => setShowMenu(!showMenu)}
                        >
                            <ActionMenuIcon/>
                        </button>
                    </div>

                    {showMenu && (
                        <div ref={ref}
                             className={`absolute z-50 w-[163px] top-[60px] flex flex-col gap-4 bg-white right-5 py-6 border border-black rounded-[5px]`}>
                            {status === 'active'
                                &&
                                <>
                                    <p
                                        onClick={deleteIntro}
                                        className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                        Ջնջել
                                    </p>
                                    <p
                                        onClick={changeStatus}
                                        className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                        {status === 'active' ? 'Փակել' : 'Ակտիվացնել'}
                                    </p>
                                </>
                            }
                            {status === 'finished'
                                &&
                                <p
                                    onClick={changeStatus}
                                    className={'px-[30px] py-[3px] transition hover:bg-gray-200 cursor-pointer'}>
                                    Փակել
                                </p>
                            }
                            {status === 'closed'
                                &&
                                <p
                                    onClick={changeStatus}
                                    className={'px-[30px] py-[3px] transition hover:bg-gray-200 cursor-pointer'}>
                                    Ակտիվացնել
                                </p>
                            }
                        </div>
                    )}
                </div>
                <div className={'h-full max-h-[152px] flex justify-center mt-[30px] mb-[50px] px-11'}>
                    <Link to={`/intros/${_id}`}>
                        <img src={image} alt={'intro_image'} className={'max-w-[149px] h-full'}/>
                    </Link>
                </div>
                <div className={'flex flex-col gap-[30px]'}>
                    <div className={'flex items-center justify-between'}>
                        <div className={'w-[90px] max-h-[70px] flex items-start flex-col'}>
                            <div className={"relative group"}>
                                <p className={'flex items-center gap-2.5 cursor-pointer'}>
                                    <CalendarIcon/>
                                    <span>Գրաֆիկ</span>
                                </p>
                                <div
                                    className={"w-[228px] absolute z-10 hidden group-hover:grid grid-cols-3 gap-y-2.5 bg-white border shadow-md rounded-[3px] p-2 left-0 mt-2"}>
                                    {schedule?.map((item, index) => (
                                        <p key={index} className={'text-[12px]'}>{item}</p>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <p className={'relative group flex items-center gap-2.5'}>
                            <AgeGroupIcon/>
                            <span>{ageGroup === 'hybrid' ? 'Հիբրիդ' : ageGroup}</span>
                            <span
                                className={`absolute w-[180px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Տարիքային խումբ</span>
                        </p>
                    </div>
                    <div className={'flex items-center justify-between'}>
                        <p className={'relative group flex items-center gap-2.5'}>
                            <PeopleIcon/>
                            <span>{studentsCount}</span>
                            <span
                                className={`absolute w-[180px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Ուսանողների քանակ</span>
                        </p>
                        <p className={`flex items-center capitalize gap-[10px] select-none`}>
                            <span style={textColorStyle}>{groupStatusNames[status]}</span>
                            <StatusIcon color={statusColors[status]}/>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IntroCard;