import React, {useEffect, useRef, useState} from 'react';
import {ActionMenuIcon, AlarmIcon, RouteIcon, StatusIcon, StopIcon} from "../../../../assets/icons";
import ButtonHoverText from "../../../shared/buttons/ButtonHoverText";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useOutsideClick} from "../../../../hooks/useOutsideClick";
import {changeRouteStatus, selectRoute, selectTransportStop} from "../../../../store/actions/transport";
import {ConfirmationModal} from "../../../shared";
import stops from "../../../../pages/main/transport/subpages/stops/Stops";

const RouteCard = ({
                       _id,
                       active: initialActive,
                       routeStart,
                       routeEnd,
                       transportStops,
                       distance,
                       stopsCount,
                       durationToEndPoint,
                       name,
                       region,
                       routeDuration,
                       handleDeleteRoute,
                       setRouteToDelete,
                       routeToDelete,
                   }) => {
    const [showMenu, setShowMenu] = useState(false)
    const [statusToChange, setStatusToChange] = useState(false)
    const [active, setActive] = useState(initialActive)

    const ref = useRef()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useOutsideClick(ref, () => setShowMenu(false))

    const deleteRoute = () => {
        setRouteToDelete(_id)
    }

    const changeStatus = () => {
        setStatusToChange(true)
        setShowMenu(false)
    }

    const handleChangeStatus = () => {
        dispatch(changeRouteStatus(_id, !active))
        setActive(!active)
        setShowMenu(false)
        setStatusToChange(false)
    }

    const handleSelectRoute = () => {
        const selectedRouteData = {
            _id,
            active: initialActive,
            routeStart,
            routeEnd,
            transportStops,
            distance,
            stopsCount,
            durationToEndPoint,
            name,
            routeDuration,
            region
        }

        dispatch(selectRoute(selectedRouteData))
        navigate(`/transport/routes/edit`)
    }

    useEffect(() => {
        setActive(initialActive)
    }, [initialActive])

    useEffect(() => {
        document.body.style.overflow = routeToDelete || statusToChange ? 'hidden' : 'auto'
    }, [routeToDelete, statusToChange])

    return (
        <>
            {routeToDelete &&
                <ConfirmationModal
                    close={() => setRouteToDelete(null)}
                    warningText={`Վստա՞հ եք, որ ցանկանում եք ջնջել այս երթուղին։`}
                    confirm={handleDeleteRoute}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            {statusToChange &&
                <ConfirmationModal
                    close={() => setStatusToChange(false)}
                    warningText={`Վստա՞հ եք, որ ցանկանում եք ${active ? 'ապաակտիվացնել' : 'ակտիվացնել'} այս երթուղին։`}
                    confirm={handleChangeStatus}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            <div className={'relative w-[405px] h-[407px] custom-shadow rounded-[10px]'}>
                <div
                    className={`${showMenu ? 'block' : 'hidden'} absolute w-full h-full z-[9990] backdrop-blur-[1px]`}></div>

                <div className={'flex justify-center my-[30px]'}>
                    <div className={'flex items-center gap-2.5 cursor-pointer'}>
                    <span
                        title={name}
                        className={'max-w-[320px] text-[#FCC10F] line-clamp-1'}>
                        {name}
                    </span>
                        <div className={'pt-1'}>
                            {active === true
                                ?
                                <ButtonHoverText icon={<StatusIcon color={'#20BA07'}/>} text={'Ակտիվ'}/>
                                :
                                <ButtonHoverText icon={<StatusIcon color={'#00000099'}/>} text={'Ապաակտիվ'}/>
                            }
                        </div>
                    </div>
                    <button
                        className={`absolute z-50 right-5 pt-1 ${showMenu ? 'pointer-events-none' : 'pointer-events-auto'}`}
                        onClick={() => setShowMenu(!showMenu)}
                    >
                        <ActionMenuIcon width={20} height={20}/>
                    </button>
                </div>

                {showMenu && (
                    <div ref={ref}
                         className={`absolute z-[99999] w-[203px] top-[60px] flex flex-col gap-4 bg-white right-5 py-6 border border-black rounded-[5px]`}>
                        <p
                            onClick={handleSelectRoute}
                            className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                            Խմբագրել
                        </p>
                        <p
                            onClick={changeStatus}
                            className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                            {active ? 'Ապաակտիվացնել' : 'Ակտիվացնել'}
                        </p>
                        <p
                            onClick={deleteRoute}
                            className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                            Ջնջել
                        </p>
                    </div>
                )}

                <div className={'relative h-[245px] mx-[70px]'}>
                    <div className={`flex gap-10 ${!transportStops.length && 'items-center'}`}>
                        <div className={`w-full relative flex flex-col ${!stopsCount && 'gap-[60px]'} z-[9990]`}>
                            <div>
                                <p
                                    title={routeStart?.name}
                                    className={'max-w-[110px] hover:text-[#FCC10F] transition cursor-pointer break-all line-clamp-2'}>
                                    {routeStart?.name}
                                </p>
                            </div>
                            <div>
                                {transportStops.map((route, index) =>
                                    <div key={index}
                                         className={`${stopsCount === 4 && 'my-[10px]'} ${stopsCount === 1 && 'my-[80px]'} ${stopsCount === 3 && 'my-[15px]'} ${stopsCount === 2 && 'my-[25px]'}`}>
                                        <div className={`w-fit flex justify-between`}>
                                        <span
                                            title={route.transportStop?.name}
                                            className={`${stopsCount !== 4 && 'max-w-[110px] line-clamp-2'} hover:text-[#FCC10F] transition cursor-pointer break-all line-clamp-1`}>{route.transportStop?.name}</span>
                                            <div
                                                className={`absolute z-[990] left-[131%] h-4 w-4 bg-[#FCC10F] rounded-full`}></div>
                                        </div>
                                    </div>)}
                            </div>
                            <div className={``}>
                                <p
                                    title={routeEnd?.name}
                                    className={'max-w-[110px] hover:text-[#FCC10F] transition cursor-pointer break-all line-clamp-2'}>{routeEnd.name}</p>
                            </div>
                        </div>

                        <div className={'w-full ml-[35px]'}>
                            {transportStops.map((route, index) =>
                                <div key={index}
                                     className={`${stopsCount === 4 && 'my-[18px]'} ${stopsCount === 1 && 'my-[80px]'} ${stopsCount === 3 && 'my-[18px]'} ${stopsCount === 2 && 'my-[25px]'}`}>
                                    <div className={`flex justify-between`}>
                                    <span
                                        className={`${stopsCount === 1 && '-mt-8'}`}>{route?.durationToEndPoint} րոպե</span>
                                    </div>
                                </div>)}
                            <div>{durationToEndPoint} րոպե</div>
                        </div>
                    </div>

                    <div
                        className="absolute top-[40%] w-full flex items-center justify-center my-2.5 px-[30px] rotate-90">
                        <div className="relative w-full h-2.5 flex items-center">
                            <div className="absolute z-[9990] -left-1 h-4 w-4 bg-[#FCC10F] rounded-full"></div>
                            <div className="absolute top-0 bottom-0 left-0 right-0 bg-black rounded-[10px]"></div>
                            <div
                                className="absolute top-[1px] bottom-[1px] left-0 right-0 border-t-[1px] border-b-[1px] border-[#FCC10F]"></div>
                            <div
                                className="absolute top-1/2 left-0 right-0 transform -translate-y-1/2 border-t-[2px] border-dotted border-white"></div>
                            <div className="absolute -right-1 w-4 h-4 bg-[#FCC10F] rounded-full"></div>
                        </div>
                    </div>
                </div>

                <div className={'flex items-center justify-between px-[30px] mt-5'}>
                    <div className={'relative group flex items-center gap-5 cursor-pointer'}>
                        <RouteIcon width={20} height={20}/>
                        <span>{distance} կմ</span>
                        <span
                            className={`absolute w-[180px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Երթուղու երկարությունը</span>
                    </div>
                    <div className={'relative group flex items-center gap-5 cursor-pointer'}>
                        <AlarmIcon color={'#000'}/>
                        <span>{routeDuration} րոպե</span>
                        <span
                            className={`absolute w-[180px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Երթուղու տևողությունը</span>
                    </div>

                    <div className={'relative group flex items-center gap-5 cursor-pointer'}>
                        <StopIcon/>
                        <span>{stopsCount}</span>
                        <span
                            className={`absolute w-[180px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Կանգառների քանակը</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RouteCard;