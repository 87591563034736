import React from 'react';
import {
    ActiveStudentsIcon,
    AllStudentsCountIcon,
    CommunityStudentsIcon,
    DroppedStudentsIcon,
    GeoIcon,
    MenuIcon
} from "../../assets/icons";
import ButtonHoverText from "../shared/buttons/ButtonHoverText";

const CommunityItem = ({name, activeStudentsCount, allStudentsCount, graduatedStudentsCount, terminatedStudentsCount}) => {
    return (
        <div className={'max-w-[405px] h-[130px] mt-[30px] py-[30px] border shadow-md rounded-[5px]'}>
            <div className={'relative flex items-center justify-center'}>
                <p className={'flex items-center gap-2.5'}>
                    <GeoIcon/>
                    <span className={'text-[#FCC10F]'}>{name}</span>
                </p>
                <button className={'absolute right-0 mr-5'}>
                    <MenuIcon/>
                </button>
            </div>
            <div className={'flex items-center justify-between px-[30px] pt-[30px]'}>
                <p className={'relative group flex items-center gap-2.5'}>
                    <CommunityStudentsIcon/>
                    <span>{allStudentsCount}</span>
                    <span
                        className={`absolute w-[180px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Համայնքից ընդհանուր ուսանողների թիվ</span>
                </p>
                <p className={'relative group flex items-center gap-2.5'}>
                    <ActiveStudentsIcon color={'#20BA07'}/>
                    <span>{activeStudentsCount}</span>
                    <span
                        className={`absolute w-[180px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Ակտիվ ուսանողներ</span>
                </p>
                <p className={'relative group flex items-center gap-2.5'}>
                    <AllStudentsCountIcon color={'#FCC10F'}/>
                    <span>{graduatedStudentsCount}</span>
                    <span
                        className={`absolute w-[180px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Շրջանավարտներ</span>
                </p>
                <p className={'relative group flex items-center gap-2.5'}>
                    <DroppedStudentsIcon/>
                    <span>{terminatedStudentsCount}</span>
                    <span
                        className={`absolute w-[180px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Կրթակարգից դուրս մնացած մասնակիցներ</span>
                </p>
            </div>
        </div>
    );
};

export default CommunityItem;