import React from 'react';
import {Container} from "../shared";
import {DropdownInputCheckbox} from "../shared/dropdowns";
import {ageGroupData, groupStatusData} from "../../common/dropdownOptions";
import Calendar from "../shared/calendar/Calendar";
import SelectedFilters from "../shared/SelectedFilters";
import {ButtonFilled, ButtonOutlined} from "../shared/buttons";
import {useSelector} from "react-redux";

const EventsFilter = ({
                          filters,
                          setFilters,
                          changeSelectedFilters,
                          discardFilters,
                          submitFilters,
                          handleChange,
                          placeholder,
                          type
                      }) => {
    const {programs} = useSelector(state => state.common)

    const handleEnter = (e) => {
        e.key === 'Enter' && submitFilters()
    }

    const handleChangeFromDate = (date) => {
        setFilters({
            ...filters,
            fromDate: date
        })
    }

    const handleChangeToDate = (date) => {
        setFilters({
            ...filters,
            toDate: date
        })
    }
    return (
        <div className={'custom-shadow'}>
            <Container>
                <div className={'relative flex flex-wrap gap-[11px] mt-[17px]'}>
                    <div className={'relative'}>
                        <div>
                            <input type={"text"}
                                   className={'w-[247px] border border-black outline-none rounded-[5px] py-[7px] pl-[30px]'}
                                   name={'name'}
                                   value={filters?.name || ''}
                                   onChange={handleChange}
                                   placeholder={placeholder}
                                   onKeyDown={handleEnter}
                            />
                        </div>
                    </div>

                    <div className={'relative w-[247px]'}>
                        <DropdownInputCheckbox data={groupStatusData} name={'status'} showCheckbox={true}
                                               previewClassName={'rounded-[5px] py-[7px] px-[30px]'}
                                               optionClassName={'px-[30px]'}
                                               placeholder={'Կարգավիճակ'}
                                               onChange={changeSelectedFilters}
                                               selected={filters?.status}
                        />
                    </div>

                    <div className={'relative w-[247px]'}>
                        <DropdownInputCheckbox data={programs} name={'program'} showCheckbox={true}
                                               placeholder={'Ծրագրեր'}
                                               previewClassName={'rounded-[5px] py-[7px] px-[30px]'}
                                               optionClassName={'px-[30px]'}
                                               onChange={changeSelectedFilters}
                                               selected={filters?.program}
                        />
                    </div>

                    <div className={'relative z-[9990] w-[247px] h-10'}>
                        <div
                            className={'absolute w-full border border-black border-opacity-70 py-[6px] rounded-[5px] bg-white'}>
                            <Calendar displayFormat={'DD.MM.YYYY'}
                                      returnFormat={'YYYY-MM-DD'}
                                      placeholder={'Սկիզբ'}
                                      className={'py-[1px]'}
                                      paddingClassName={'px-0'}
                                      optionClassName={'px-2'}
                                      gapClassName={'gap-1'}
                                      textSize={'text-[12px]'}
                                      showShortMonths={true}
                                      setDate={handleChangeFromDate}
                                      value={filters?.fromDate}
                            />
                        </div>
                    </div>

                    <div className={'relative z-[9990] w-[247px] h-10'}>
                        <div
                            className={'absolute w-full border border-black border-opacity-70 py-[6px] rounded-[5px] bg-white'}>
                            <Calendar displayFormat={'DD.MM.YYYY'}
                                      returnFormat={'YYYY-MM-DD'}
                                      placeholder={'Ավարտ'}
                                      className={'py-[1px]'}
                                      paddingClassName={'px-0'}
                                      optionClassName={'px-2'}
                                      gapClassName={'gap-1'}
                                      textSize={'text-[12px]'}
                                      showShortMonths={true}
                                      setDate={handleChangeToDate}
                                      value={filters?.toDate}
                            />
                        </div>
                    </div>

                    {type === 'trips' &&
                        <input
                            type={"text"}
                            className={'w-[247px] border border-black outline-none rounded-[5px] py-[7px] pl-[30px] mt-5'}
                            name={'place'}
                            value={filters?.place || ''}
                            onChange={handleChange}
                            placeholder={'Վայր'}
                            onKeyDown={handleEnter}
                        />
                    }
                </div>

                <div className={'flex justify-between gap-3 pt-[30px] pb-[17px] mr-1'}>
                    <SelectedFilters selectedFilters={filters} removeItem={changeSelectedFilters}/>

                    <div className={'flex gap-3 items-center'}>
                        <ButtonOutlined
                            onClick={discardFilters}
                            text={'Չեղարկել'}
                        />
                        <ButtonFilled
                            onClick={() => submitFilters()}
                            text={'Կիրառել'}
                        />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default EventsFilter;