import React, {useState} from 'react'
import moment from "moment"
import StudentAttendanceIcon from "./StudentAttendanceIcon";
import LessonActionsMenu from "./LessonActionsMenu";

const shortMonths = [
    'Հնվ',
    'Փետ',
    'Մարտ',
    'Ապր',
    'Մայ',
    'Հուն',
    'Հուլ',
    'Օգս',
    'Սեպ',
    'Հոկ',
    'Նոյ',
    'Դեկ',
]

const PolygonMark = () => (
    <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.79484 0.545067L6.39715 6.77283L0.555007 0.632592L6.79484 0.545067Z" fill="#D0CFCF"/>
    </svg>
)

const LessonItem = ({
                        date,
                        lessonData,
                        levelsCount,
                        showYear,
                        isLast,
                        markAttendance,
                        openReplaceLessonModal,
                        openCancelLessonModal,
                        openRestoreCanceledLessonModal,
                        lineHovered,
                        hoverLine,
                        activeStudents,
                        showDropped,
                        lessonIndex,
                        levelIndex,
                        isParentScrolling
                    }) => {
    const studentsData = Object.entries(lessonData?.students)
    const lessonDate = moment(date, 'YYYY-MM-DD')

    const [openActionsMenu, setOpenActionsMenu] = useState(false)

    const isFutureLesson = moment().isBefore(moment(lessonDate))

    const toggleActionsMenu = () => {
        setOpenActionsMenu(true)
    }

    return (
        <div className={`relative min-w-10 w-full`}>
            <div className={`relative border-y`}>
                <div className={'absolute h-10 p-2.5'}>
                    {showYear && lessonDate.year()}
                </div>
                <div
                    onClick={toggleActionsMenu}
                    title={lessonData?.note}
                    className={`relative mt-10 h-10 flex flex-col items-center justify-center text-xs border-t cursor-default ${!isLast && 'border-r'} ${openActionsMenu ? 'pointer-events-none' : 'pointer-events-auto'}`}
                >
                    <p>{shortMonths[lessonDate.month()]}</p>
                    <p>{lessonDate.format('DD')}</p>

                    {(lessonData.replaced || lessonData.canceled || lessonData.note) &&
                        <span className={`absolute -top-[1px] -right-[1px]`}><PolygonMark/></span>}

                </div>
            </div>
            {openActionsMenu && (
                <LessonActionsMenu
                    close={() => setOpenActionsMenu(false)}
                    isFutureLesson={isFutureLesson}
                    levelsCount={levelsCount}
                    lessonCanceled={lessonData.canceled}
                    markAttendance={() => markAttendance([date, lessonData], levelIndex, lessonIndex)}
                    openReplaceLessonModal={() => openReplaceLessonModal(date, lessonIndex, levelIndex)}
                    openCancelLessonModal={() => openCancelLessonModal(date, lessonIndex, levelIndex)}
                    openRestoreCanceledLessonModal={() => openRestoreCanceledLessonModal(date, lessonIndex, levelIndex)}
                />
            )}
            <div className={`relative grid ${!isLast && 'border-r'}`}>
                {studentsData?.map((item, index) => {
                    const show = showDropped ? true : activeStudents.includes(item[0])

                    return show && (
                        <StudentAttendanceIcon
                            key={item[0]}
                            lessonDate={lessonDate}
                            data={item[1]}
                            canceled={lessonData.canceled}
                            index={index}
                            lineHovered={lineHovered === index}
                            hoverLine={hoverLine}
                            isParentScrolling={isParentScrolling}
                        />
                    )
                })}
            </div>
        </div>
    );
};

export default LessonItem;