import {
    CHANGE_STUDENT_PROFILE_IMAGE,
    CHANGE_STUDENT_STATUS,
    CREATE_NOTE,
    CREATE_STUDENT_FILE,
    DELETE_NOTE,
    DELETE_STUDENT_FILE,
    EDIT_NOTE,
    EDIT_STUDENT,
    GET_NOTES,
    GET_STUDENT,
    GET_STUDENT_FILES,
    GET_STUDENTS, GET_STUDENTS_FILTERS,
    SELECT_STUDENTS,
    SET_LOADING
} from '../constants/actionTypes'

const initialState = {
    students: null,
    student: null,
    notes: null,
    files: null,
    studentFilters: null,
    selected: [],
    loading: false,
}

const studentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_STUDENTS:
            return { ...state, students: action.payload, selected: [], files: null, notes: null }
        case EDIT_STUDENT:
            return { ...state, student: action.payload.student }
        case GET_STUDENT:
            return { ...state, student: action.payload.student}
        case GET_NOTES:
            return { ...state, notes: action.payload.notes}
        case GET_STUDENTS_FILTERS:
            return { ...state, studentFilters: action.payload.filters}
        case GET_STUDENT_FILES:
            return { ...state, files: action.payload.files}
        case DELETE_STUDENT_FILE:
            return { ...state, files: state.files.filter(file => file._id !== action.payload.id) }
        case CREATE_STUDENT_FILE:
            return {...state, files: [action.payload.file, ...state.files]}
        case SET_LOADING:
            return { ...state, loading: !state.loading }
        case SELECT_STUDENTS:
            const isAll = !action.payload.id
            let selected = state.selected

            if(isAll) {
                if(selected.length !== state.students.data.length) {
                    selected = state.students.data
                } else {
                    selected = []
                }
            } else {
                const selectedStudent = state.students.data.find(item => item._id === action.payload.id)

                if(selected.some(item => item?._id === action.payload.id)) {
                    selected = selected.filter(item => item._id !== action.payload.id)
                } else {
                    selected = [...selected, selectedStudent]
                }
            }

            return {...state, selected}
        case CHANGE_STUDENT_STATUS:
            const {id, globalStatus} = action.payload

            const updatedStudents = state.students.data.map(student => {
                if (student._id === id) {
                    student.globalStatus = globalStatus
                }
                return student
            })

            return {
                ...state,
                students: {...state.students, data: updatedStudents},
            }
        case CHANGE_STUDENT_PROFILE_IMAGE:
            const {profileImage} = action.payload

            return {...state, student: {...state.student, profileImage}}
        case CREATE_NOTE:
            const {note} = action.payload

            return {...state, notes: {...state.notes, data: [note, ...state.notes.data]}}
        case EDIT_NOTE:
            const {updatedNote} = action.payload

            const updatedStudentNotes = state.notes.data.map(note => {
                if (note._id === updatedNote._id) {
                    return updatedNote
                }
                return note
            })

            return {
                ...state,
                notes: {...state.notes, data: updatedStudentNotes}
            }
        case DELETE_NOTE:
            return {
                ...state,
                notes: {
                    ...state.notes,
                    data: state.notes.data.filter(item => item._id !== action.payload.id)
                }}
        default:
            return state;
    }
}
export default studentsReducer