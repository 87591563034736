import React, {useRef} from 'react';
import {useOutsideClick} from "../../../../hooks/useOutsideClick";
import {useSelector} from "react-redux";

const LessonActionsMenu = ({
                               isFutureLesson,
                               markAttendance,
                               openReplaceLessonModal,
                               openCancelLessonModal,
                               lessonCanceled,
                               openRestoreCanceledLessonModal,
                               close
                           }) => {
    const {authData} = useSelector((state) => state.auth)
    const userHasPermissions = authData.role === 'manager' || authData.role === 'admin'

    const ref = useRef(null)

    useOutsideClick(ref, close)

    if ((lessonCanceled && !userHasPermissions) || (isFutureLesson && !userHasPermissions)) return

    return (
        <div className={``}>
            <div ref={ref} style={{background: '#fff'}}
                 className={`absolute w-[175px] py-[5px] shadow-md z-[9980] pointer-events-auto hover:bg-[#000] bg-opacity-5 left-full -translate-y-1/2 rounded-[3px] text-base`}>
                <ul>
                    {lessonCanceled ?
                        userHasPermissions &&
                        <>
                            <li className={'pl-2.5 py-[5px] cursor-pointer hover:bg-black hover:bg-opacity-5'}
                                onClick={openRestoreCanceledLessonModal}>Վերականգնել
                            </li>
                        </>
                        :
                        <>
                            <li className={'pl-2.5 py-[5px] cursor-pointer hover:bg-black hover:bg-opacity-5'}
                                onClick={markAttendance}>Ներկայություն
                            </li>
                            {userHasPermissions &&
                                <>
                                    <li className={'pl-2.5 py-[5px] cursor-pointer hover:bg-black hover:bg-opacity-5'}
                                        onClick={openReplaceLessonModal}>Տեղափոխել դասը
                                    </li>
                                    <li className={'pl-2.5 py-[5px] cursor-pointer hover:bg-black hover:bg-opacity-5'}
                                        onClick={openCancelLessonModal}>Չեղարկել դասը
                                    </li>
                                </>
                            }
                        </>
                    }
                </ul>
            </div>
        </div>
    )
}

export default LessonActionsMenu