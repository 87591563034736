import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getGroupNotes} from "../../../../../store/actions/groups";
import {useParams} from "react-router-dom";
import NoteItem from "../../../../../components/students/NoteItem";
import CreateNoteModal from "../../../../../components/students/modals/CreateNoteModal"
import {deleteStudentNote} from "../../../../../store/actions/students";
import {ConfirmationModal} from "../../../../../components/shared";
import {TrashIcon} from "../../../../../assets/icons";

const GroupNotes = () => {
    const {notes} = useSelector(state => state.groups)

    const [editNote, setEditNote] = useState(null)
    const [createNote, setCreateNote] = useState(false)
    const [deleteNoteModal, setDeleteNoteModal] = useState(null)

    const {id} = useParams()
    const dispatch = useDispatch()

    const handleEditNote = (note) => {
        setEditNote(note)
    }

    const handleDeleteNote = () => {
        dispatch(deleteStudentNote(deleteNoteModal))
        setDeleteNoteModal(null)
    }

    useEffect(() => {
        dispatch(getGroupNotes(id))
    }, []);

    return (
        <>
            {editNote && <CreateNoteModal
                editNote={editNote}
                close={() => setEditNote(null)}
                groupId={id}
                open={editNote}
            />}

            {deleteNoteModal && <ConfirmationModal
                close={() => setDeleteNoteModal(null)}
                cancelText={'Չեղարկել'}
                confirmText={'Ջնջել'}
                confirm={handleDeleteNote}
                icon={<TrashIcon />}
                warningText={'Վստ՞հ եք, որ ցանկանում եք ջնջել այս նոթագրությունը։'}
            />}

            <div>
                {notes?.data.map(note => <NoteItem
                    key={note._id}
                    note={note}
                    showStudent
                    handleEditNote={handleEditNote}
                    handleDeleteNote={(id) => setDeleteNoteModal(id)}
                    setDeleteNoteModal={id => setDeleteNoteModal(id)}
                />)}
            </div>
        </>
    );
};

export default GroupNotes;