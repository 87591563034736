import React, {useEffect} from 'react';
import {Container} from "../../../components/shared";
import ProjectsFilter from "../../../components/programs/program/projects/ProjectsFilter";
import {useDispatch, useSelector} from "react-redux";
import {getAllProjects} from "../../../store/actions/programs";
import ProjectItem from "../../../components/programs/program/projects/ProjectItem";
import {useFilters} from "../../../hooks/useFilters";
import {getMembers} from "../../../store/actions/profile";
import MemberItem from "../../../components/settings/members/MemberItem";
import Pagination from "../../../components/shared/Pagination";
import {getGroups} from "../../../store/actions/groups";

const initialFilters = {page: 1}

const Projects = () => {
    const {projects, loading} = useSelector(state => state.programs)

    const {
        filters,
        handleChange,
        changeSelectedFilters,
        discardFilters,
        changePage,
        submitFilters,
        setFilters
    } = useFilters(initialFilters, getAllProjects, 'projects')

    return (
        <div>
            <h2 className={'text-[50px] text-[#FCC10F] font-bold text-center my-[30px]'}>Նախագծեր</h2>
            <ProjectsFilter
                filters={filters}
                setFilters={setFilters}
                discardFilters={discardFilters}
                submitFilters={submitFilters}
                handleChange={handleChange}
                changeSelectedFilters={changeSelectedFilters}
            />

            <Container>
                {
                    loading
                        ?
                        <p className={'text-center mt-[30px]'}>Բեռնվում է...</p>
                        :
                        projects?.data?.length > 0
                            ?
                            <>
                                <div>
                                    {projects?.data?.map((project, index) => <ProjectItem key={index} {...project} />)}
                                </div>
                                <Pagination count={projects?.pagesCount} page={filters?.page || 1} onChange={changePage}/>
                            </>
                            :
                            <p className={'text-center mt-[30px]'}>Նախագծեր չկան</p>}
            </Container>
        </div>
    );
};

export default Projects;