import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {submitForm} from "../../../store/actions/forms";
import Answer from "./widgets/Answer";
import {AsteriskIcon} from "../../../assets/icons";
import {checkFormValidity} from "../../../utils/forms/checkFormValidity";
import {checkMissingMandatory} from "../../../utils/forms/checkMissingMandatory";
import HoverInfo from "../../shared/HoverInfo";

const FormView = ({form, disableSubmit}) => {
    const [data, setData] = useState(form)
    const [isFormValid, setIsFormValid] = useState(false)
    const dispatch = useDispatch()

    const handleChange = (obj) => {
        setData({
            ...data, body: data?.body.map(field => {
                if (field.id === obj.id) {
                    return obj
                }

                return field
            })
        })
    }

    const handleSubmitForm = () => {
        if (isFormValid) {
            const payload = {}

            data?.body.forEach(item => {
                if (item.category === 'phoneNumber') {
                    if (payload['phoneNumbers']) {
                        payload['phoneNumbers'].push(item.answer)
                    } else {
                        payload['phoneNumbers'] = [item.answer]
                    }
                } else if (item.category === 'parentFullName') {
                    if (payload['parentsFullNames']) {
                        payload['parentsFullNames'].push(item.answer)
                    } else {
                        payload['parentsFullNames'] = [item.answer]
                    }
                } else {
                    payload[item.category] = item.answer
                }
            })
            dispatch(submitForm(form._id, payload))
        } else {
            setData({
                ...data, body: data?.body.map(field => {
                    field.touched = true
                    return field
                })
            })
        }

    }

    useEffect(() => {
        form && setData(form)
    }, [form])

    useEffect(() => {
        if (data) {
            const isValid = checkFormValidity(data)

            setIsFormValid(isValid)
        }
    }, [data])

    return (
        <div>
            <div className='flex flex-col'>
                {data?.body.map(field => {
                        const invalidMandatory = checkMissingMandatory(field)

                        return field.type !== 'successMessage' && (
                            <div className={'mb-[6px]'} key={field.id}>
                                <div className='flex font-medium text-4 mb-[10px]'>
                                    <span className={'mr-1 break-all'}>{field.name}</span>
                                    {field.mandatory && <div className={'w-2.5 h-2.5'}><AsteriskIcon/></div>}
                                    {field.info && <HoverInfo info={field.info}/>}
                                </div>
                                <div key={field.id}
                                     className={`overflow-hidden px-1 border rounded-[5px] ${invalidMandatory ? 'border-[#FF0707]' : 'border-black'}`}>
                                    <Answer {...field} handleStateChange={handleChange}/>
                                </div>
                                <p className={`text-[#FF5151] transition ${invalidMandatory ? 'opacity-100' : 'opacity-0'}`}>
                                    Պարտադիր դաշտ է</p>
                            </div>
                        )
                    }
                )}

                <button disabled={disableSubmit} onClick={handleSubmitForm}
                        className={`md:w-[469px] md:h-[65px] md:p-0 py-4 px-8 rounded-md bg-[#FCC10F] ${disableSubmit ? 'opacity-70' : 'opacity-100'} md:text-[30px] text-white font-bold shadow-md mt-[26px] mb-[100px]`}>Ուղարկել
                </button>
            </div>
        </div>
    )
}

export default FormView;