import React from 'react';
import Level from "./Level";
import TotalAttendance from "./TotalAttendance";

const Levels = ({
                    levels,
                    total,
                    markAttendance,
                    openReplaceLessonModal,
                    openCancelLessonModal,
                    openRestoreCanceledLessonModal,
                    lineHovered,
                    hoverLine,
                    activeStudents,
                    showDropped,
                    isParentScrolling
                }) => {
    return (
        <div className={'w-full pb-2 flex-grow'} id={'group-levels'}>
            <div className={'flex'}>
                {levels?.map((level, i) => (
                    <Level
                        key={i}
                        level={level}
                        index={i}
                        markAttendance={markAttendance}
                        openReplaceLessonModal={openReplaceLessonModal}
                        openCancelLessonModal={openCancelLessonModal}
                        openRestoreCanceledLessonModal={openRestoreCanceledLessonModal}
                        lineHovered={lineHovered}
                        hoverLine={hoverLine}
                        activeStudents={activeStudents}
                        showDropped={showDropped}
                        levelsCount={levels.length}
                        isParentScrolling={isParentScrolling}
                    />
                ))}
                <div className={'border-l bg-[#FFF4EA]'}>
                    <div className={'h-[82px] text-[13px] flex flex-col items-center gap-2.5 px-1 pt-5 border-y'}>
                        <p>Ամփոփում</p>
                    </div>
                    <TotalAttendance
                        data={total}
                        lineHovered={lineHovered}
                        hoverLine={hoverLine}
                        activeStudents={activeStudents}
                        showDropped={showDropped}
                    />
                </div>
            </div>
        </div>
    )
}

export default Levels