import {
    ADD_MANY_STUDENTS,
    ADD_STUDENT_NOTE,
    ADD_STUDENT_TO_GROUP,
    CANCEL_LESSON,
    CHANGE_GROUP_STATUS,
    CHANGE_GROUP_STUDENT_STATUS,
    CHANGE_LESSON_DATE,
    CHANGE_STUDENT_JOIN_DATE, CREATE_CONNECT,
    CREATE_GROUP,
    CREATE_INTRO, CREATE_TRIP, CREATE_WORKSHOP,
    DELETE_GROUP,
    DELETE_GROUP_NOTE, GET_CONNECT, GET_CONNECTS,
    GET_GROUP,
    GET_GROUP_NOTES,
    GET_GROUPS,
    GET_INTRO,
    GET_INTROS, GET_TRIP, GET_TRIPS, GET_WORKSHOP,
    GET_WORKSHOPS,
    MARK_LESSON_ATTENDANCE,
    REMOVE_STUDENT_FROM_GROUP,
    RESTORE_CANCELED_LESSON,
    SET_LOADING
} from "../constants/actionTypes";
import * as groupsApi from "../../api/groups"


export const createGroup = (payload, navigate) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.createGroup(payload)

        navigate(`/groups/${data.groupId}/attendance`)

        dispatch({type: CREATE_GROUP, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const createIntro = (payload, navigate) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.createIntro(payload)

        navigate(`/intros/${data.groupId}`)

        dispatch({type: CREATE_INTRO, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const createWorkshop = (payload, navigate) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.createWorkshop(payload)

        navigate(`/workshops/${data.groupId}`)

        dispatch({type: CREATE_WORKSHOP, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const createConnect = (payload, navigate) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.createConnect(payload)

        navigate(`/connects/${data.groupId}`)

        dispatch({type: CREATE_CONNECT, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const createTrip = (payload, navigate) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.createTrip(payload)

        navigate(`/trips/${data.groupId}`)

        dispatch({type: CREATE_TRIP, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const changeGroupSettings = (groupId, payload, navigate) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.changeGroupSettings(groupId, payload)

        dispatch({type: CREATE_GROUP, payload: data})
        navigate()

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const getGroups = (filters) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.getGroups(filters)

        dispatch({type: GET_GROUPS, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const getIntros = (queryParams) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.getIntros(queryParams)

        if (data.success) {
            dispatch({type: GET_INTROS, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        alert(error.response.data.message)
    }
}

export const getWorkshops = (queryParams) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.getWorkshops(queryParams)

        if (data.success) {
            dispatch({type: GET_WORKSHOPS, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        alert(error.response.data.message)
    }
}

export const getConnects = (queryParams) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.getConnects(queryParams)

        if (data.success) {
            dispatch({type: GET_CONNECTS, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        alert(error.response.data.message)
    }
}

export const getTrips = (queryParams) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.getTrips(queryParams)

        if (data.success) {
            dispatch({type: GET_TRIPS, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        alert(error.response.data.message)
    }
}

export const getGroup = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.getGroup(id)

        dispatch({type: GET_GROUP, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const getIntro = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.getIntro(id)

        dispatch({type: GET_INTRO, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const getWorkshop = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.getWorkshop(id)

        dispatch({type: GET_WORKSHOP, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const getConnect = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.getConnect(id)

        dispatch({type: GET_CONNECT, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const getTrip = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.getTrip(id)

        dispatch({type: GET_TRIP, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const changeGroupStatus = (id, status) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.changeGroupStatus(id, status)

        dispatch({type: CHANGE_GROUP_STATUS, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const deleteGroup = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})
        const {data} = await groupsApi.deleteGroup(id)

        dispatch({type: DELETE_GROUP, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const getGroupNotes = (groupId) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.getGroupNotes(groupId)

        dispatch({type: GET_GROUP_NOTES, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const addStudentToGroup = (groupId, studentId, joinDate, close) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.addStudentToGroup(groupId, studentId, joinDate)

        dispatch({type: ADD_STUDENT_TO_GROUP, payload: data})
        close()

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const removeStudentFromGroup = (groupId, studentId, note) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.removeStudentFromGroup(groupId, studentId, note)

        dispatch({type: REMOVE_STUDENT_FROM_GROUP, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const changeStudentStatus = (groupId, studentId, status, date, note) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.changeStudentStatus(groupId, studentId, status, date, note)

        dispatch({type: CHANGE_GROUP_STUDENT_STATUS, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const changeStudentJoinDate = (groupId, studentId, joinDate) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.changeStudentJoinDate(groupId, studentId, joinDate)

        dispatch({type: CHANGE_STUDENT_JOIN_DATE, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const addStudentNote = (groupId, studentId, noteData) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.addStudentNote(groupId, studentId, noteData)

        dispatch({type: ADD_STUDENT_NOTE, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}


export const changeLessonDate = (groupId, lessonDate, replaceDate, lessonIndex, levelIndex, note) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.changeLessonDate(groupId, lessonDate, replaceDate, lessonIndex, levelIndex, note)

        dispatch({type: CHANGE_LESSON_DATE, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const cancelLesson = (groupId, lessonDate, note, levelIndex, lessonIndex) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.cancelLesson(groupId, lessonDate, note, levelIndex, lessonIndex)

        dispatch({type: CANCEL_LESSON, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const restoreCanceledLesson = (groupId, lessonDate, levelIndex, lessonIndex) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.restoreCanceledLesson(groupId, lessonDate, levelIndex, lessonIndex)

        dispatch({type: RESTORE_CANCELED_LESSON, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const markLessonAttendance = (groupId, lessonDate, attendanceData, lessonIndex, levelIndex) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.markLessonAttendance(groupId, lessonDate, attendanceData, lessonIndex, levelIndex)

        dispatch({type: MARK_LESSON_ATTENDANCE, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const addManyStudents = (groupId, studentIds, joinDate) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await groupsApi.addManyStudents(groupId, studentIds, joinDate)

        dispatch({type: ADD_MANY_STUDENTS, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}