import React, {useState} from 'react';
import moment from "moment";
import {AbsentIcon, CanceledLessonIcon, CheckedGreenIcon, CommentIcon} from "../../../../assets/icons"

const AttendanceNoteIcon = ({note, type, isParentScrolling}) => {
    const [hovered, setHovered] = useState(false)

    const bgColor = type === 'absent' ? 'bg-[#FF0707]' : 'bg-[#20BA07]'

    const toggleShowNote = () => {
        if(!isParentScrolling) {
            setHovered(true)
        } else {
            setHovered(false)
        }
    }

    return (
        <div
            className={`relative w-5 h-5 flex justify-center items-center rounded-[3px] ${bgColor}`}
            onMouseOver={toggleShowNote}
            onMouseLeave={toggleShowNote}
        >
            <CommentIcon/>

            <div
                className={`absolute bottom-[calc(100%+10px)] z-[9999] ${hovered ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'} transition bg-white rounded-[3px] py-[5px] px-2.5 shadow-md`}>
                {note}

                <div className={'relative flex items-center justify-center'}>
                    <div
                        className={'w-2 h-2 bg-white absolute top-[calc(100%+1.1px)] transform rotate-[45deg]'}></div>
                </div>
            </div>
        </div>
    )
}

const StudentAttendanceIcon = ({lessonDate, data, canceled, lineHovered, hoverLine, index, isParentScrolling}) => {
    const isBeforeToday = lessonDate.isSameOrBefore(moment())

    const Icon = () => (
        data?.present === true
            ?
            data?.note ?
                <AttendanceNoteIcon type={'present'} note={data.note} isParentScrolling={isParentScrolling}/>
                :
                <CheckedGreenIcon/>
            :
            data?.present === false
                ?
                data?.note ?
                    <AttendanceNoteIcon type={'absent'} note={data.note} isParentScrolling={isParentScrolling}/>
                    :
                    <AbsentIcon/>
                :
                ''
    )

    return (
        <div
            className={`w-full h-10 flex items-center justify-center border-b ${lineHovered && 'bg-black bg-opacity-5'}`}
            onMouseOver={() => hoverLine(index)}
            onMouseLeave={() => hoverLine(null)}
        >
            {canceled
                ?
                <CanceledLessonIcon/>
                :
                isBeforeToday
                    ?
                    data
                        ?
                        <Icon/>
                        :
                        <div className={'w-5 h-5 rounded-[3px] bg-[#00000019]'}></div>
                    :
                    data ? '' : <div className={'w-5 h-5 rounded-[3px] bg-[#00000019]'}></div>}
        </div>
    );
};

export default StudentAttendanceIcon;