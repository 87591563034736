import React from 'react';
import DriverPersonalData from "../../../../../../components/transport/subpages/drivers/driver/DriverPersonalData";
import DriverWorkData from "../../../../../../components/transport/subpages/drivers/driver/DriverWorkData";

const DriverProfile = ({driver}) => {
    return (
        <div className={'flex justify-between mt-7'}>
            <DriverPersonalData {...driver}/>
            <DriverWorkData {...driver}/>
        </div>
    );
};

export default DriverProfile;