import React, {useState, useRef, useEffect} from 'react';
import {AsteriskIcon, CloseIcon, HorizontalLine} from "../../assets/icons";
import {daysOfWeek} from "../../common/dropdownOptions";
import moment from "moment";
import {PlusButton} from "../shared/buttons";

const newScheduleDay = (dayIndex) => {
    return {
        dayIndex,
        startTime: '',
        endTime: ''
    }
}

const SelectSchedule = ({schedule, addToSchedule, removeScheduleDay, onFocus, lessonDuration, showPlusButton}) => {
    const [selectedDaySchedule, setSelectedDaySchedule] = useState(null)
    const containerRef = useRef(null);

    const handleSelectScheduleDay = (dayIndex) => {
        const existingScheduleDay = schedule.find(item => item.dayIndex === dayIndex)

        selectedDaySchedule?.dayIndex === dayIndex
            ? setSelectedDaySchedule(null)
            : existingScheduleDay ? setSelectedDaySchedule(existingScheduleDay) : setSelectedDaySchedule(newScheduleDay(dayIndex))
    }

    const handleChangeTime = e => {
        const timeValue = e.target.value;
        let value = timeValue.replace(/\D/g, '').slice(0, 4)
        let hours = timeValue.slice(0, 2).replace(/\D/g, '')
        let minutes = timeValue.slice(3).replace(/\D/g, '')

        if (value.length >= 2 && hours <= 24 && minutes <= 59) {
            value = `${value.slice(0, 2)}:${value.slice(2)}`
        }

        if ((hours > 24 || (hours >= 3 && hours <= 9)) || (minutes > 59 || (minutes >= 6 && minutes <= 9))) return

        setSelectedDaySchedule({
            ...selectedDaySchedule,
            [e.target.name]: value
        })

        if (e.target.name === 'startTime') {
            const startTime = moment(value, 'HH:mm')
            const endTime = startTime.add(lessonDuration, 'minutes').format('HH:mm')

            if (startTime.isValid()) {
                setSelectedDaySchedule(prevState => ({
                    ...prevState,
                    endTime: e.target.name === 'startTime' ? endTime : ''
                }))
            } else {
                setSelectedDaySchedule(prevState => ({
                    ...prevState,
                    endTime: ''
                }))
            }
        }
    }

    const handleSubmit = () => {
        if (selectedDaySchedule && selectedDaySchedule.startTime && selectedDaySchedule.endTime && selectedDaySchedule.startTime.length === 5 && selectedDaySchedule.endTime.length === 5) {
            addToSchedule(selectedDaySchedule)
            setSelectedDaySchedule(null)
        }
    }

    const handleKeyDown = (e, dayIndex) => {
        const {name, value} = e.target;

        if (e.key === 'Enter') {
            handleSubmit()
        } else if (e.key === 'Backspace' && value.length === 3 && value.charAt(2) === ":") {
            e.preventDefault();
            setSelectedDaySchedule({
                ...selectedDaySchedule,
                [name]: value.slice(0, 1)
            })
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            const startTime = document.querySelector('input[name="startTime"]');
            const endTime = document.querySelector('input[name="endTime"]');

            if (
                startTime &&
                endTime &&
                startTime.value.length === 5 &&
                endTime.value.length === 5
            ) {
                if (containerRef.current && !containerRef.current.contains(event.target)) {
                    handleSubmit();
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        };
    }, [containerRef, selectedDaySchedule]);

    return (
        <div ref={containerRef} className={'flex flex-col gap-[30px]'}>
            {schedule?.length > 0 &&
                <div className={'flex flex-col gap-[30px]'}>
                    {schedule?.map((item, index) => (
                        <div key={index} className={'flex flex-col gap-5'}>
                            <div
                                className={'w-[362px] h-10 flex items-center justify-between px-[30px] bg-gray-200 rounded-[5px]'}>
                                <div className={'flex items-center gap-[30px]'}>
                                    <span>{daysOfWeek[item.dayIndex]}</span>
                                    <span>{item.startTime}</span>
                                    <HorizontalLine/>
                                    <span>{item.endTime}</span>
                                </div>
                                <span onClick={() => removeScheduleDay(item.dayIndex)}
                                      className={'cursor-pointer'}><CloseIcon
                                    width={20} height={20}/></span>
                            </div>
                        </div>
                    ))}
                </div>
            }

            <div onClick={onFocus} className={'flex flex-row gap-10'}>
                <div className={'flex gap-[30px]'}>
                    {daysOfWeek.map((day, index) => {
                        const active = index === selectedDaySchedule?.dayIndex;

                        return (
                            <div
                                key={index}
                                onClick={() => handleSelectScheduleDay(index)}
                                className={`w-[45px] h-[45px] flex items-center justify-center border ${active ? 'border-[#FCC10F] bg-[#FCC10F] text-white' : 'border-black border-opacity-70'} rounded-full cursor-pointer select-none transition`}>
                                {day}
                            </div>
                        );
                    })}
                </div>
            </div>

            {selectedDaySchedule &&
                <div className={'grid grid-cols-2 max-w-[495px] max-h-[90px] gap-[45px]'}>
                    <div className={'flex flex-col gap-[30px]'}>
                        <h2>Դասի սկիզբ</h2>
                        <input
                            type={"text"}
                            name={'startTime'}
                            className={`w-[225px] h-10 border border-black border-opacity-70 outline-none rounded-[5px] px-[50px] ${selectedDaySchedule?.startTime && 'text-center'}`}
                            placeholder={'Օրինակ՝ 14։00'}
                            onChange={handleChangeTime}
                            onKeyDown={handleKeyDown}
                            value={selectedDaySchedule?.startTime}
                        />
                    </div>
                    <div className={'flex flex-col gap-[30px]'}>
                        <h2>Դասի ավարտ</h2>
                        <input
                            type={"text"}
                            name={'endTime'}
                            className={`w-[225px] h-10 border border-black border-opacity-70 outline-none rounded-[5px] px-[50px] ${selectedDaySchedule?.endTime && 'text-center'}`}
                            placeholder={'Օրինակ՝ 16։00'}
                            onChange={handleChangeTime}
                            onKeyDown={handleKeyDown}
                            value={selectedDaySchedule?.endTime}
                            disabled
                        />
                    </div>
                </div>
            }
            {showPlusButton &&
                <div className={`flex items-center justify-center`} onClick={() => {}}>
                    <PlusButton className={'p-2.5'} width={10} height={10}/>
                </div>
            }
        </div>
    );
};

export default SelectSchedule;
