import API from './index'

export const getSMARTConnects = () => API.get(`/connects`)
export const createSMARTConnect = (name) => API.post(`/connects`, {name})

export const getSMARTTrips = () => API.get(`/trips`)
export const createSMARTTrip = (name) => API.post(`/trips`, {name})

export const getSMARTWorkshops = () => API.get(`/workshops`)
export const createSMARTWorkshop = (name) => API.post(`/workshops`, {name})

export const getEducationalEstablishments = () => API.get(`/establishments`)
export const createEducationalEstablishment = (data) => API.post(`/establishments`, data)

export const getCommunityNames = () => API.get(`/communities/names`)

export const getProgramNames = () => API.get(`/programs/names`)
export const getMembersNamesList = (roles) => API.get(`/members/names?roles=${roles.join(',')}`)

export const getMemberRoles = () => API.get('/members/roles')

export const getTransportStopsNames = () => API.get(`/transport/stops/names`)
export const getRegions = () => API.get('/regions')
export const getTransportRouteFilters = () => API.get('/transport/routes/filters')
export const getVehicleFilters = () => API.get(`/transport/vehicles/filters`)
export const getVehicleMarks = () => API.get(`/transport/vehicles/marks`)

export const getScheduleCodes = () => API.get('groups/schedule-codes')

