import React, { useEffect, useState } from 'react';
import { AsteriskIcon, CloseIcon, ProjectUploadIcon, SearchIcon } from "../../../../../assets/icons";
import { ButtonFilled, ButtonOutlined } from "../../../../shared/buttons";
import { useParams } from "react-router-dom";
import { AutoResizableTextarea } from "../../../../shared";
import { useDispatch } from "react-redux";
import { createProgramProject } from "../../../../../store/actions/programs";
import { getGroupNameSuggestions } from "../../../../../api/groups";

const initialState = {
    title: '',
    description: '',
    group: null,
    media: null,
    file: null,
}

const CreateProjectModal = ({ close, page }) => {
    const [searchGroupValue, setSearchGroupValue] = useState('')
    const [searchedGroups, setSearchedGroups] = useState(null)
    const [data, setData] = useState(initialState)
    const [errors, setErrors] = useState({})
    const [uploadedFiles, setUploadedFiles] = useState([])

    const { id } = useParams()
    const dispatch = useDispatch()

    const handleChangeSearchGroup = (e) => {
        setSearchGroupValue(e.target.value)
    }

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const handleRemoveSelectedGroup = () => {
        setData({ ...data, group: null })
        setSearchGroupValue('')
    }

    const handleSelectGroup = (group) => {
        setData({ ...data, group })
        setSearchGroupValue(group.name)
    }

    const handleFileChange = (e, fileType) => {
        const files = Array.from(e.target.files)
        let media, file

        if (fileType === 'media') {
            media = files.find(file => file.type.startsWith('image/') || file.type.startsWith('video/'))
            setData(prevData => ({ ...prevData, media }))
        } else if (fileType === 'pdf') {
            file = files.find(file => file.type === 'application/pdf')
            setData(prevData => ({ ...prevData, file }))
        }

        const newUploadedFiles = [...uploadedFiles.filter(item => item !== null), media, file].filter(Boolean)
        setUploadedFiles(newUploadedFiles)

        e.target.value = null
    }

    const handleRemoveSelectedFile = (fileType) => {
        if (fileType === 'media') {
            setData({
                ...data,
                media: null,
            })
            setUploadedFiles(prevFiles => prevFiles.filter(file => file && !file.type.startsWith('image/') && !file.type.startsWith('video/')))
        } else if (fileType === 'pdf') {
            setData({
                ...data,
                file: null,
            })
            setUploadedFiles(prevFiles => prevFiles.filter(file => file && file.type !== 'application/pdf'))
        }
    };

    const handleCreateProject = () => {
        const newErrors = {}
        if (!data.title) newErrors.title = 'Այս դաշտը պարտադիր է'
        if (!data.description) newErrors.description = 'Այս դաշտը պարտադիր է'
        if (!data.group) newErrors.group = 'Այս դաշտը պարտադիր է'
        if (!data.media) newErrors.media = 'Այս դաշտը պարտադիր է'

        if (Object.keys(newErrors).length === 0) {
            const formData = new FormData()
            formData.append('media', data.media)
            formData.append('file', data.file)
            formData.append('title', data.title)
            formData.append('description', data.description)
            formData.append('group', data.group._id)

            dispatch(createProgramProject(id, formData, page))
            close()
        } else {
            setErrors(newErrors)
        }
    }

    useEffect(() => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            title: data.title ? "" : prevErrors.title,
            description: data.description ? "" : prevErrors.description,
            group: data.group ? "" : prevErrors.group,
            media: data.media ? "" : prevErrors.media,
        }));
    }, [data.title, data.description, data.group, data.media, data.file])

    useEffect(() => {
        const fetchGroupSuggestions = async () => {
            const { data } = await getGroupNameSuggestions(searchGroupValue, `program=${id}`)
            setSearchedGroups(data)
        }

        if (searchGroupValue) fetchGroupSuggestions()
    }, [searchGroupValue])

    return (
        <div>
            <div className={'fixed z-[9990] w-screen h-screen top-0 left-0 flex items-center justify-center'}>
                <div onClick={close}
                     className={'fixed bg-black bg-opacity-5 backdrop-blur w-full h-full top-0 left-0 z-[9990]'}></div>
                <div
                    className={`absolute grid grid-rows-[auto,1fr,auto] w-full max-w-[879px] z-[9999] pt-[30px] ${errors ? 'pb-5' : 'pb-[50px]'} border border-black rounded-[10px] bg-white`}>
                    <div className={'relative flex items-center justify-center'}>
                        <h2 className={'text-center'}>Նոր նախագիծ</h2>
                        <button className={'absolute right-6 bg-black bg-opacity-5 rounded-full p-1.5'} onClick={close}>
                            <CloseIcon width={20} height={20} />
                        </button>
                    </div>

                    <div className={'flex gap-[30px] px-[30px] mt-5'}>
                        <div className={'flex flex-col gap-5'}>
                            <div className={'flex flex-col justify-center items-center gap-5'}>
                                <div className={'flex flex-col items-center justify-center gap-2.5'}>
                                    <p className={'flex items-center gap-[15px]'}><AsteriskIcon /> <span
                                        className={'text-[#FF0707]'}>Նկար/տեսանյութ</span></p>
                                    <div
                                        className={'w-[100px] h-[100px] flex items-center justify-center border-[3px] border-[#FCC10F] rounded-full cursor-pointer relative'}>
                                        <ProjectUploadIcon />
                                        <input type={"file"} accept={"video/*, image/*"}
                                               autoComplete={false}
                                               onChange={(e) => handleFileChange(e, 'media')}
                                               className={"absolute inset-0 opacity-0 cursor-pointer"}
                                               disabled={!!data.media} />
                                    </div>
                                    {errors.media && <p className={'text-red-500'}>{errors.media}</p>}
                                </div>
                                <div className={'flex flex-col items-center justify-center gap-2.5'}>
                                    <p className={'flex items-center gap-[15px]'}><span className={'text-[#FF0707]'}>Կցել PDF ֆայլ</span>
                                    </p>
                                    <div
                                        className={'w-[100px] h-[100px] flex items-center justify-center border-[3px] border-[#FCC10F] rounded-full cursor-pointer relative'}>
                                        <ProjectUploadIcon />
                                        <input type={"file"} accept={".pdf"}
                                               onChange={(e) => handleFileChange(e, 'pdf')}
                                               className={"absolute inset-0 opacity-0 cursor-pointer"}
                                               disabled={!!data.file} />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-2">
                                {uploadedFiles.map((file, index) =>
                                    <div className={'flex items-center justify-center gap-3'}>
                                        <p key={index}
                                           className={"max-w-[170px] text-gray-700 text-center break-all"}>
                                            <span>{file?.name}</span>
                                        </p>
                                        {file.type.startsWith('image/') || file.type.startsWith('video/') ?
                                            <span onClick={() => handleRemoveSelectedFile('media')}><CloseIcon width={15} height={15} /></span>
                                            :
                                            <span onClick={() => handleRemoveSelectedFile('pdf')}><CloseIcon width={15} height={15} /></span>
                                        }
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={'flex flex-col gap-[10px]'}>
                            <div className={`flex flex-col ${errors ? 'gap-3' : 'gap-5'} w-[548px]`}>
                                <p className={'flex items-center gap-[15px] text-[#FF0707]'}><AsteriskIcon /> դաշտերը
                                    պարտադիր լրացման են</p>
                                <div className={'relative flex flex-col gap-[10px]'}>
                                    <p className={'flex gap-1'}>Խմբի համարը <AsteriskIcon /></p>
                                    <div
                                        className={`border border-black border-opacity-70 py-[2px] px-[30px] rounded-[3px] outline-none`}>
                                        <div className={'flex items-center justify-between'}>
                                            {data.group ?
                                                <div className={'flex items-center justify-between'}>
                                                    <span>{data.group.name}</span>
                                                </div>
                                                :
                                                <input
                                                    type={'text'}
                                                    name="group"
                                                    value={searchGroupValue}
                                                    onChange={handleChangeSearchGroup}
                                                    placeholder={'որոնել'}
                                                    className={'w-full pr-[30px] outline-none'}
                                                    autoComplete={"off"}
                                                />
                                            }
                                            <div className={'flex items-center gap-5'}>
                                                {data.group &&
                                                    <button onClick={handleRemoveSelectedGroup}>
                                                        <CloseIcon width={15} height={15} />
                                                    </button>
                                                }
                                                <button>
                                                    <SearchIcon />
                                                </button>
                                            </div>
                                        </div>
                                        {!data.group && searchedGroups && searchGroupValue && (
                                            <div
                                                className={'max-h-[80px] overflow-scroll absolute left-0 right-0 w-full bg-white border border-black rounded-b-[3px]'}>
                                                {searchedGroups?.length > 0 ?
                                                    searchedGroups.map(group => (
                                                        <div key={group._id} className={'hover:bg-gray-200 transition'}
                                                             onClick={() => handleSelectGroup(group)}>
                                                            <p className={'px-[30px] py-1'}>{group.name}</p>
                                                        </div>
                                                    ))
                                                    :
                                                    <p className={'px-[30px]'}>Խմբեր չկան</p>
                                                }
                                            </div>
                                        )}
                                    </div>
                                    {errors.group && <p className={'text-red-500'}>{errors.group}</p>}
                                </div>
                                <div className={'flex flex-col gap-[10px]'}>
                                    <p className={'flex gap-1'}>Նախագծի անունը <AsteriskIcon /></p>
                                    <input type={"text"} name={'title'} value={data.title} onChange={handleChange}
                                           className={'w-full h-[30px] border border-black border-opacity-70 py-1 px-[30px] rounded-[3px] outline-none'} />
                                    {errors.title && <p className={'text-red-500'}>{errors.title}</p>}
                                </div>
                                <div className={'flex flex-col gap-[10px]'}>
                                    <p className={'flex gap-1'}>Նախագծի նկարագրություն <AsteriskIcon /></p>
                                    <AutoResizableTextarea value={data.description} name={'description'}
                                                           onChange={handleChange} maxLength={500} />
                                    {errors.description && <p className={'text-red-500'}>{errors.description}</p>}
                                </div>
                            </div>
                            <div className={'flex flex-col gap-[30px]'}>
                                <p className={'flex justify-end text-black text-opacity-70'}>500 նշանի սահմանում</p>
                                <div className={'flex items-center justify-end gap-[30px]'}>
                                    <ButtonOutlined onClick={close} text={'Չեղարկել'} className={'w-[176px]'}/>
                                    <ButtonFilled onClick={handleCreateProject} text={'Ստեղծել'}
                                                  className={'w-[176px] shadow-md'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateProjectModal;
