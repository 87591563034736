import React, {useEffect, useRef, useState} from 'react';
import {ConfirmationModal} from "../../shared";
import {
    ActionMenuIcon, ActiveStudentsIcon,
    AlarmIcon,
    DriverIcon,
    GeoIcon,
    PeopleIcon,
    StatusIcon,
    TransportIcon
} from "../../../assets/icons";
import ButtonHoverText from "../../shared/buttons/ButtonHoverText";
import {useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {useOutsideClick} from "../../../hooks/useOutsideClick";
import {changeTransportStopStatus, changeVehicleStatus, selectTransportStop} from "../../../store/actions/transport";
import Drivers from "../../../pages/main/transport/subpages/drivers/Drivers";

const VehicleCard = ({
                         _id,
                         active: initialActive,
                         model,
                         color,
                         drivers,
                         seatingCapacity,
                         plate,
                         handleDeleteVehicle,
                         setVehicleToDelete,
                         vehicleToDelete,
                     }) => {
    const [showMenu, setShowMenu] = useState(false)
    const [statusToChange, setStatusToChange] = useState(false)
    const [active, setActive] = useState(initialActive)

    const ref = useRef()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useOutsideClick(ref, () => setShowMenu(false))

    const deleteVehicle = () => {
        setVehicleToDelete(_id)
    }

    const changeStatus = () => {
        setStatusToChange(true)
    }

    const handleChangeStatus = () => {
        dispatch(changeVehicleStatus(_id, !active))
        setActive(!active)
        setShowMenu(false)
        setStatusToChange(false)
    }

    useEffect(() => {
        setActive(initialActive)
    }, [initialActive])

    useEffect(() => {
        document.body.style.overflow = vehicleToDelete || statusToChange ? 'hidden' : 'auto'
    }, [vehicleToDelete, statusToChange])

    return (
        <>
            {(vehicleToDelete) &&
                <ConfirmationModal
                    close={() => setVehicleToDelete(null)}
                    warningText={`Վստա՞հ եք, որ ցանկանում եք ջնջել այս կանգառը։`}
                    confirm={handleDeleteVehicle}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            {statusToChange &&
                <ConfirmationModal
                    close={() => setStatusToChange(false)}
                    warningText={`Վստա՞հ եք, որ ցանկանում եք ${active ? 'ապաակտիվացնել' : 'ակտիվացնել'} այս մեքենան։`}
                    confirm={handleChangeStatus}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            <div className={'relative w-[405px] h-[221px] custom-shadow rounded-[5px]'}>
                <div
                    className={`${showMenu ? 'block' : 'hidden'} absolute w-full h-full z-40 backdrop-blur-[1px]`}></div>


                <div className={'flex justify-center'}>
                    <div className={'relative flex items-center gap-2.5 my-5 cursor-pointer'}>
                        <span className={'text-[#FCC10F]'}>{model}</span>
                        {active === true
                            ?
                            <ButtonHoverText icon={<StatusIcon color={'#20BA07'}/>} text={'Ակտիվ'}/>
                            :
                            <ButtonHoverText icon={<StatusIcon color={'#00000099'}/>} text={'Ապաակտիվ'}/>
                        }
                    </div>
                    <button
                        className={`absolute z-50 right-5 top-5 ${showMenu ? 'pointer-events-none' : 'pointer-events-auto'}`}
                        onClick={() => setShowMenu(!showMenu)}
                    >
                        <ActionMenuIcon width={20} height={20}/>
                    </button>
                </div>

                <div className={'flex items-center justify-center'}>
                    <TransportIcon width={70} height={70}/>
                </div>

                {showMenu && (
                    <div ref={ref}
                         className={`absolute z-50 w-[203px] top-[60px] flex flex-col gap-4 bg-white right-5 py-6 border border-black rounded-[5px]`}>
                        <p
                            onClick={changeStatus}
                            className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                            {active ? 'Ապաակտիվացնել' : 'Ակտիվացնել'}
                        </p>
                        <p
                            onClick={deleteVehicle}
                            className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                            Ջնջել
                        </p>
                    </div>
                )}

                <div className={'w-full flex justify-between px-[30px]'}>
                    <div className={'flex items-center gap-2.5'}>
                        <ActiveStudentsIcon color={'#000'}/>
                        <span>{seatingCapacity}</span>
                    </div>
                    <div className={'flex items-center gap-2.5'}>
                        <span>{color}</span>
                    </div>
                </div>

                <div className={'w-full  flex justify-between px-[30px] py-5'}>
                    <div className={"relative group h-full"}>
                        <div className={'flex items-center gap-5 select-none'}>
                            <DriverIcon color={'#000'}/>
                            <span>Վարորդ</span>
                        </div>
                        {drivers.length >= 1 &&
                            <div
                                className={"w-[220px] absolute z-10 hidden group-hover:flex flex-col gap-y-2.5 bg-white border shadow-md rounded-[3px] p-2 left-[85%]"}>
                                {drivers?.map((driver, index) => (
                                    <Link to={`/transport/drivers/${driver._id}`} key={index} className={'text-[12px] hover:text-[#FCC10F]'}>{driver.fullName}</Link>
                                ))}
                            </div>
                        }
                    </div>
                    <div className={'flex items-center gap-2.5'}>
                        <span>{plate}</span>
                    </div>
                </div>

            </div>
        </>
    );
};

export default VehicleCard;