import React, {useState} from 'react';
import {CopyIcon, EditIcon, ExternalFormIcon, InnerFormIcon, ListTaskIcon, TrashIcon} from "../../assets/icons";
import ButtonHoverText from "../shared/buttons/ButtonHoverText";
import {useDispatch} from "react-redux";
import {changeRequestFormStatus, deleteRequestForm} from "../../store/actions/forms";
import FormStatusDropdown from "../shared/dropdowns/FormStatusDropdown";
import {Link, useNavigate} from "react-router-dom";
import externalForm from "../../assets/images/ExternalForm.png";
import innerForm from "../../assets/images/InnerForm.png"

const statusOptions = [
    {id: 1, value: 'public', name: 'Հրապարակել'},
    {id: 2, value: 'draft', name: 'Սևագիր'},
    {id: 3, value: 'closed', name: 'Փակել հայտը'},
]

const displayNames = {
    public: 'Հրապարակված',
    draft: 'Սևագիր',
    closed: 'Փակված',
}

const FormItem = ({name, status, _id, type}) => {
    const [copied, setCopied] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleCopyLink = () => {
        const externalLink = `${window.location.origin}/forms/view/${_id}`;
        navigator.clipboard.writeText(externalLink)
            .then(() => {
                setCopied(true)
                setTimeout(() => setCopied(false), 1000)
            })
    };

    const redirectToRequests = () => {
        navigate(`/requests?size=10&page=1&archive=false&formN=${_id}`)
    }

    const handleSelectStatus = (e) => {
        dispatch(changeRequestFormStatus(_id, e.target.value))
    }

    const handleDeleteForm = (e) => {
        dispatch(deleteRequestForm(_id))
    }

    return (
        <div
            className={'grid grid-cols-[1fr,1fr,120px] px-[30px] border border-black border-opacity-30 rounded-[5px]'}>
            <div className={'flex gap-5 items-center line-clamp-1 py-1.5'}>
                {type === 'request'
                    ?
                    <img src={externalForm} alt={'external_form'}/>
                    :
                    <img src={innerForm} alt={'inner_form'}/>
                }
                <Link to={`/forms/view/${_id}`}
                      className={'truncate hover:text-[#FCC10F] transition'}>{name}</Link>
            </div>
            <div>
                <FormStatusDropdown value={displayNames[status]} selectedKey={status} data={statusOptions}
                                    onChange={handleSelectStatus}/>
            </div>
            <div className={'flex items-center gap-[30px] justify-end py-1.5'}>
                {(status === 'closed' || status === 'public')
                    ?
                    <>
                        <ButtonHoverText text={'Բացել լրացված հայտերը'} icon={<ListTaskIcon/>}
                                         onClick={redirectToRequests}/>
                        {status !== 'closed' &&
                            <ButtonHoverText text={copied ? 'Պատճենված է' : 'Պատճենել հղումը'} icon={<CopyIcon/>}
                                             onClick={handleCopyLink}/>}
                    </>
                    :
                    <ButtonHoverText text={'Խմբագրել'} icon={<EditIcon/>} onClick={() => navigate(`/forms/${_id}`)}/>
                }

                {status === 'draft' && <ButtonHoverText text={'Ջնջել'} icon={<TrashIcon/>} onClick={handleDeleteForm}/>}
            </div>
        </div>
    );
};

export default FormItem;