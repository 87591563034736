import {AUTH_ERROR, CHANGE_PROFILE_SETTINGS, CHECK_AUTH, LOGOUT, SET_LOADING, SIGN_IN} from '../constants/actionTypes'
import * as authApi from '../../api/auth'
import * as profileApi from '../../api/profile'

export const signIn = (formData) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await authApi.signIn(formData)

        dispatch({ type: SIGN_IN, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        // const {data} = error.response
        console.log(error)
        // dispatch({ type: AUTH_ERROR, payload: data })
    }
}

export const googleSignIn = (token) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await authApi.googleSignIn(token)

        dispatch({ type: SIGN_IN, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        const {data} = error.response
        dispatch({ type: AUTH_ERROR, payload: data })
    }
}

export const checkAuth = () => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await authApi.checkAuth()

        dispatch({ type: CHECK_AUTH, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message)
    }
}

export const logout = () => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        await authApi.logout()

        dispatch({ type: LOGOUT })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const changeProfileSettings = (id, memberData) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await profileApi.editProfile(id, memberData)

        dispatch({ type: CHANGE_PROFILE_SETTINGS, payload: data})
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}