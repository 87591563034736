import React, {useEffect, useRef, useState} from 'react';
import {CheckedIcon, DropdownArrow} from "../../../assets/icons";
import {gsap} from "gsap";

const pageArr = [
    {
        id: 10,
        value: 10,
    },
    {
        id: 50,
        value: 50,
    },
    {
        id: 100,
        value: 100,
    }
]
const PageSizeDropdown = ({value, onChange}) => {
    const [open, setOpen] = useState(false);
    const optionsRef = useRef(null);
    const selectedRef = useRef(null);

    const toggleOpen = () => {
        setOpen(!open);
    };

    const handleSelect = (newValue) => {
        onChange({target: {value: newValue}})
        toggleOpen()
    }

    useEffect(() => {
        const optionsElement = optionsRef.current;
        const selectedElement = selectedRef.current;

        if (open) {
            gsap.fromTo(
                optionsElement,
                {opacity: 0, height: 0, display: 'block'},
                {opacity: 1, height: 'auto', duration: 0.3}
            )
            gsap.fromTo(
                selectedElement,
                {borderBottomColor: 'none'},
                {borderBottomColor: "black", duration: 0.3}
            );
        } else {
            gsap.to(optionsElement, {opacity: 0, height: 0, duration: 0.2, display: 'none'})
            gsap.to(selectedElement, {borderBottomColor: 'none', duration: 0.2})
        }
    }, [open])

    return (
        <div className={`relative ${open && 'z-50'} select-none`}>
            <div
                className={`flex justify-between gap-[8px] px-5 py-[3px] border border-black rounded-[5px] ${open && 'rounded-b-none'}`}
                ref={selectedRef}
                onClick={toggleOpen}>

                <p>{value}/էջ</p>
                <button className={`${open && 'rotate-180'} transition`}><DropdownArrow/></button>
            </div>

            <div
                style={{background: '#fff'}}
                ref={optionsRef}
                className={`${open ? 'h-auto' : 'h-0'} w-full absolute border border-black rounded-b-[5px] border-t-0 overflow-hidden`}>
                <div className={`transition`}>
                    <div>
                        {pageArr.map(option => (
                            option.value !== value && (
                                <div key={option.id} onClick={() => handleSelect(option.value)}
                                     className={'pl-5 py-2 rounded-md cursor-pointer hover:bg-gray-100'}>
                                    <div className={'line-clamp-1 overflow-hidden'}>{option.value}/էջ</div>
                                </div>
                            )
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageSizeDropdown;