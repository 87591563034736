import {GET_COMMUNITIES, SET_LOADING} from "../constants/actionTypes";
import * as communitiesApi from "../../api/communities";

export const getCommunities = (sortOption) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await communitiesApi.getCommunities(sortOption)

        if (data.success) {
            dispatch({type: GET_COMMUNITIES, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error);
        alert(error.response?.data.message)
    }
}


