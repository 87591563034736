import React, {useEffect, useState} from 'react';
import {PlusButton} from '../../../../components/shared/buttons';
import CreateProjectModal from '../../../../components/programs/program/projects/modals/CreateProjectModal';
import {useDispatch, useSelector} from 'react-redux';
import {getProgramProjects} from '../../../../store/actions/programs';
import {useParams, useNavigate} from 'react-router-dom';
import ProjectItem from '../../../../components/programs/program/projects/ProjectItem';
import Pagination from "../../../../components/shared/Pagination";

const Projects = () => {
    const {projects, loading} = useSelector(state => state.programs)
    const [openCreateProjectModal, setOpenCreateProjectModal] = useState(false)
    const [page, setPage] = useState(1)

    const {id} = useParams();
    const dispatch = useDispatch();

    const handleChangePage = (_, p) => {
        window.scrollTo({top: 0})
        setPage(p)
    }

    useEffect(() => {
        dispatch(getProgramProjects(id, page))
    }, [page])

    useEffect(() => {
        openCreateProjectModal ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto'
    }, [openCreateProjectModal])

    return (
        <>
            {openCreateProjectModal && (
                <CreateProjectModal close={() => setOpenCreateProjectModal(false)} page={page}/>
            )}

            <div className={'mt-[30px]'}>
                <PlusButton
                    onClick={() => setOpenCreateProjectModal(!openCreateProjectModal)}
                    className={`p-2.5`}
                    width={10}
                    height={10}
                    styleClassName={`hover:bg-white transition border border-[#FCC10F]`}
                    hoverPlusColor={'#FCC10F'}
                />
            </div>

            <div>
                {loading ?
                    <p className={'text-center'}>Բեռնվում է․․․</p>
                    : projects?.data?.length > 0 ?
                        <>
                            {projects?.data?.map(project => (
                                <ProjectItem key={project._id} {...project} page={page}/>
                            ))}
                            <Pagination count={projects?.pagesCount} page={page} onChange={handleChangePage}/>
                        </>
                        :
                        <p className={'text-center mt-5'}>Նախագծեր չկան</p>
                }
            </div>
        </>
    );
};

export default Projects;
