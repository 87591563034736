import React, {useEffect, useRef, useState} from 'react';
import {AsteriskIcon, CheckedIcon, CloseIcon} from "../../assets/icons";
import {Dropdown, DropdownInput} from "../shared/dropdowns";
import {useDispatch, useSelector} from "react-redux";
import Calendar from "../shared/calendar/Calendar";
import {MandatoryWarningText, PhoneInput} from "../shared";
import {editRequest} from "../../store/actions/requests";
import {ButtonFilled, ButtonOutlined} from "../shared/buttons";
import {checkEditRequestFormData} from "../../utils/forms/checkEditRequestFormData";
import {getFormRequirements} from "../../store/actions/forms";

const genderOptions = [
    {id: 'male', value: 'Արական'},
    {id: 'female', value: 'Իգական'},
]

const EditRequestModal = ({request, close}) => {
    const [data, setData] = useState(request)
    const {communities} = useSelector(state => state.common)
    const {formNames, fields} = useSelector(state => state.forms)
    const [makeStudent, setMakeStudent] = useState(Boolean(data.status === 'active'))
    const [validEmail, setValidEmail] = useState('')
    const [errors, setErrors] = useState(null)
    const dispatch = useDispatch()

    const elementsRef = useRef(null)

    const handleChange = (e) => {
        setData({...data, data: {...data.data, [e.target.name]: e.target.value}})
    }

    const handleChangeEmail = (e) => {
        const inputValue = e.target.value.trim()
        const regex = /^[a-z.@.0-9]+$/

        if (inputValue === '' || regex.test(inputValue)) {
            setData({...data, data: {...data.data, [e.target.name]: e.target.value.toLowerCase()}})
        }
    }

    const changeBirthday = (e) => {
        setData({...data, data: {...data.data, birthday: e}})
    }

    const changePhoneNumber = (value, index) => {
        setData({
            ...data,
            data: {...data.data, phoneNumbers: data.data.phoneNumbers.map((item, i) => i === index ? value : item)}
        })
    }

    const handleChangeCommunity = (e) => {
        const selectedCommunity = communities.find(item => item._id === e.target.value)
        setData({
            ...data,
            data: {
                ...data.data,
                community: selectedCommunity
            }
        })
    }

    const changeParentName = (e) => {
        setData({
            ...data,
            data: {
                ...data.data,
                parentsFullNames: data.data.parentsFullNames.map((item, i) => i === parseInt(e.target.name) ? e.target.value : item)
            }
        })
    }

    const submitEditing = (e) => {
        const checkedFormResult = checkEditRequestFormData(data.data)

        if (!checkedFormResult.length) {
            dispatch(editRequest(data._id, {...data, makeStudent}))
            close()
        } else {
            setErrors(checkedFormResult)
        }
    }

    const checkIfWarnedFieldWritten = (key, value) => {
        if (errors?.includes(key) && !value) {
            return true
        }
    }

    useEffect(() => {
        elementsRef.current.scrollTo({top: 0})
    }, [])

    useEffect(() => {
        if (data.data.email.endsWith('@coaf.org') || data.data.email.endsWith('@gmail.com')) {
            setValidEmail(true)
        }
    }, [data.data.email])

    useEffect(() => {
        dispatch(getFormRequirements(data.form?._id))
    }, [])

    return (
        <div className={'fixed z-[9990] w-screen h-screen top-0 left-0 flex items-center justify-center'}>
            <div onClick={close}
                 className={'fixed bg-black bg-opacity-5 backdrop-blur w-full h-full top-0 left-0 z-[9990]'}></div>

            <div
                className={'absolute z-[9999] grid grid-rows-[64px,1fr] w-full h-[calc(100vh-200px)] max-w-[850px] overflow-hidden border border-black rounded-[10px] bg-white'}>
                <div className={'flex items-center justify-between py-[15px] px-[30px] border-b border-black'}>
                    <h2 className={'text-[28px] leading-[34px]'}>Հայտի խմբագրում</h2>
                    <button className={'bg-black bg-opacity-5 rounded-full p-1.5'}
                            onClick={close}>
                        <CloseIcon width={20} height={20}/>
                    </button>
                </div>

                <div ref={elementsRef} className={'p-[30px] overflow-scroll mr-[30px]'}>
                    <div className={'flex flex-col gap-[30px]'}>
                        {
                            fields?.firstName && (
                                <div className={'relative'}>
                                    <label htmlFor={'firstName'} className='flex items-center gap-1.5'>
                                        <span>Անուն</span>
                                        {fields?.firstName.mandatory && <AsteriskIcon />}
                                    </label>
                                    <input type="text" id={'firstName'} name={'firstName'}
                                           value={data.data?.firstName || ''}
                                           onChange={handleChange}
                                           className={`w-full border border-black border-opacity-70 outline-none py-[7px] px-[30px] rounded-[5px] mt-2.5 transition ${checkIfWarnedFieldWritten('firstName', data.data?.firstName) ? 'border-red-500' : ''}`}/>
                                    <div className={'absolute'}>
                                        <MandatoryWarningText text={'Պարտադիր դաշտ է'}
                                                              cond={checkIfWarnedFieldWritten('firstName', data.data?.firstName)}/>
                                    </div>
                                </div>
                            )
                        }
                        {
                            fields?.lastName && (
                                <div className={'relative'}>
                                    <label htmlFor={'lastName'} className='flex items-center gap-1.5'>
                                        <span>Ազգանուն</span>
                                        {fields?.lastName.mandatory && <AsteriskIcon />}
                                    </label>
                                    <input type="text" id={'lastName'} name={'lastName'} value={data.data?.lastName || ''}
                                           onChange={handleChange}
                                           className={`w-full border border-black border-opacity-70 outline-none py-[7px] px-[30px] rounded-[5px] mt-2.5 transition ${checkIfWarnedFieldWritten('lastName', data.data?.lastName) ? 'border-red-500' : ''}`}/>
                                    <div className={'absolute'}>
                                        <MandatoryWarningText text={'Պարտադիր դաշտ է'}
                                                              cond={checkIfWarnedFieldWritten('lastName', data.data?.lastName)}/>
                                    </div>
                                </div>
                            )
                        }
                        {
                            fields?.middleName && (
                                <div className={'relative'}>
                                    <label htmlFor={'middleName'} className='flex items-center gap-1.5'>
                                        <span>Հայրանուն</span>
                                        {fields?.middleName.mandatory && <AsteriskIcon />}
                                    </label>
                                    <input type="text" id={'middleName'} name={'middleName'}
                                           value={data.data?.middleName || ''}
                                           onChange={handleChange}
                                           className={`w-full border border-black border-opacity-70 outline-none py-[7px] px-[30px] rounded-[5px] mt-2.5 transition ${checkIfWarnedFieldWritten('middleName', data.data?.middleName) ? 'border-red-500' : ''}`}/>
                                    <div className={'absolute'}>
                                        <MandatoryWarningText text={'Պարտադիր դաշտ է'}
                                                              cond={checkIfWarnedFieldWritten('middleName', data.data?.middleName)}/>
                                    </div>
                                </div>
                            )
                        }
                        {
                            fields?.gender && (
                                <div>
                                    <label htmlFor={'gender'} className='flex items-center gap-1.5 mb-2.5'>
                                        <span>Սեռ</span>
                                        {fields?.gender.mandatory && <AsteriskIcon />}
                                    </label>
                                    <div className={'border border-black py-1 rounded-[5px]'}>
                                        <Dropdown data={genderOptions}
                                                  value={genderOptions.find(gender => gender.id === data.data?.gender || gender?.value === data.data?.gender)?.value || ''}
                                                  name={'gender'}
                                                  onChange={handleChange}/>
                                    </div>
                                </div>
                            )
                        }
                        {
                            fields?.community && (
                                <div>
                                    <label htmlFor={'community'} className='flex items-center gap-1.5 mb-2.5'>
                                        <span>Համայնք</span>
                                        {fields?.community.mandatory && <AsteriskIcon />}
                                    </label>
                                    <div className={'border border-black rounded-[5px]'}>
                                        <DropdownInput data={communities} value={data.data?.community?._id}
                                                       onChange={handleChangeCommunity} id={'community'}/>
                                    </div>
                                </div>
                            )
                        }
                        {
                            fields?.birthday && (
                                <div className={'relative'}>
                                    <label htmlFor={'gender'} className='flex items-center gap-1.5 mb-2.5'><span>Դիմողի ծննդյան տվյալներ</span>
                                        {fields?.birthday.mandatory && <AsteriskIcon/>}</label>
                                    <div className={'border border-black py-[7px] rounded-[5px]'}>
                                        <Calendar value={data.data.birthday || ''} displayFormat={'DD/MM/YYYY'}
                                                  returnFormat={'YYYY-MM-DD'} setDate={changeBirthday}
                                                  onChange={handleChange}/>
                                    </div>
                                    <div className={'absolute'}>
                                        <MandatoryWarningText text={'Պարտադիր դաշտ է'}
                                                              cond={checkIfWarnedFieldWritten('gender', data.data?.gender)}/>
                                    </div>
                                </div>
                            )
                        }
                        {
                            fields?.birthCertificate && (
                                <div className={'relative'}>
                                    <label htmlFor={'birthCertificate'} className='flex items-center gap-1.5'>
                                        <span>Դիմողի ծննդյան վկայականի կամ անձնագրի համար</span>
                                        {fields?.birthCertificate.mandatory && <AsteriskIcon />}
                                    </label>
                                    <input type="text" id={'birthCertificate'} name={'birthCertificate'}
                                           value={data.data.birthCertificate || ''}
                                           onChange={handleChange}
                                           className={'w-full border border-black border-opacity-70 outline-none py-[7px] px-[30px] rounded-[5px] mt-2.5'}/>
                                    <div className={'absolute'}>
                                        <MandatoryWarningText text={'Պարտադիր դաշտ է'}
                                                              cond={checkIfWarnedFieldWritten('birthCertificate', data.data?.birthCertificate)}/>
                                    </div>
                                </div>
                            )
                        }
                        {
                            fields?.phoneNumber && (
                                data.data.phoneNumbers.map((phoneNumber, index) => (
                                    <div key={index} className={'relative'}>
                                        <label htmlFor={'phoneNumber'}
                                               className='flex items-center gap-1.5'><span>Հեռախոսահամար {index + 1}</span>
                                            {fields?.phoneNumber.mandatory &&  <AsteriskIcon/>}
                                        </label>
                                        <div className={'border border-black rounded-[5px] py-[2px] mt-2.5'}>
                                            <PhoneInput answer={phoneNumber}
                                                        onChange={changePhoneNumber}
                                                        name={index}/>
                                        </div>
                                        <div className={'absolute'}>
                                            <MandatoryWarningText text={'Պարտադիր դաշտ է'}
                                                                  cond={checkIfWarnedFieldWritten(`phoneNumbers_${index}`, data.data?.phoneNumbers[index])}/>
                                        </div>
                                    </div>
                                ))
                            )
                        }
                        {
                            fields?.parentFullName && (
                                data.data.parentsFullNames.map((parent, index) => (
                                    <div key={index} className={'relative'}>
                                        <label htmlFor={'parentFullNames'} className='flex items-center gap-1.5'><span>Ծնող կամ օրինական խնամակալ {index + 1}. անուն, ազգանուն, հայրանուն</span>
                                            {fields?.parentFullName.mandatory &&  <AsteriskIcon/>}</label>
                                        <input type="text" id={'parentFullNames'} name={index}
                                               value={parent || ''}
                                               onChange={changeParentName}
                                               className={'w-full border border-black border-opacity-70 outline-none py-[7px] px-[30px] rounded-[5px] mt-2.5'}/>
                                        <div className={'absolute'}>
                                            <MandatoryWarningText text={'Պարտադիր դաշտ է'}
                                                                  cond={checkIfWarnedFieldWritten(`parentFullNames_${index}`, data.data?.parentsFullNames[index])}/>
                                        </div>
                                    </div>
                                ))
                            )
                        }
                        <div>
                            <label htmlFor={'email'}
                                   className='flex items-center gap-1.5'
                            >
                                <span>Էլ. փոստի հասցե</span>
                                <AsteriskIcon/>
                            </label>
                            <input type="email" id={'email'} name={'email'}
                                   value={data.data.email || ''}
                                   onChange={handleChangeEmail}
                                   className={'w-full border border-black border-opacity-70 outline-none py-[7px] px-[30px] rounded-[5px] mt-2.5'}
                            />
                            <p className={`text-red-500 mt-2`}>
                                {data.data.email ? !(data.data.email.endsWith("@gmail.com") || data.data.email.endsWith("@coaf.org")) ? 'Էլ․ փոստի սխալ ձևաչափ' : '' : 'Էլ․ փոստը պարտադիր է'}
                            </p>
                        </div>
                        <div>
                            <label htmlFor={'email'}
                                   className={'flex items-center gap-1.5'}><span>Ծրագրային շաբաթ</span><AsteriskIcon/></label>
                            <div className={'border border-black rounded-[5px] mt-2.5 px-[30px] py-1.5'}>
                                {data.form?.name}
                            </div>
                        </div>
                    </div>
                    <div className={'mt-[30px]'}>
                        <div className={'w-fit flex items-center gap-5 select-none'}
                             onClick={() => setMakeStudent(!makeStudent)}>
                            <div
                                className={'w-[30px] h-[30px] flex items-center justify-center cursor-pointer border border-black rounded-[5px]'}>{makeStudent &&
                                <CheckedIcon color={'#FCC10F'}/>}</div>
                            <p>Դարձնել ուսանող </p>
                        </div>
                    </div>
                    <div className={'mt-[50px] flex items-center gap-[30px]'}>
                        <ButtonFilled onClick={submitEditing} text={'Պահպանել'} className={'w-[176px] shadow-md'}/>
                        <ButtonOutlined onClick={close} text={'Չեղարկել'} className={'w-[176px] shadow-md'}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditRequestModal