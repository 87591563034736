import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Route, Routes, useParams} from "react-router-dom";
import {getProgram} from "../../../store/actions/programs";
import {Container} from "../../../components/shared";
import ProgramHeader from "../../../components/programs/program/ProgramHeader";
import ProgramNavbar from "../../../components/programs/program/ProgramNavbar";
import Groups from "./subpages/Groups";
import Projects from "./subpages/Projects";

const Program = () => {
    const {program, loading} = useSelector(state => state.programs)

    const {id} = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getProgram(id))
    }, [id])

    return (
        <div>
            <Container>
                <ProgramHeader {...program}/>
                <ProgramNavbar {...program}/>

                <Routes>
                    <Route path='groups' element={<Groups {...program}/>}/>
                    <Route path='content' element={<p>content</p>}/>
                    <Route path='plan' element={<p>plan</p>}/>
                    <Route path='exercises' element={<p>exercises</p>}/>
                    <Route path='projects' element={<Projects />}/>
                </Routes>
            </Container>
        </div>
    );
};

export default Program;