import React, {useState} from 'react';
import {EditIcon, TrashIcon, TrashModeIcon} from "../../assets/icons";
import moment from "moment";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const NoteItem = ({note, handleEditNote, showStudent, setDeleteNoteModal, handleDeleteNote}) => {
    const {authData: {role}} = useSelector(state => state.auth);
    const formattedDate = moment(note.date || note.createdAt).format('DD.MM.YYYY')
    const formattedTime = moment(note.createdAt).format("HH:mm")

    const showControls = role === 'manager' ? true : !note?.isPublic

    return (
        <div
            className={'relative group p-[30px] mt-[30px] mb-[50px] bg-white shadow-md rounded-[10px]'}
        >
            {!note?.isPublic && <div className={'mb-2'}><TrashModeIcon/></div>}
            <div className={'flex items-center justify-between mb-5'}>
                <div>
                    {showStudent && <Link to={`/students/${note.student._id}`} className={`mb-[30px] hover:text-[#FCC10F] transition`}>{note.student.fullName}</Link>}
                </div>
                <div>
                    {showControls &&
                        <div className={'w-[90px] flex items-start justify-end gap-[30px]'}>
                            <button onClick={() => handleEditNote(note)}>
                                <EditIcon width={30} height={30}/>
                            </button>
                            <button onClick={() => setDeleteNoteModal(note._id)}>
                                <TrashIcon width={30} height={30}/>
                            </button>
                        </div>
                    }
                </div>
            </div>
            {!note.isPublic &&
                <h2 className={"absolute group-hover:opacity-100 opacity-0 right-0 bottom-0 mb-1 mr-[25px] text-[12px] transition"}>Սևագիր</h2>}
            <div className={'flex justify-between gap-[30px]'}>
                <div className={`${!note.isPublic && 'opacity-70'} text-justify`}>
                    <p className={'break-all'}>{note.note}</p>
                </div>
            </div>
            <div className={'flex gap-[30px] mt-[30px]'}>
                <p className={'bold opacity-70'}>{note.author.fullName}</p>
                <p>{note.author.role}</p>
                <p>{formattedDate}</p>
                <p>{formattedTime}</p>
                {note.group &&
                    <Link to={`/groups/${note.group._id}/attendance`}
                          className={'text-[#FCC10F]'}>{note.group?.name}</Link>}
            </div>
        </div>
    );
};

export default NoteItem;
