import React, {useEffect, useState} from 'react';
import GroupsFilter from "../../../components/groups/GroupsFilter";
import {useFilters} from "../../../hooks/useFilters";
import {useDispatch, useSelector} from "react-redux";
import {PlusIcon, ScrollToTopIcon} from "../../../assets/icons";
import {Link} from "react-router-dom";
import {Container} from "../../../components/shared";
import GroupCard from "../../../components/groups/GroupCard";
import {deleteGroup, getGroups} from "../../../store/actions/groups";
import Pagination from "../../../components/shared/Pagination";
import PageSizeDropdown from "../../../components/shared/dropdowns/PageSizeDropdown";

const initialFilters = {
    name: '', page: 1, size: 9
}
const Groups = () => {
    const {groups, loading} = useSelector(state => state.groups)

    const [groupToDelete, setGroupToDelete] = useState(null)

    const {
        filters,
        handleChange,
        changeSelectedFilters,
        discardFilters,
        changePage,
        changeCustomPage,
        setCustomPage,
        handleChangeSize,
        submitFilters
    } = useFilters(initialFilters, getGroups, 'groups')

    const dispatch = useDispatch()

    const handleDeleteGroup = () => {
        dispatch(deleteGroup(groupToDelete))
        setGroupToDelete(null)
    }

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    return (
        <div>
            <div>
                <h2 className={'mt-5 text-center text-[50px] text-[#FCC10F] font-bold'}>Խմբեր</h2>
            </div>

            <GroupsFilter
                filters={filters}
                discardFilters={discardFilters}
                submitFilters={submitFilters}
                handleChange={handleChange}
                changeSelectedFilters={changeSelectedFilters}
            />
            <Container>
                <div>
                    <Link
                        to={'/groups/create'}
                        className={'w-[208px] h-[50px] flex items-center justify-center gap-[30px] text-white rounded-[5px] bg-[#FCC10F] mt-[14px]'}>
                        <PlusIcon/>
                        <span className={'bold'}>Նոր խումբ</span>
                    </Link>
                    {loading
                        ?
                        <p className={'text-center'}>Բեռնվում է․․․</p>
                            :
                            groups?.data.length ?
                                <>
                                    <div
                                        className={'relative grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 place-items-stretch gap-[30px] mt-[30px]'}>
                                        {groups?.data.map((group, index) => {
                                            const isLast = groups?.data.length === index + 1

                                            return (
                                                <div key={group._id}>
                                                    <GroupCard
                                                        {...group}
                                                        handleDeleteGroup={handleDeleteGroup}
                                                        groupToDelete={groupToDelete}
                                                        setGroupToDelete={setGroupToDelete}
                                                        // isManager={isManager}
                                                    />

                                                    {isLast &&
                                                        <button
                                                            className={'absolute -right-16'}
                                                            onClick={scrollToTop}>
                                                            <ScrollToTopIcon/>
                                                        </button>}
                                                </div>
                                            )
                                        })}
                                    </div>

                                    <div className={'flex items-center justify-center mt-[40px]'}>
                                        {filters?.page && <Pagination count={groups?.pagesCount} onChange={changePage}
                                                                      page={parseInt(filters?.page)}/>}
                                        <div className={'flex items-center justify-center gap-2.5'}>
                                            <span>Էջ</span>
                                            <input type="number" onKeyDown={changeCustomPage}
                                                   onChange={e => setCustomPage(e.target.value)}
                                                   className={'max-w-[50px] outline-none px-2 py-0.5 text-center border border-black rounded-[5px]'}/>
                                        </div>
                                        <div className={'ml-[30px]'}>
                                            <PageSizeDropdown onChange={handleChangeSize} value={filters?.size}/>
                                        </div>
                                    </div>
                                </>
                                :
                                <p className={'text-center mt-5'}>Խմբեր չկան</p>
                    }
                </div>
            </Container>
        </div>
    );
};

export default Groups;