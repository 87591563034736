import React, {useEffect} from 'react'
import {Container} from "../shared"
import DropdownInputCheckbox from "../shared/dropdowns/DropdownInputCheckbox"
import {
    ageGroupData,
    ageGroupDataHybrid,
    generateScheduleDropdownData,
    groupStatusData
} from "../../common/dropdownOptions"
import {useDispatch, useSelector} from "react-redux"
import SelectedFilters from "../shared/SelectedFilters"
import {ButtonFilled, ButtonOutlined} from "../shared/buttons"
import {getScheduleCodes} from "../../store/actions/common";

const GroupsFilter = ({handleChange, changeSelectedFilters, filters, discardFilters, submitFilters}) => {
    const {programs, scheduleCodes} = useSelector(state => state.common)

    const dispatch = useDispatch()

    const handleEnter = (e) => {
        e.key === 'Enter' && submitFilters()
    }

    useEffect(() => {
        dispatch(getScheduleCodes())
    }, [])

    return (
        <div className={'custom-shadow mt-5'}>
            <Container>
                <div className={'grid grid-cols-5 gap-[11px] mt-[17px]'}>
                    <div className={'relative'}>
                        <div>
                            <input type={"text"}
                                   className={'w-[247px] border border-black outline-none rounded-[5px] py-[7px] pl-[10px]'}
                                   name={'name'}
                                   value={filters?.name || ''}
                                   onChange={handleChange}
                                   placeholder={"Խմբի անուն"}
                                   onKeyDown={handleEnter}
                            />
                        </div>
                    </div>

                    <div className={'relative w-[247px]'}>
                        <DropdownInputCheckbox data={groupStatusData} name={'status'} showCheckbox={true}
                                               previewClassName={'rounded-[5px] py-[7px]'}
                                               placeholder={'Կարգավիճակ'}
                                               onChange={changeSelectedFilters}
                                               selected={filters?.status}
                        />
                    </div>

                    <div className={'relative w-[247px]'}>
                        <DropdownInputCheckbox data={programs} name={'program'} showCheckbox={true}
                                               placeholder={'Ծրագրեր'}
                                               previewClassName={'rounded-[5px] py-[7px]'}
                                               onChange={changeSelectedFilters}
                                               selected={filters?.program}
                        />
                    </div>

                    <div className={'relative w-[247px]'}>
                        <DropdownInputCheckbox data={ageGroupDataHybrid} name={'ageGroup'} showCheckbox={false}
                                               placeholder={'Տարիքային խումբ'}
                                               previewClassName={'rounded-[5px] py-[7px]'}
                                               onChange={changeSelectedFilters} selected={filters?.ageGroup}/>
                    </div>

                    <div className={'relative w-[247px]'}>
                        <DropdownInputCheckbox data={scheduleCodes} name={'schedule'} showCheckbox={true}
                                               placeholder={'Գրաֆիկ'}
                                               previewClassName={'rounded-[5px] py-[7px]'}
                                               onChange={changeSelectedFilters}
                                               selected={filters?.schedule}/>
                    </div>
                </div>

                <div className={'flex justify-between gap-3 pt-[30px] pb-[17px]'}>
                    <SelectedFilters selectedFilters={filters} removeItem={changeSelectedFilters}/>

                    <div className={'flex gap-3 items-center'}>
                        <ButtonOutlined
                            onClick={discardFilters}
                            text={'Չեղարկել'}
                        />
                        <ButtonFilled
                            onClick={() => submitFilters()}
                            text={'Կիրառել'}
                        />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default GroupsFilter;