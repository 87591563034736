import React, {useEffect, useRef, useState} from 'react'
import {PlusButton} from "../../shared/buttons"
import {NavLink, Route, Routes} from "react-router-dom"
import SwitchButton from "../../shared/SwitchButton"
import ButtonHoverText from "../../shared/buttons/ButtonHoverText"
import AddStudentModal from "./modals/AddStudent"
import {useOutsideClick} from "../../../hooks/useOutsideClick";

const GroupNavbar = ({showDroppedStudents, changeShowDroppedStudents, selectedStudent, setSelectedStudent, openSelectStudentModal, setOpenSelectStudentModal, students}) => {
    const [openAddStudentModal, setOpenAddStudentModal] = useState(null)

    useEffect(() => {
        openAddStudentModal ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto'
    }, [openAddStudentModal])

    const CustomStudentDropdown = () => {
        const ref = useRef()

        useOutsideClick(ref, () => setOpenSelectStudentModal(false))

        const handleSelectStudent = (student) => {
            setSelectedStudent(student)
            setOpenSelectStudentModal(false)
        }

        return (
            <div ref={ref} className={'absolute w-screen max-w-[330px] max-h-[280px] z-50 top-[calc(100%+10px)] flex flex-col gap-5 rounded-[3px] overflow-y-scroll bg-white border border-black py-5'}>
                {students.studentsList.map(studentData => (
                    <div key={studentData._id} onClick={() => handleSelectStudent(studentData.student)} className={'px-[30px] py-1 hover:bg-gray-100 cursor-pointer'}>
                        {studentData.student.fullName}
                    </div>
                ))}
            </div>
        )
    }

    return (
        <>
            {openAddStudentModal &&
                <AddStudentModal
                    close={() => setOpenAddStudentModal(false)}
                    type={'group'}
                />
            }

            <div className={'flex justify-between items-center'}>
                <div className={'relative mt-5'}>
                    <Routes>
                        <Route path='attendance' element={<PlusButton  onClick={() => setOpenAddStudentModal(true)} className={'p-2.5'} width={10} height={10} styleClassName={'hover:bg-white transition border border-[#FCC10F]'}/>}/>
                        <Route
                            path='notes'
                            element={<PlusButton
                                onClick={() => setOpenSelectStudentModal(true)}
                                className={`p-2.5`}
                                width={10}
                                height={10}
                                styleClassName={`hover:bg-white transition border border-[#FCC10F]`}
                                hoverPlusColor={'#FCC10F'}
                            />}
                        />
                    </Routes>
                    {openSelectStudentModal && <CustomStudentDropdown />}
                </div>

                <div className={'flex items-center gap-[50px] mt-[30px]'}>
                    <NavLink to={`attendance`}
                             className={({isActive}) => `${isActive ? 'text-[#FCC10F]' : 'text-black'}`}>Հաճախելիություն</NavLink>
                    <NavLink to={`exercises`}
                             className={({isActive}) => `${isActive ? 'text-[#FCC10F]' : 'text-black'}`}>Առաջադրանքներ</NavLink>
                    <NavLink to={'performance'}
                             className={({isActive}) => `${isActive ? 'text-[#FCC10F]' : 'text-black'}`}>Կատարողական</NavLink>
                    <NavLink to={'notes'}
                             className={({isActive}) => `${isActive ? 'text-[#FCC10F]' : 'text-black'}`}>Նոթագրություն</NavLink>

                    {!(window.location.pathname.includes('performance') || window.location.pathname.includes('exercises') || window.location.pathname.includes('notes')) && (
                        <ButtonHoverText
                            icon={<SwitchButton checked={showDroppedStudents} handleChange={changeShowDroppedStudents} />}
                            text={'Ցույց տալ դուրս մնացած մասնակիցներին'}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default GroupNavbar;