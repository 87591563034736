import API from "./index";

export const createTransportStop = (data) => API.post(`/transport/stops`, data)
export const getTransportStops = (queryParams) => API.get(`/transport/stops${queryParams}`)
export const editTransportStop = (id, data) => API.patch(`/transport/stops/${id}`, data)
export const changeTransportStopStatus = (id, status) => API.patch(`/transport/stops/${id}/status`, {status})
export const deleteTransportStop = (id) => API.delete(`/transport/stops/${id}`)

export const createRoute = (data) => API.post('/transport/routes', data)
export const getRoutes = (queryParams) => API.get(`/transport/routes${queryParams}`)
export const changeRouteStatus = (id, status) => API.patch(`/transport/routes/${id}/status`, {status})
export const editRoute = (id, data) => API.patch(`transport/routes/${id}`, data)
export const deleteRoute = (id) => API.delete(`/transport/routes/${id}`)

export const createVehicle = (data) => API.post('/transport/vehicles', data)
export const getVehicles = (queryParams) => API.get(`/transport/vehicles${queryParams}`)
export const changeVehicleStatus = (id, status) => API.patch(`/transport/vehicles/${id}/status`, status)
export const deleteVehicle = (id) => API.delete(`/transport/vehicles/${id}`)

export const createDriver = (data) => API.post('/transport/drivers', data)
export const getDrivers = (queryParams) => API.get(`/transport/drivers/${queryParams}`)
export const getDriver = (id) => API.get(`transport/drivers/${id}`)
export const changeDriverStatus = (id) => API.patch(`/transport/drivers/${id}/status`)
export const editDriver = (id, data) => API.patch(`/transport/drivers/${id}`, data)
