import React, {useRef} from 'react';
import {CameraIcon, CompletedIcon, IntroductoryWeekIcon} from "../../../assets/icons";
import {useDispatch} from "react-redux";
import {changeStudentProfileImage} from "../../../store/actions/students";
import {Dropdown} from "../../shared/dropdowns";

const yearCharset = '1234567890'

const ProfileInfo = ({
                         firstName,
                         lastName,
                         middleName,
                         profileImage,
                         educationalEstablishment,
                         matriculationYear,
                         birthCertificate,
                         parentsFullNames,
                         formWeek,
                         introGroup,
                         _id,
                         editMode,
                         changeField,
                         changeParentName
                     }) => {
    const imageUploadRef = useRef(null)

    const dispatch = useDispatch()

    const handleImageUpload = (e) => {
        const selectedImage = e.target.files[0]

        if (selectedImage) {
            const formData = new FormData()
            formData.append('image', selectedImage)

            dispatch(changeStudentProfileImage(_id, formData))
        } else {
            console.log('No image selected')
        }
    }

    return (
        <div className={'border'}>
            <div className={`flex items-center justify-center py-8 border-b transition`}>
                <div className={'relative group'}>
                    <div>
                        <img src={profileImage}
                             className={'object-cover rounded-full aspect-square w-[200px] h-[200px] select-none pointer-events-none'}
                             alt="profile-image"/>
                    </div>

                    {editMode && <>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            style={{display: 'none'}}
                            ref={imageUploadRef}
                        />

                        <button
                            title={'Վերբեռնել նկար'}
                            className={'absolute right-0 fill-icon-white bottom-3 shadow-md p-2 rounded-full group-hover:opacity-100 opacity-0 transition z-50 bg-[#fff] border border-[#FCC10F] hover:bg-[#FCC10F]'}
                            onClick={() => imageUploadRef.current.click()}
                        >
                            <CameraIcon color={'#FCC10F'}/>
                        </button>
                    </>}
                </div>
            </div>

            <div className={'flex flex-col'}>
                {editMode &&
                    <div className={'flex flex-col gap-[15px] border-b px-2.5 py-[15px]'}>
                        <div className={'flex flex-col gap-[15px]'}>
                            <p className={'text-[#FCC10F]'}>Անուն</p>
                            <div className={'w-[297px] h-[30px]'}>
                                <input
                                    type={"text"}
                                    name={"firstName"}
                                    value={firstName}
                                    className={'w-full h-full border border-black rounded-[3px] py-1 px-[10px] outline-none'}
                                    onChange={changeField}
                                />
                            </div>
                        </div>
                        <div className={'flex flex-col gap-[15px]'}>
                            <p className={'text-[#FCC10F]'}>Ազգանուն</p>
                            <div className={'w-[297px] h-[30px]'}>
                                <input
                                    type={"text"}
                                    name={"lastName"}
                                    value={lastName}
                                    className={'w-full h-full border border-black rounded-[3px] py-1 px-[10px] outline-none'}
                                    onChange={changeField}
                                />
                            </div>
                        </div>
                        <div className={'flex flex-col gap-[15px]'}>
                            <p className={'text-[#FCC10F]'}>Հայրանուն</p>
                            <div className={'w-[297px] h-[30px]'}>
                                <input
                                    type={"text"}
                                    name={"middleName"}
                                    value={middleName}
                                    className={'w-full h-full border border-black rounded-[3px] py-1 px-[10px] outline-none'}
                                    onChange={changeField}
                                />
                            </div>
                        </div>
                    </div>
                }
                <div className={`flex flex-col ${editMode ? 'gap-[25px]' : 'gap-[30px]'}`}>
                    <div className={`w-[336px] flex flex-col ${editMode ? 'gap-[15px]' : 'gap-5'} px-[10px] pt-[15px]`}>
                        <p className={'text-[#FCC10F]'}>Ուսումնական հաստատություն</p>
                        {editMode
                            ?
                            <div className={'w-[297px] h-[30px]'}>
                                <input
                                    type={"text"}
                                    name={"educationalEstablishment"}
                                    value={educationalEstablishment}
                                    className={'w-full h-full border border-black rounded-[3px] py-1 px-[10px] outline-none'}
                                    onChange={changeField}
                                />
                            </div>
                            :
                            <p>{educationalEstablishment}</p>}
                    </div>
                    <div
                        className={`flex flex-col px-[10px] ${editMode ? 'pb-[10px] gap-[15px]' : 'pb-[15px] gap-5'} border-b`}>
                        <p className={'text-[#FCC10F]'}>Ընդունվելու տարեթիվ</p>
                        {editMode
                            ?
                            <input
                                type="text"
                                className={'w-[297px] h-[30px] border border-black rounded-[3px] py-1 px-[10px] outline-none'}
                                name={'matriculationYear'}
                                value={matriculationYear || ''}
                                onChange={changeField}
                            />
                            :
                            <p>{matriculationYear || "Առկա չէ"}</p>}
                    </div>
                </div>
                <div
                    className={`flex flex-col py-[15px] px-[10px] ${editMode ? 'pb-[10px] gap-[15px]' : 'pb-[15px] gap-5'} border-b`}>
                    <p className={'text-[#FCC10F]'}>Ծննդյան վկայական/ անձնագիր</p>
                    {editMode
                        ?
                        <input
                            type="text"
                            className={'w-[297px] h-[30px] border border-black rounded-[3px] py-1 px-[10px] outline-none'}
                            name={'birthCertificate'}
                            value={birthCertificate || ''}
                            onChange={changeField}
                        />
                        :
                        <p>{birthCertificate || 'Տվյալը առկա չէ'}</p>
                    }
                </div>
                <div
                    className={`flex flex-col py-[15px] px-[10px] ${editMode ? 'pb-[10px] gap-[25px]' : 'pb-[15px] gap-[30px]'} border-b`}>
                    <div className={`flex flex-col ${editMode ? 'gap-[15px]' : 'gap-5'}`}>
                        <p className={'text-[#FCC10F]'}>Ծնող կամ օրինական խնամակալ 1</p>
                        {editMode
                            ?
                            <input
                                type="text"
                                className={'w-[297px] h-[30px] border border-black rounded-[3px] py-1 px-[10px] outline-none'}
                                name={'firstParentName'}
                                value={parentsFullNames && parentsFullNames[0]}
                                onChange={e => changeParentName(0, e.target.value)}
                            />
                            :
                            <p>{parentsFullNames && (parentsFullNames[0] || 'Տվյալը առկա չէ')}</p>
                        }
                    </div>
                    <div className={`flex flex-col ${editMode ? 'gap-[15px]' : 'gap-5'}`}>
                        <p className={'text-[#FCC10F]'}>Ծնող կամ օրինական խնամակալ 2</p>
                        {editMode
                            ?
                            <input
                                type="text"
                                className={'w-[297px] h-[30px] border border-black rounded-[3px] py-1 px-[10px] outline-none'}
                                name={'firstParentName'}
                                value={parentsFullNames && parentsFullNames[1]}
                                onChange={e => changeParentName(1, e.target.value)}
                            />
                            :
                            <p>{parentsFullNames && (parentsFullNames[1] || 'Տվյալը առկա չէ')}</p>
                        }
                    </div>
                </div>
                <div className={'flex flex-col gap-5 py-[15px] px-[10px] border-b'}>
                    <p className={'text-[#FCC10F]'}>Հայտի համար</p>
                    <p>{formWeek?.name || 'Առկա չէ'}</p>
                </div>
                <div className={'flex flex-col gap-5 pt-[15px] px-[10px] pb-[72px]'}>
                    <p className={'flex items-center gap-5 text-[#FCC10F]'}><IntroductoryWeekIcon/> Ներածական շաբաթ</p>
                    {introGroup
                        ?
                        <p className={'flex items-center gap-[30px]'}>{introGroup?.name} <CompletedIcon/></p>
                        :
                        <p className={'flex items-center gap-[30px]'}>Առկա չէ</p>
                    }
                </div>
            </div>
        </div>
    );
};

export default ProfileInfo;