import React, {useRef, useEffect, useState} from 'react';

const AutoResizableTextarea = ({value, name, onChange, placeholder, autoFocus, maxLength}) => {
    const textareaRef = useRef(null);
    const [isMaxLengthReached, setIsMaxLengthReached] = useState(false)

    const handleChange = (e) => {
        const newValue = e.target.value;
        if (maxLength && newValue.length > maxLength) {
            setIsMaxLengthReached(true)
        } else {
            setIsMaxLengthReached(false)
        }

        onChange(e);
        adjustTextareaHeight();
    };

    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current;
        textarea.style.height = 'auto';
        const newHeight = Math.min(textarea.scrollHeight, 100); // Limit height to 100px
        textarea.style.height = `${newHeight}px`;
        textarea.style.overflowY = textarea.scrollHeight > 100 ? 'scroll' : 'hidden'; // Add scrollbar if height exceeds 100px
    };

    useEffect(() => {
        adjustTextareaHeight(); // Adjust height initially if there's a preset value
    }, [value]);

    return (
        <div className="relative">
            <textarea
                name={name}
                ref={textareaRef}
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                autoFocus={autoFocus}
                className={`resize-none w-full transition py-[3px] px-5 border border-black rounded-[3px] outline-none overflow-hidden ${isMaxLengthReached && 'pb-10'}`}
                style={{height: '30px'}}
            />
            {isMaxLengthReached && (
                <p className={"absolute bottom-2 right-1.5 text-red-500 text-sm bg-white rounded-[5px]"}>{maxLength} նշանը
                    լրացել է</p>
            )}
        </div>
    );
};

export default AutoResizableTextarea;   