import React, {useEffect, useState} from 'react'
import StudentsList from "../../../../../components/groups/group/attendance/students/StudentsList"
import Levels from "../../../../../components/groups/group/attendance/Levels"
import MarkAttendanceModal from "../../../../../components/groups/group/modals/MarkAttendance"
import {useDispatch} from "react-redux";
import {markLessonAttendance, restoreCanceledLesson} from "../../../../../store/actions/groups";
import ReplaceLessonModal from "../../../../../components/groups/group/modals/ReplaceLesson";
import CancelLesson from "../../../../../components/groups/group/modals/CancelLesson"
import {ConfirmationModal} from "../../../../../components/shared";
import RemoveStudentModal from "../../../../../components/groups/group/modals/RemoveStudent";

const Attendance = ({students, attendance, showDroppedStudents, _id}) => {
    const [markAttendance, setMarkAttendance] = useState(null)
    const [replaceLessonModal, setReplaceLessonModal] = useState(false)
    const [cancelLessonModal, setCancelLessonModal] = useState(false)
    const [restoreCanceledLessonModal, setRestoreCanceledLessonModal] = useState(false)
    const [removeStudentModal, setRemoveStudentModal] = useState(false)

    const [lineHovered, setLineHovered] = useState(null)
    const [isScrolling, setIsScrolling] = useState(false)

    const dispatch = useDispatch()

    const activeStudents = students?.studentsList.map(studentData => {
        if (studentData.status !== 'dropped') {
            return studentData.student._id
        }
    }).filter(student => student)

    const handleMarkAttendance = (dayAttendance, levelIndex, lessonIndex) => {
        setMarkAttendance({dayAttendance, levelIndex, lessonIndex})
    }

    const handleOpenReplaceLessonModal = (targetLessonDate, lessonIndex, levelIndex) => {
        setReplaceLessonModal({targetLessonDate, lessonIndex, levelIndex})
    }

    const handleOpenCancelLessonModal = (targetLessonDate, lessonIndex, levelIndex) => {
        setCancelLessonModal({targetLessonDate, lessonIndex, levelIndex})
    }

    const handleOpenRestoreCanceledLessonModal = (targetLesson, lessonIndex, levelIndex) => {
        setRestoreCanceledLessonModal({targetLesson, lessonIndex, levelIndex})
    }

    const handleRestoreCanceledLesson = () => {
        dispatch(restoreCanceledLesson(_id, restoreCanceledLessonModal.targetLesson, restoreCanceledLessonModal.levelIndex, restoreCanceledLessonModal.lessonIndex))
        setRestoreCanceledLessonModal(false)
    }

    const handleRemoveStudent = (studentId) => {
        setRemoveStudentModal(studentId)
    }

    const handleSubmitMarkAttendance = (lessonDate, data, levelIndex, lessonIndex) => {
        dispatch(markLessonAttendance(_id, lessonDate, data, levelIndex, lessonIndex))
        setMarkAttendance(null)
    }

    const handleScroll = (e) => {
        if (!isScrolling) {
            setIsScrolling(true);
            setTimeout(() => {
                setIsScrolling(false);
            }, 1000);
        }
    }

    useEffect(() => {
        if (markAttendance || replaceLessonModal || cancelLessonModal || restoreCanceledLessonModal || removeStudentModal) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [markAttendance, replaceLessonModal, replaceLessonModal, restoreCanceledLessonModal, removeStudentModal])

    return (
        <>
            {markAttendance &&
                <MarkAttendanceModal students={students} data={markAttendance} close={() => setMarkAttendance(null)}
                                     submit={handleSubmitMarkAttendance}/>}
            {replaceLessonModal &&
                <ReplaceLessonModal close={() => setReplaceLessonModal(null)} targetLessonDate={replaceLessonModal}/>}
            {cancelLessonModal &&
                <CancelLesson close={() => setCancelLessonModal(null)} targetLessonDate={cancelLessonModal}/>}

            {restoreCanceledLessonModal && <ConfirmationModal close={() => setRestoreCanceledLessonModal(null)}
                                                              warningText={'Վստա՞հ եք, որ ցանկանում եք վերականգնել չեղարկումը։'}
                                                              confirmText={'Վերականգնել'} cancelText={'Չեղարկել'}
                                                              confirm={handleRestoreCanceledLesson}/>}
            {removeStudentModal &&
                <RemoveStudentModal close={() => setRemoveStudentModal(null)} studentId={removeStudentModal}/>}

            <div onScroll={handleScroll} className={'relative overflow-auto flex pb-2.5 border rounded-[3px] mt-[30px]'}>
                    <StudentsList
                        studentsList={students?.studentsList}
                        lineHovered={lineHovered}
                        hoverLine={(lineIndex) => setLineHovered(lineIndex)}
                        activeStudents={activeStudents}
                        showDropped={showDroppedStudents}
                        handleRemoveStudent={handleRemoveStudent}
                    />
                <Levels
                    students={students}
                    levels={attendance?.levels}
                    total={attendance?.total}
                    markAttendance={handleMarkAttendance}
                    openReplaceLessonModal={handleOpenReplaceLessonModal}
                    openCancelLessonModal={handleOpenCancelLessonModal}
                    openRestoreCanceledLessonModal={handleOpenRestoreCanceledLessonModal}
                    lineHovered={lineHovered}
                    hoverLine={(lineIndex) => setLineHovered(lineIndex)}
                    activeStudents={activeStudents}
                    showDropped={showDroppedStudents}
                    isParentScrolling={isScrolling}
                />
            </div>
        </>
    )
}

export default Attendance