import React from 'react';

const Container = ({children, className}) => {
    return (
        <div className={`max-w-[1312px] my-0 mx-auto p-4 ${className}`}>
            {children}
        </div>
    );
};

export default Container;