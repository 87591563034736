import React, {useState} from 'react';
import {Container} from "../../../components/shared";
import ProfileInfo from "../../../components/profile/ProfileInfo";
import ProfileData from "../../../components/profile/ProfileData";
import {useSelector} from "react-redux";

const Profile = () => {
    const {authData} = useSelector(state => state.auth)

    const [uploadedImage, setUploadedImage] = useState(null)

    const handleImageUpload = (e) => {
        const selectedImage = e.target.files[0]

        if (selectedImage) {
            setUploadedImage(URL.createObjectURL(selectedImage))
        }
    }

    return (
        <div>
            <Container>
                <div className={'grid grid-cols-[246px,926px] gap-[82px] mt-[14px]'}>
                    <ProfileInfo {...authData} uploadedImage={uploadedImage} uploadImage={handleImageUpload}/>

                    <ProfileData {...authData} uploadedImage={uploadedImage}/>
                </div>
            </Container>
        </div>
    );
};

export default Profile;