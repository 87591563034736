import React, {useEffect, useState} from 'react'
import '../../../../index.css'
import {PhoneInput} from "../../../shared";
import Calendar from "../../../shared/calendar/Calendar";
import {DropdownInput, Dropdown} from "../../../shared/dropdowns";

const disallowedCharset = '1234567890'

const Answer = ({name, placeholder, options, category, type, mandatory, info, id, handleStateChange}) => {
    const [data, setData] = useState({name, options, mandatory, placeholder, info: info || null, answer: '', touched: false, category, type, id})
    const [answer, setAnswer] = useState(data.answer)

    const handleChange = (e) => {
        let fieldData = data

        if (type !== 'checkboxes' && type !== 'calendar') {
            const lastChar = e.target.value[e.target.value.length - 1]

            if (category === 'firstName' ||
                category === 'lastName' ||
                category === 'middleName' ||
                category === 'parentFullName'
            ) {
                if (!disallowedCharset.includes(lastChar)) {
                    fieldData = {...fieldData, answer: e.target.value}
                }
            } else {
                fieldData = {...fieldData, answer: e.target.value}
            }
        } else if (type === 'calendar') { // e would be a dateString
            fieldData = {...fieldData, answer: e}
        } else {
            if (data.answer.includes(e.target.value)) {
                fieldData = {...fieldData, answer: data.answer.filter(item => item !== e.target.value)}
            } else {
                fieldData = {...data, answer: [...data.answer, e.target.value]}
            }
        }
        setData(fieldData)
    }

    const handleChangePhone = (phone) => {
        setData({...data, answer: phone})
    }

    const handleTouch = () => {
        setData({...data, touched: true})
    }

    useEffect(() => {
        handleStateChange(data)
    }, [data])

    useEffect(() => {
        answer && setData({...data, answer: answer})
    }, [answer])

    switch (type) {
        case 'dropdown':
            if (category === 'community') {
                return (
                    <DropdownInput data={options}
                                   onTouch={handleTouch}
                                   value={answer}
                                   className={'py-[3px]'}
                                   onChange={(e) => setAnswer(e.target.value)}
                                   placeholder={data.placeholder}
                                   optionClassName={'px-6'}
                                   name={data.name}/>
                )
            } else {
                return <Dropdown onChange={handleChange} onTouch={handleTouch} value={data.answer}
                                 data={options}
                                 placeholder={data.placeholder}/>
            }
        case 'shortAnswer':
        case 'staticText':
        case 'text':
            return (
                <div className={'px-7'}>
                    <input type="text" onClick={handleTouch}
                           className={`w-full py-[3px] outline-none`}
                           placeholder={placeholder}
                           value={data.answer} onChange={handleChange}/>
                </div>
            )
        case 'calendar':
            return (
                <div onClick={handleTouch}>
                    <Calendar value={answer} displayFormat={'DD/MM/YYYY'} returnFormat={'YYYY-MM-DD'} placeholder={placeholder} setDate={setAnswer} className={'p-[3px]'}/>
                </div>
            )
        case 'phoneNumber':
            return (
                <PhoneInput answer={data.answer} onChange={handleChangePhone} onTouch={handleTouch} className={'py-[3px]'}/>
            )
        default:
            break;
    }
}

export default Answer