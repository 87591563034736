import React, {useEffect, useState} from 'react';
import SwitchButton from "../shared/SwitchButton";
import {AsteriskIcon, ClosedEyeIcon, CloseIcon, DoneIcon, OpenedEyeIcon, UnderdoneIcon} from "../../assets/icons";
import {ButtonFilled} from "../shared/buttons";
import {useDispatch} from "react-redux";
import {editProfile} from "../../store/actions/profile";
import {changeProfileSettings} from "../../store/actions/auth";

const ProfileData = ({_id, fullName, email, phoneNumber, emailsEnabled, uploadedImage}) => {

    const initialState = {
        fullName: fullName,
        email: email,
        phoneNumber: phoneNumber,
        password: '',
        confirmPassword: '',
        emailsEnabled: null,
        image: null,
    }
    const [data, setData] = useState(initialState)

    const [showPassword, setShowPassword] = useState(false)
    const [showRepeatPassword, setShowRepeatPassword] = useState(false)

    const [allowNotifications, setAllowNotifications] = useState(emailsEnabled)

    const [errors, setErrors] = useState({})

    const dispatch = useDispatch()

    const handleInputChange = (e) => {
        const {name, value} = e.target
        setData(prevState => ({
            ...prevState,
            [name]: value
        }))

        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: undefined
        }))
    }

    const handlePasswordChange = (e) => {
        const { name, value } = e.target
        setData((prevState) => ({ ...prevState, [name]: value }))

        const requirements = [
            { regex: /.{8,}/, message: 'Առնվազն 8 տառ' },
            { regex: /[A-Z]/, message: 'Առնվազն 1 մեծատառ' },
            { regex: /\d/, message: 'Առնվազն 1 թիվ' },
            { regex: /[*$&!#]/, message: 'Առնվազն 1 նշան' }
        ]

        const passwordErrors = requirements.map(req => ({
            message: req.message,
            isValid: req.regex.test(value)
        }))

        const allRequirementsMet = passwordErrors.every(error => error.isValid);

        setErrors(prevErrors => ({
            ...prevErrors,
            password: allRequirementsMet ? [] : passwordErrors
        }))
    }

    const handleRepeatPasswordChange = (e) => {
        const {name, value} = e.target
        setData(prevState => ({
            ...prevState,
            [name]: value
        }))

        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: undefined
        }))
    }

    const handleSubmitChanges = () => {
        const newErrors = {}

        // for (const key in data) {
        //     if (!data[key] || !data[key].length) {
        //         newErrors[key] = "Այս դաշտը պարտադիր է"
        //     }
        // }

        if (Object.entries(newErrors).length) {
            setErrors(prevErrors => ({
                ...prevErrors,
                ...newErrors
            }))
            return
        }

        const formData = new FormData()
        formData.append('fullName', data.fullName)
        formData.append('email', data.email)
        formData.append('phoneNumber', data.phoneNumber)
        formData.append('password', data.password)
        formData.append('confirmPassword', data.confirmPassword)
        formData.append('profileImage', uploadedImage)
        formData.append('emailsEnabled', allowNotifications)

        dispatch(changeProfileSettings(_id, formData))
    }

    return (
        <div>
            <div className={'flex items-center gap-[30px]'}>
                <h2>Ծանուցումներ էլեկտրոնային փոստին</h2>
                <SwitchButton checked={allowNotifications}
                              handleChange={() => setAllowNotifications(!allowNotifications)}/>
            </div>
            <div className={'flex flex-col gap-5 w-[624px] mt-5'}>
                <div className={'flex flex-col gap-2.5'}>
                    <div className={'flex gap-1'}>
                        <p>Անուն, ազգանուն, հայրանուն</p>
                        <AsteriskIcon/>
                    </div>
                    <input
                        type="text"
                        value={data.fullName}
                        name="fullName"
                        onChange={handleInputChange}
                        className={'w-full h-10 border border-black border-opacity-70 rounded-[5px] px-[30px] py-2.5 outline-none'}
                    />
                    {errors.fullName && <p className={'text-red-500'}>{errors.fullName}</p>}
                </div>

                <div className={'flex flex-col gap-2.5'}>
                    <p>Էլեկտրոնային փոստ</p>
                    <input
                        type="email"
                        value={data.email}
                        name="email"
                        onChange={handleInputChange}
                        className={'w-full h-10 border border-black border-opacity-70 rounded-[5px] px-[30px] py-2.5 outline-none pointer-events-none'}
                    />
                </div>

                <div className={'flex flex-col gap-2.5'}>
                    <div className={'flex gap-1'}>
                        <p>Հեռախոսահամար</p>
                        <AsteriskIcon/>
                    </div>
                    <input
                        type="number"
                        value={data.phoneNumber || ''}
                        name="phoneNumber"
                        onChange={handleInputChange}
                        className={'w-full h-10 border border-black border-opacity-70 rounded-[5px] px-[30px] py-2.5 outline-none'}
                    />
                    {errors.phoneNumber && <p className={'text-red-500'}>{errors.phoneNumber}</p>}
                </div>

                <div className={'flex flex-col gap-2.5'}>
                    <div className={'flex gap-1'}>
                        <p>Փոխել գաղտնաբառը</p>
                    </div>
                    <div
                        className={'flex items-center w-full h-10 border border-black border-opacity-70 rounded-[5px] outline-none px-[30px] py-2.5'}>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            value={data.password}
                            onChange={handlePasswordChange}
                            className={'w-full outline-none border-none'}
                        />
                        <button
                            onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <OpenedEyeIcon width={20} height={20}/> :
                                <ClosedEyeIcon width={20} height={20}/>}
                        </button>
                    </div>
                    {Array.isArray(errors.password) && errors.password.map((error, index) => (
                        <p key={index} className={`flex items-center gap-2.5 ${error.isValid ? 'text-green-500' : 'text-[#FF0707]'}`}>
                            {!error.isValid ? <UnderdoneIcon /> : <DoneIcon />} <span>{error.message}</span>
                        </p>
                    ))}
                </div>

                <div className={'flex flex-col gap-2.5'}>
                    <div className={'flex gap-1'}>
                        <p>Կրկնել գաղտնաբառը</p>
                    </div>
                    <div
                        className={'flex items-center w-full h-10 border border-black border-opacity-70 rounded-[5px] outline-none px-[30px] py-2.5'}>
                        <input
                            type={showRepeatPassword ? 'text' : 'password'}
                            name="confirmPassword"
                            value={data.confirmPassword}
                            onChange={handleRepeatPasswordChange}
                            className={'w-full outline-none border-none'}
                        />
                        <button onClick={() => setShowRepeatPassword(!showRepeatPassword)}>
                            {showRepeatPassword ? <OpenedEyeIcon width={20} height={20}/> :
                                <ClosedEyeIcon width={20} height={20}/>}
                        </button>
                    </div>
                </div>
            </div>
            <ButtonFilled text={'Պահպանել'} className={'w-[176px] mt-[50px]'} onClick={handleSubmitChanges}/>
        </div>
    );
};

export default ProfileData
