import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {getWorkshop} from "../../../../../store/actions/groups";
import AddStudentModal from "../../../../../components/groups/group/modals/AddStudent";
import {Container} from "../../../../../components/shared";
import Attendance from "../../../groups/group/attendance/Attendance";
import SwitchButton from "../../../../../components/shared/SwitchButton";
import ButtonHoverText from "../../../../../components/shared/buttons/ButtonHoverText";
import {PlusButton} from "../../../../../components/shared/buttons";
import EventHeader from "../../../../../components/smartEvents/event/EventHeader";
import EventInfo from "../../../../../components/smartEvents/event/EventInfo";

const Workshop = () => {
    const {group, loading} = useSelector(state => state.groups)

    const [openAddStudentModal, setOpenAddStudentModal] = useState(null)

    const {id} = useParams()
    const dispatch = useDispatch()

    const [showDroppedStudents, setShowDroppedStudents] = useState(() => {
        const savedState = localStorage.getItem('showDroppedStudents');
        return savedState !== null ? JSON.parse(savedState) : false;
    })

    const changeShowDroppedStudents = () => {
        const newState = !showDroppedStudents;
        setShowDroppedStudents(newState);
        localStorage.setItem('showDroppedStudents', JSON.stringify(newState));
    }

    useEffect(() => {
        dispatch(getWorkshop(id))
    }, [id])

    useEffect(() => {
        openAddStudentModal ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto'
    }, [openAddStudentModal])
    return (
        <div>
            {openAddStudentModal &&
                <AddStudentModal
                    close={() => setOpenAddStudentModal(false)}
                    type={'workshop'}
                />
            }

            <Container>
                <EventHeader {...group} />
                <EventInfo {...group} type={'workshop'}/>

                <div className={'flex items-center justify-between mt-5'}>
                    <PlusButton
                        onClick={() => setOpenAddStudentModal(true)}
                        className={'p-2.5'}
                        width={10}
                        height={10}
                        styleClassName={'hover:bg-white transition border border-[#FCC10F]'}
                        hoverPlusColor={'#FCC10F'}
                    />

                    <ButtonHoverText
                        icon={<SwitchButton checked={showDroppedStudents} handleChange={changeShowDroppedStudents} />}
                        text={'Ցույց տալ դուրս մնացած մասնակիցներին'}
                    />
                </div>

                <Attendance {...group} />
            </Container>
        </div>
    );
};

export default Workshop;